/** @format */

import isIp from 'is-ip';
export default function validateAlarmCenter(
  alarmCenter = {},
  country,
  productType,
  firmwareInterval,
  editConfigurationFeatures
) {
  return {
    type: 'object',
    allowNull: true,
    ...(Object.keys(alarmCenter).length &&
    alarmCenter.copiedFromPredefinedAlarmCenter
      ? {
          keys: {
            name: {
              type: 'string',
              errorCode: 'validation.ipReceiver.name.not.entered',
              allowNull: alarmCenter.copiedFromPredefinedAlarmCenter === 'local'
            },
            copiedFromPredefinedAlarmCenter: {
              type: 'string',
              allowNull: true
            },
            ...(alarmCenter.hasDc09 ? {} : validateV4()),
            ...(alarmCenter.hasDc09 ? validateDc09(alarmCenter) : {}),
            ...validateIps(alarmCenter),
            ...validatePolling(
              country,
              productType,
              firmwareInterval,
              editConfigurationFeatures
            )
          }
        }
      : {})
  };
}

function validatePolling(
  country,
  productType,
  firmwareInterval,
  editConfigurationFeatures
) {
  if (productType === '2G/3G' && firmwareInterval === 'before.3.3.1.0') {
    return {
      pollingInterval: {
        type: 'string',
        allowNull: true,
        condition: (pollingInterval) => pollingInterval !== 'select',
        errorCode: 'validation.ipReceiver.polling.not.entered'
      }
    };
  } else if (
    country.get('isoCode') === 'SE' &&
    editConfigurationFeatures.includes('alarmClass')
  ) {
    return {
      communicationPath: {
        type: 'string',
        condition: (communicationPath) => communicationPath !== 'select',
        errorCode: 'validation.ipReceiver.communication.path.not.entered'
      },
      alarmClass: {
        type: 'string',
        allowNull: true,
        condition: (alarmClass) => alarmClass !== 'select',
        errorCode: 'validation.ipReceiver.alarm.class.not.entered'
      }
    };
  }
  return {
    pollingInterval: {
      type: 'string',
      allowNull: true,
      condition: (pollingInterval) => pollingInterval !== 'select',
      errorCode: 'validation.ipReceiver.polling.not.entered'
    },
    communicationPath: {
      type: 'string',
      condition: (communicationPath) => communicationPath !== 'select',
      errorCode: 'validation.ipReceiver.communication.path.not.entered'
    }
  };
}

function validateV4() {
  return {
    v4: {
      type: 'object',
      keys: {
        transmitterCode: {
          type: 'string',
          condition: (transmitterCode) => transmitterCode.length <= 15,
          errorCode: 'validation.ipReceiver.transmitterCode.not.1to15'
        },
        transmitterType: {
          type: 'string',
          allowNull: true,
          condition: (transmitterType) => transmitterType.length <= 5,
          errorCode: 'validation.ipReceiver.transmitterType.not.0to5'
        },
        authenticationCode: {
          type: 'string',
          allowNull: true,
          condition: (authenticationCode) => authenticationCode.length <= 15,
          errorCode: 'validation.ipReceiver.authenticationCode.not.0to15'
        },
        sosAreaCode: {
          type: 'string',
          allowNull: true,
          condition: (sosAreaCode) => sosAreaCode.length <= 20,
          errorCode: 'validation.ipReceiver.sosAreaCode.not.0to20'
        },
        additionalInformation: {
          type: 'string',
          allowNull: true,
          condition: (additionalInformation) =>
            additionalInformation.length <= 100,
          errorCode: 'validation.ipReceiver.additionalInformation.not.0to100'
        }
      }
    }
  };
}

function validateDc09(alarmCenter) {
  return {
    dc09: {
      type: 'object',
      keys: {
        encryptionKey: {
          type: 'string',
          allowNull:
            !alarmCenter.encrypted ||
            (alarmCenter.dc09 && alarmCenter.dc09.eagleEyeCms),
          condition: (encryptionKey) =>
            /^([0-9a-fA-F]{32}|[0-9a-fA-F]{64})$/.test(encryptionKey),
          errorCode: 'validation.ipReceiver.dc09enckey.not.32or64chars'
        },
        accountNumber: {
          type: 'string',
          condition: (accountNumber) =>
            /^[0-9a-fA-F]{1,24}$/.test(accountNumber),
          errorCode:
            'validation.ipReceiver.dc09.accountnumber.not.1to16.hexchars'
        }
      }
    }
  };
}

function validateIps(alarmCenter) {
  return alarmCenter.copiedFromPredefinedAlarmCenter === 'other' ||
    alarmCenter.copiedFromPredefinedAlarmCenter === 'local'
    ? alarmCenter.communicationPath === 'all' && !alarmCenter.samePathSettings
      ? {
          primaryethernetReceiverIp: {
            type: 'string',
            condition: (primaryethernetReceiverIp) =>
              isIp(primaryethernetReceiverIp),
            errorCode: 'validation.not.valid.ip'
          },
          primaryethernetReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port'
          },
          primarymobileReceiverIp: {
            type: 'string',
            condition: (secondaryethernetReceiverIp) =>
              isIp(secondaryethernetReceiverIp),
            errorCode: 'validation.not.valid.ip'
          },
          primarymobileReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port'
          },
          secondaryethernetReceiverIp: {
            type: 'string',
            condition: (secondaryethernetReceiverIp) =>
              isIp(secondaryethernetReceiverIp),
            errorCode: 'validation.not.valid.ip',
            allowNull: !alarmCenter.secondaryethernetReceiverPort
          },
          secondaryethernetReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port',
            allowNull: !alarmCenter.secondaryethernetReceiverIp
          },
          secondarymobileReceiverIp: {
            type: 'string',
            condition: (secondarymobileReceiverIp) =>
              isIp(secondarymobileReceiverIp),
            errorCode: 'validation.not.valid.ip',
            allowNull: !alarmCenter.secondarymobileReceiverPort
          },
          secondarymobileReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port',
            allowNull: !alarmCenter.secondarymobileReceiverIp
          }
        }
      : {
          primaryReceiverIp: {
            type: 'string',
            condition: (primaryReceiverIp) =>
              /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(primaryReceiverIp),
            errorCode: 'validation.not.valid.ip'
          },
          primaryReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port'
          },
          secondaryReceiverIp: {
            type: 'string',
            condition: (secondaryReceiverIp) =>
              /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(secondaryReceiverIp),
            errorCode: 'validation.not.valid.ip',
            requiredIf: 'secondaryReceiverPort'
          },
          secondaryReceiverPort: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port',
            requiredIf: 'secondaryReceiverIp'
          }
        }
    : {
        primaryReceiverIp: { type: 'any', allowNull: true },
        primaryReceiverPort: { type: 'any', allowNull: true },
        secondaryReceiverIp: { type: 'any', allowNull: true },
        secondaryReceiverPort: { type: 'any', allowNull: true }
      };
}
