/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';

export default function editArcService() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_ARC_SERVICE_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const service = state.modals.getIn(['editArcService', 'service']);
    let response;
    try {
      response = await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveArcService`,
        {
          body: {
            service,
            subscriptionId
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_ARC_SERVICE_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );
    dispatch({
      type: 'EDIT_ARC_SERVICE_DONE',
      payload: response.body
    });
  };
}
