/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showDialog: false,
  errors: {
    file: []
  },
  isUploading: false,
  uploaded: false,
  uploadingProgress: 0
});

export default function sequenceChannelSpecificationUploadReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'OPEN_SEQUENCE_CHANNEL_SPECIFICATION_DIALOG': {
      return state.set('showDialog', true).set('uploaded', false);
    }
    case 'CLOSE_SEQUENCE_CHANNEL_SPECIFICATION_DIALOG': {
      return state.set('showDialog', false).setIn(['errors', 'file'], []);
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_STARTED': {
      return state
        .set('isUploading', true)
        .set('uploaded', false)
        .set('uploadingProgress', 0);
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_DONE': {
      return state.set('isUploading', false).set('uploaded', true);
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_PROGRESS': {
      const progress =
        parseFloat(
          parseFloat(
            action.payload.progress.loaded / action.payload.progress.total
          ).toFixed(3)
        ) * 100;
      return state.set('uploadingProgress', progress);
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_FAILED': {
      const errors = action.errors;
      return state.set('errors', fromJS(errors)).set('isUploading', false);
    }
    default: {
      return state;
    }
  }
}
