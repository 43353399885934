/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadSecurityRouterServices(id) {
  return async (dispatch) => {
    if (id === null || !isFinite(id)) {
      return dispatch({
        type: 'LOAD_SECURITY_ROUTER_SERVICES_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    id = parseInt(id, 10);
    dispatch({ type: 'LOAD_SECURITY_ROUTER_SERVICES_STARTED' });
    const requestUrl = `${config.apiServer}/configurations/security-router-services/${id}`;

    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'LOAD_SECURITY_ROUTER_SERVICES_DONE',
        payload: { services: response.body.services }
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_SECURITY_ROUTER_SERVICES_FAILED',
        payload: { msg: response.body.msg }
      });
    }
  };
}
