/** @format */

import save from './save';
import move from './move';
import create from './create';
import createSecurityRouter from './createSecurityRouter';
import saveSecurityRouter from './saveSecurityRouter';
import validateSecurityRouter from './validateSecurityRouter';
import changeBillingPeriod from './changeBillingPeriod';
import editInvoiceReference from './editInvoiceReference';
import editAdminNotes from './editAdminNotes';
import editAdditionalInformation from './editAdditionalInformation';
import validateVoucher from './validateVoucher';
import saveSubscription from './saveSubscription';
import editArcService from './editArcService';
import editSubscriptionDetails from './editSubscriptionDetails';
import editEndCustomerDetails from './editEndCustomerDetails';
import editInstallationSite from './editInstallationSite';

const exports = {
  inputChange,
  inputReceiverMatrixChange,
  changeAlarmCenter,
  save,
  create,
  createSecurityRouter,
  saveSecurityRouter,
  move,
  toggleAlarmCenterInput,
  validateSecurityRouter,
  print,
  changeBillingPeriod,
  editInvoiceReference,
  validateVoucher,
  editAdminNotes,
  editAdditionalInformation,
  saveSubscription,
  editArcService,
  editSubscriptionDetails,
  editEndCustomerDetails,
  editInstallationSite
};

export default exports;

function inputReceiverMatrixChange({ name, group, position, index }) {
  return {
    type: 'CONFIGURATION_EDIT_INPUT_RECEIVER_MATRIX_CHANGE',
    payload: {
      name,
      group,
      position,
      index
    }
  };
}

function changeAlarmCenter({ alarmCenter, index, networkType, gprsDisabled }) {
  return (dispatch, getState) => {
    if (alarmCenter !== 'other' && alarmCenter !== 'local') {
      alarmCenter = getState()
        .global.get('predefinedAlarmCenters')
        .find((center) => center.get('name') === alarmCenter);
    }
    dispatch({
      type: 'CONFIGURATION_EDIT_CHANGE_ALARM_CENTER',
      payload: {
        alarmCenter,
        index,
        networkType,
        gprsDisabled
      }
    });
  };
}

function print() {
  window.print();
  return { type: 'CONFIGURATION_EDIT_PRINT' };
}

function inputChange(payload) {
  return {
    type: 'CONFIGURATION_EDIT_INPUT_CHANGE',
    payload
  };
}

function toggleAlarmCenterInput(payload) {
  return {
    type: 'CONFIGURATION_EDIT_TOGGLE_ALARM_CENTER_INPUT',
    payload
  };
}
