/** @format */

import expect from '@edgeguideab/expect';
import validateAlarmCenter from './alarmCenter';

export default function validateVoucher({
  voucher,
  configuration,
  country,
  eagleEye,
  editConfigurationFeatures
}) {
  return expect(
    {
      alarmCenters: {
        type: 'array',
        allowNull: true,
        items: (alarmCenter) =>
          validateAlarmCenter(
            alarmCenter,
            country,
            voucher,
            editConfigurationFeatures
          )
      },
      eagleEye: {
        type: 'boolean',
        allowNull: true,
        condition: (ee) => (ee ? voucher.eagleEye : true)
      }
    },
    { ...configuration, eagleEye: eagleEye ? eagleEye : configuration.eagleEye }
  ).errors();
}
