/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  progress: 0,
  done: false,
  error: '',
  isFetching: false,
  dalmStatusId: null
});

export default function getDalmStatusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DALM_STATUS_INITIATE_STARTED': {
      return state
        .set('progress', 0)
        .set('done', false)
        .set('isFetching', true)
        .set('error', '');
    }
    case 'DALM_STATUS_POLL_STARTED': {
      return state.set('isFetching', true).set('error', '');
    }
    case 'DALM_STATUS_INITIATE_FAILED':
    case 'DALM_STATUS_POLL_FAILED': {
      return state
        .set('isFetching', false)
        .set('progress', 100)
        .set('done', false)
        .set('error', action.payload);
    }
    case 'DALM_STATUS_INITIATE_DONE': {
      return state
        .set('isFetching', false)
        .set('progress', 0)
        .set('done', false)
        .set('dalmStatusId', null);
    }
    case 'DALM_STATUS_POLL_DONE': {
      return state
        .set('isFetching', false)
        .set('progress', action.payload.progress)
        .set('dalmStatusId', action.payload.id)
        .set('done', action.payload.done);
    }
    case 'UNMOUNT_GET_DALM_STATUS': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
