/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  subscriptions: [],
  page: 0,
  pageLimit: 10,
  offset: 0,
  total: 0,
  sortedBy: 'createdAt',
  sortOrder: 'descending',
  searchQuery: '',
  oldSearch: '',
  selectedRow: null
});

export default function listUnactiveSubscriptions(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'UNACTIVE_SUBSCRIPTIONS_CHANGE_PAGE': {
      return state
        .set('page', action.payload)
        .set('offset', (action.payload - 1) * state.get('pageLimit'));
    }
    case 'UNACTIVE_SUBSCRIPTIONS_LIST_INIT_STATE': {
      const {
        page = state.get('page'),
        searchQuery = state.get('searchQuery'),
        pageLimit = state.get('pageLimit'),
        sortOrder = state.get('sortOrder'),
        sortedBy = state.get('sortedBy'),
        offset = state.get('offset')
      } = action.payload;

      return INITIAL_STATE.merge(
        fromJS({
          page,
          searchQuery,
          pageLimit,
          sortOrder,
          sortedBy,
          offset
        })
      );
    }
    case 'UNACTIVE_SUBSCRIPTIONS_LIST_SORT': {
      const column = action.payload.column;
      const order = action.payload.order;
      return state.set('sortedBy', column).set('sortOrder', order);
    }
    case 'UNACTIVE_SUBSCRIPTIONS_LOAD_START': {
      if (!action.payload.append) {
        state = state.set('subscriptions', fromJS([]));
      }
      return state.set('error', '').set('loading', true);
    }
    case 'UNACTIVE_SUBSCRIPTIONS_LOAD_DONE': {
      let newState = state;
      const newSubscriptions = fromJS(action.payload.subscriptions);
      if (action.payload.append) {
        newState = newState.set(
          'subscriptions',
          state.get('subscriptions').push(...newSubscriptions)
        );
      } else {
        newState = newState.set('subscriptions', newSubscriptions);
      }
      return newState
        .set('loading', false)
        .set('total', action.payload.total)
        .set('page', action.payload.page)
        .set('offset', action.payload.offset);
    }
    case 'UNACTIVE_SUBSCRIPTIONS_LOAD_FAILED': {
      const msg = action.payload.msg;
      return state.set('loading', false).set('error', msg);
    }
    case 'UNACTIVE_SUBSCRIPTIONS_SEARCH_BAR_INPUT_CHANGE': {
      return state.set('searchQuery', action.payload);
    }
    case 'CLEAR_SEARCH': {
      return state
        .set('oldSearch', state.get('searchQuery'))
        .set('searchQuery', '');
    }
    case 'RECOVER_OLD_SEARCH': {
      return state
        .set('searchQuery', state.get('oldSearch'))
        .set('oldSearch', '');
    }
    case 'SELECT_ROW_SUBSCRIPTION_QR_ACTIVATION': {
      return state.set('selectedRow', action.payload);
    }
    default: {
      return state;
    }
  }
}
