/** @format */

import request from '@edgeguideab/client-request';
import expect from '@edgeguideab/expect';
import config from 'config';
import configurationListActions from 'actions/configuration/list';

export default function move({ configurationId, folderId = null }) {
  return async (dispatch) => {
    const expectations = expect(
      {
        folderId: {
          type: 'number',
          parse: true,
          allowNull: true,
          errorCode: 'configuration.choose.folder'
        },
        configurationId: {
          type: 'number',
          parse: true,
          errorCode: ''
        }
      },
      { configurationId, folderId }
    );

    if (!expectations.wereMet())
      return dispatch({
        type: 'MOVE_CONFIGURATION_FAILED',
        payload: expectations.errors()
      });
    folderId = folderId || 'unsorted';
    dispatch({ type: 'MOVE_CONFIGURATION_START' });
    try {
      await request.put(
        `${config.apiServer}/configurations/${configurationId}/move/${folderId}`
      );
    } catch (response) {
      return dispatch({
        type: 'MOVE_CONFIGURATION_FAILED',
        payload: {
          folderId: [].concat(response.body.msg || 'errors.maintenance')
        }
      });
    }

    dispatch({ type: 'MOVE_CONFIGURATION_DONE' });
    dispatch(configurationListActions.get({ fromFolder: true }));
    setTimeout(() => dispatch({ type: 'HIDE_MOVE_CONFIGURATION_MODAL' }), 1000);
  };
}
