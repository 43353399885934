/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  loading: false,
  data: {},
  error: '',
  total: 0,
  count: 0
});

export default function organizationsDownloadCsvFileReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_DOWNLOAD_ORGANIZATIONS_CSV_FILE_MODAL': {
      return state
        .set('show', true)
        .set('loading', true)
        .set('count', 0)
        .set('total', 0);
    }
    case 'LOADING_DOWNLOAD_ORGANIZATIONS_CSV_FILE_DATA': {
      return state
        .set('count', action.payload.count)
        .set('total', action.payload.total);
    }
    case 'LOADING_DOWNLOAD_ORGANIZATIONS_CSV_FILE_DATA_DONE': {
      return state.set('loading', false).set('data', fromJS(action.payload));
    }
    case 'HIDE_DOWNLOAD_ORGANIZATIONS_CSV_FILE_MODAL': {
      return INITIAL_STATE;
    }
    case 'LOADING_DOWNLOAD_ORGANIZATIONS_CSV_FILE_DATA_FAILED': {
      return state.set('loading', false).set('error', action.payload.msg);
    }
    default: {
      return state;
    }
  }
}
