/** @format */

export default function validateTestModeSettings(configuration) {
  const testModeSettings = [
    'testModeStartDuration',
    'testModeConfirmTimeout',
    'testModeWarningTimeout',
    'testModeExtensionDuration'
  ];

  return testModeSettings.reduce(
    (acc, settingsKey) => ({
      ...acc,
      [settingsKey]: {
        type: 'number',
        errorCode: 'validation.test.mode',
        allowNull: testModeSettings.every(
          (setting) => configuration[setting] === null
        )
      }
    }),
    {}
  );
}
