/** @format */

import isIp from 'is-ip';
import { subnet } from 'ip-utils';

export default function validateVds({ vds, lans }) {
  const isSet = vds.active;
  return {
    type: 'object',
    allowNull: true,
    keys: isSet
      ? {
          name: {
            type: 'string',
            errorCode: 'validation.not.valid.name'
          },
          lan: {
            type: 'number',
            parse: true,
            condition: (lanNumber) =>
              checkIfInRange(lans, lanNumber, vds.address),
            errorCode: 'validation.vds.out.of.range'
          },
          address: {
            type: 'string',
            condition: (ipAddress) => isIp(ipAddress),
            errorCode: 'validation.ipAddress.notselected'
          },
          port: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.port'
          },
          pollInterval: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.poll.interval'
          },
          supervisionInterval: {
            type: 'number',
            parse: true,
            allowNull: true,
            errorCode: 'validation.not.valid.supervision.interval'
          },
          supervisionSequenceChannel: {
            type: 'string',
            allowNull: false,
            errorCode: 'validation.not.valid.supervision.sequence.channel'
          },
          vdsOutputs: {
            type: 'array',
            items: (vdsOutput) => validateVdsOutput({ vdsOutput })
          }
        }
      : {}
  };
}

function validateVdsOutput({ vdsOutput }) {
  const isSet = vdsOutput.active;
  return {
    type: 'object',
    allowNull: true,
    keys: isSet
      ? {
          vdsOutputNumber: {
            type: 'number',
            parse: true,
            errorCode: 'validation.not.valid.output.number'
          }
        }
      : {}
  };
}

function checkIfInRange(lans, lanNumber, ip) {
  const lan = lans.find((l) => l.position === lanNumber);
  return lan && subnet(lan.ipAddress + '/' + lan.prefix).contains(ip);
}
