/** @format */

import { fromJS } from 'immutable';

const INITIAL_FREE_PORTS = [1, 2, 3, 4];
const INITIAL_STATE = fromJS(getInitialState());

export default function lansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value, params } = action.payload;
      if (index !== undefined && type === 'lans') {
        if (params && params.type === 'reservation') {
          return state.setIn(
            [
              'data',
              index,
              'dhcpAddressReservations',
              params.index,
              params.name
            ],
            value
          );
        }
        if (name.includes('port')) {
          const selectedPort = parseInt(name.substring(4), 10);
          if (value) {
            state = state.setIn(
              ['data', index, 'ports'],
              state.getIn(['data', index, 'ports']).push(selectedPort)
            );
          } else {
            state = state.setIn(
              ['data', index, 'ports'],
              state
                .getIn(['data', index, 'ports'])
                .filter((p) => p !== selectedPort)
            );
          }
          const freePositions = INITIAL_FREE_PORTS.filter(
            (port) =>
              !state.get('data').find((lan) => lan.get('ports').includes(port))
          );
          return state.set('freePositions', fromJS(freePositions));
        }
        if (name === 'dhcpServer') {
          state = state.setIn(
            ['data', index, 'dhcpAddressReservations'],
            fromJS([])
          );
        }
        return state
          .setIn(['data', index, name], value)
          .setIn(['data', index, 'edited'], true);
      } else if (action.payload.name === 'product') {
        const data = state
          .get('data')
          .filter((lan) => parseInt(lan.get('position'), 10) < 2)
          .map((lan) => lan.set('path', 'wwan'))
          .toJS();
        state = state.set('data', fromJS(data));
      } else if (
        (type === 'ipv4' &&
          name !== 'respondToWanPing' &&
          value.includes('disabled') &&
          params.ipv6.includes('none')) ||
        (type === 'ipv6' &&
          value.includes('none') &&
          params.ipv4.includes('disabled'))
      ) {
        const lans = state.get('data');
        lans.forEach((lan, i) => {
          state = state.setIn(['data', i, 'path'], 'wwan');
        });
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_LAN': {
      const lans = state.get('data').toJS();
      const configuration = action.payload.configuration.toJS();
      lans.push(getDefaultLan(lans.length + 1, configuration.product));
      return state.set('data', fromJS(lans));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_DHCP_ADDRESS_RESERVATION': {
      const dhcpReservations = state
        .getIn([
          'data',
          action.payload.params.lanIndex,
          'dhcpAddressReservations'
        ])
        .toJS();
      dhcpReservations.push(getDefaultDhcpReservation());
      return state.setIn(
        ['data', action.payload.params.lanIndex, 'dhcpAddressReservations'],
        fromJS(dhcpReservations)
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_LAN': {
      let lans = state.get('data').delete(action.payload.index);
      for (let i = 0; i < lans.size; i++) {
        lans = lans.setIn([i, 'position'], i + 1);
      }
      const freePositions = INITIAL_FREE_PORTS.filter(
        (port) => !lans.find((lan) => lan.get('ports').includes(port))
      );
      return state
        .set('data', lans)
        .set('freePositions', fromJS(freePositions));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_DHCP_ADDRESS_RESERVATION': {
      const dhcpReservations = state
        .getIn(['data', action.payload.position, 'dhcpAddressReservations'])
        .delete(action.payload.index);

      return state.setIn(
        ['data', action.payload.position, 'dhcpAddressReservations'],
        dhcpReservations
      );
    }
    default: {
      return state;
    }
  }
}
function getInitialState() {
  const INITIAL_LANS_JSON = {
    freePositions: INITIAL_FREE_PORTS,
    data: []
  };
  return INITIAL_LANS_JSON;
}

function getDefaultLan(position, product) {
  if (position) {
    return {
      ipAddress: '192.168.0.1',
      prefix: 24,
      position: position,
      dhcpServer: true,
      start: 100,
      end: 254,
      dns: '',
      path: product === 'nena' ? 'wwan' : product === 'pangea' ? 'auto' : null,
      label: '',
      edited: false,
      ports: [],
      dhcpAddressReservations: []
    };
  }
  return {
    ipAddress: '',
    prefix: 0,
    position: null,
    dhcpServer: false,
    start: 0,
    end: 0,
    dns: '',
    path: product === 'nena' ? 'wwan' : product === 'pangea' ? 'auto' : null,
    label: '',
    edited: false,
    ports: [],
    dhcpAddressReservations: []
  };
}

function getDefaultDhcpReservation() {
  return {
    ipAddress: '',
    macAddress: '',
    label: ''
  };
}
