/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  endUser: {
    name: '',
    organizationNumber: '',
    address: '',
    addressAlt: '',
    zipCode: '',
    city: ''
  },
  contact: {
    name: '',
    phoneNumber: '',
    email: '',
    mobilePhoneNumber: ''
  },
  installationData: {
    address: '',
    addressAlt: '',
    zipCode: '',
    city: ''
  },
  installer: {
    name: '',
    phoneNumber: '',
    email: ''
  },
  invoice: {
    to: null
  }
});

export default function contactInformationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { name, index, value, type } = action.payload;
      if (index !== undefined && type === 'contactInformation') {
        return state.setIn([index, name], value);
      }
      return state;
    }
    case 'EDIT_INVOICE_INFO_DONE': {
      return state.setIn(
        ['invoice', action.payload.name],
        action.payload.value
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_END_CUSTOMER_DETAILS_CHANGED': {
      return state.set('invoice', action.payload.invoice);
    }
    default: {
      return state;
    }
  }
}
