/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    folderId: ''
  },
  export: false,
  isShowing: false,
  isMoving: false,
  moved: false,
  configuration: Map(),
  folderId: undefined,
  name: '',
  error: Map()
});

export default function moveConfigurationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_MOVE_CONFIGURATION_MODAL': {
      return state
        .merge(INITIAL_STATE)
        .set('isShowing', true)
        .set('isMoving', false)
        .set('configuration', action.payload.configuration)
        .setIn(
          ['form', 'folderId'],
          action.payload.configuration.get('folderId') || ''
        );
    }
    case 'HIDE_EXPORT_CONFIGURATION_MODAL':
    case 'HIDE_MOVE_CONFIGURATION_MODAL': {
      return state.set('isShowing', false).set('id', undefined);
    }
    case 'MOVE_CONFIGURATION_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'MOVE_CONFIGURATION_SHOW_EXPORT_CONFIRMATION': {
      return state.set('export', true);
    }
    case 'EXPORT_CONFIGURATION_START':
    case 'MOVE_CONFIGURATION_START': {
      return state.set('isMoving', true).set('error', Map());
    }
    case 'EXPORT_CONFIGURATION_DONE':
    case 'MOVE_CONFIGURATION_DONE': {
      return state.set('moved', true);
    }
    case 'MOVE_CONFIGURATION_FAILED': {
      const errors = action.payload;
      return state
        .set('isMoving', false)
        .set('moved', false)
        .set('errors', fromJS(errors));
    }
    default: {
      return state;
    }
  }
}
