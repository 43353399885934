/** @format */

import { fromJS, Map } from 'immutable';
import lans from './lans';
import ipv4 from './ipv4';
import ipv6 from './ipv6';
import vpns from './vpns';
import alarmCenters from './alarmCenters';
import inputs from './inputs';
import errorInputs from './errorInputs';
import outputs from './outputs';
import relays from './relays';
import receivers from './receivers';
import serialPorts from './serialPorts';
import contactInformation from './contactInformation';
import mobileData from './mobileData';
import eagleEye from './eagleEye';
import eagleEyeTexts from './eagleEyeTexts';
import line from './line';
import voicePhrases from './voicePhrases';
import ipProtocolsDc09s from './ipProtocolsDc09s';
import bacnet from './bacnet';
import portForwarding from './portForwarding';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  configuration: getInitialConfiguration(),
  validationErrors: {},
  services: {}
});

export default function configurationReducer(state = INITIAL_STATE, action) {
  state = state.setIn(
    ['configuration', 'lans'],
    lans(state.getIn(['configuration', 'lans']), action)
  );
  state = state.setIn(
    ['configuration', 'ipv4'],
    ipv4(state.getIn(['configuration', 'ipv4']), action)
  );
  state = state.setIn(
    ['configuration', 'ipv6'],
    ipv6(state.getIn(['configuration', 'ipv6']), action)
  );
  state = state.setIn(
    ['configuration', 'vpns'],
    vpns(state.getIn(['configuration', 'vpns']), action)
  );
  state = state.setIn(
    ['configuration', 'alarmCenters'],
    alarmCenters(state.getIn(['configuration', 'alarmCenters']), action)
  );
  state = state.setIn(
    ['configuration', 'inputs'],
    inputs(state.getIn(['configuration', 'inputs']), action)
  );
  state = state.setIn(
    ['configuration', 'errorInputs'],
    errorInputs(state.getIn(['configuration', 'errorInputs']), action)
  );
  state = state.setIn(
    ['configuration', 'outputs'],
    outputs(state.getIn(['configuration', 'outputs']), action)
  );
  state = state.setIn(
    ['configuration', 'relays'],
    relays(state.getIn(['configuration', 'relays']), action)
  );
  state = state.setIn(
    ['configuration', 'receivers'],
    receivers(state.getIn(['configuration', 'receivers']), action)
  );
  state = state.setIn(
    ['configuration', 'serialPorts'],
    serialPorts(state.getIn(['configuration', 'serialPorts']), action)
  );
  state = state.setIn(
    ['configuration', 'contactInformation'],
    contactInformation(
      state.getIn(['configuration', 'contactInformation']),
      action
    )
  );
  state = state.setIn(
    ['configuration', 'mobileData'],
    mobileData(state.getIn(['configuration', 'mobileData']), action)
  );
  state = state.setIn(
    ['configuration', 'eagleEye'],
    eagleEye(state.getIn(['configuration', 'eagleEye']), action)
  );
  state = state.setIn(
    ['configuration', 'eagleEyeTexts'],
    eagleEyeTexts(state.getIn(['configuration', 'eagleEyeTexts']), action)
  );
  state = state.setIn(
    ['configuration', 'line'],
    line(state.getIn(['configuration', 'line']), action)
  );
  state = state.setIn(
    ['configuration', 'ipProtocolsDc09s'],
    ipProtocolsDc09s(state.getIn(['configuration', 'ipProtocolsDc09s']), action)
  );
  state = state.setIn(
    ['configuration', 'voicePhrases'],
    voicePhrases(state.getIn(['configuration', 'voicePhrases']), action)
  );
  state = state.setIn(
    ['configuration', 'bacnet'],
    bacnet(state.getIn(['configuration', 'bacnet']), action)
  );
  state = state.setIn(
    ['configuration', 'portForwardingRules'],
    portForwarding(
      state.getIn(['configuration', 'portForwardingRules']),
      action
    )
  );
  switch (action.type) {
    case 'NEW_SECURITY_ROUTER_CONFIGURATION': {
      let merged = state.merge(INITIAL_STATE);
      action.payload.keep.forEach((path) => {
        merged = merged.setIn(path, state.getIn(path));
      });
      const products = action.payload.products || Map();
      if (products.size === 1) {
        merged = merged.setIn(
          ['configuration', 'product'],
          Object.keys(products.toJS())[0]
        );
      }
      return merged.setIn(
        ['configuration', 'country'],
        action.payload.country.get('isoCode')
      );
    }
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE': {
      const configuration = fromJS(action.payload);
      return state
        .set('configuration', configuration)
        .set('loading', false)
        .setIn(
          ['configuration', 'voiceActivated'],
          state.getIn(['configuration', 'voiceActivated']) ||
            !!action.payload.voicePhrases.length ||
            action.payload.receivers.some(
              (receiver) => receiver.type === 'voice'
            )
        );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_RESET_STATE':
      return INITIAL_STATE;
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_FAILED':
    case 'ADD_FAVORITE_SECURITYROUTER_FAILED':
    case 'REMOVE_FAVORITE_SECURITYROUTER_FAILED': {
      return INITIAL_STATE.set('error', action.payload.msg).set(
        'loading',
        false
      );
    }
    case 'REMOVE_FAVORITE_SECURITYROUTER_SUCCESS': {
      return state.setIn(['configuration', 'isFavorite'], false);
    }
    case 'ADD_FAVORITE_SECURITYROUTER_SUCCESS': {
      return state.setIn(['configuration', 'isFavorite'], true);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.type === 'eagleEye' &&
        action.payload.name === 'eagleEyeNotifications' &&
        !action.payload.value
      ) {
        return state.setIn(['configuration', 'facilityName'], '');
      } else if (
        action.payload.index !== undefined ||
        action.payload.type !== undefined
      ) {
        return state;
      }
      if (action.payload.name === 'product') {
        state = state.setIn(['configuration', 'expansionBoard'], 'none');
      }
      return state
        .setIn(['configuration', action.payload.name], action.payload.value)
        .setIn(['configuration', 'lastUnsavedChange'], true);
    }
    case 'SAVE_SECURITY_ROUTER_CONFIGURATION_STARTED': {
      return state.set('validationErrors', fromJS({}));
    }
    case 'SAVE_SECURITY_ROUTER_CONFIGURATION_DONE': {
      return state.merge(INITIAL_STATE);
    }
    case 'SAVE_SECURITY_ROUTER_CONFIGURATION_FAILED': {
      return state.set('validationErrors', fromJS(action.payload));
    }
    case 'VALIDATE_SECURITY_ROUTER_CONFIGURATION_STARTED': {
      return state.set('validationErrors', fromJS({}));
    }
    case 'VALIDATE_SECURITY_ROUTER_CONFIGURATION_FAILED': {
      return state.set('validationErrors', fromJS(action.payload));
    }
    case 'COPIED_SECURITY_ROUTER_CONFIGURATION_DONE': {
      return state
        .setIn(['configuration', 'name'], '')
        .setIn(['configuration', 'status'], 'factory')
        .setIn(['configuration', 'createNew'], true)
        .setIn(['configuration', 'copied'], true)
        .setIn(['configuration', 'dalmProgrammedAt'], null);
    }
    case 'LOAD_SECURITY_ROUTER_SERVICES_STARTED': {
      return state.set('error', '').set('loading', true);
    }
    case 'LOAD_SECURITY_ROUTER_SERVICES_DONE': {
      return state
        .set('services', fromJS(action.payload.services))
        .set('loading', false);
    }
    case 'LOAD_SECURITY_ROUTER_SERVICES_FAILED': {
      return state.set('error', fromJS(action.payload)).set('loading', false);
    }
    case 'SECURITY_ROUTER_SERVICES_UPDATE_DONE': {
      return state
        .set('services', fromJS(action.payload.services))
        .setIn(['configuration', 'status'], fromJS(action.payload.status))
        .set('loading', false);
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_SERVICE_TOGGLE': {
      return state.setIn(
        ['configuration', 'voiceActivated'],
        !state.getIn(['configuration', 'voiceActivated'])
      );
    }
    case 'EDIT_INVOICE_REFERENCE_DONE': {
      return state.setIn(
        ['configuration', 'invoiceReference'],
        action.payload.invoiceReference
      );
    }
    case 'EDIT_ADMIN_NOTES_DONE': {
      return state.setIn(['configuration', 'adminNotes'], action.payload);
    }
    default: {
      return state;
    }
  }
}

function getInitialConfiguration() {
  return {
    name: '',
    id: 0,
    createdAt: '',
    folderId: 0,
    product: 'pangea',
    oem: 'standard',
    hardwareVersion: 2,
    facilityName: '',
    expansionBoard: 'none',
    lans: lans(undefined, { type: '' }),
    ipv4: ipv4(undefined, { type: '' }),
    ipv6: ipv6(undefined, { type: '' }),
    vpns: vpns(undefined, { type: '' }),
    alarmCenters: alarmCenters(undefined, { type: '' }),
    inputs: inputs(undefined, { type: '' }),
    errorInputs: errorInputs(undefined, { type: '' }),
    outputs: outputs(undefined, { type: '' }),
    relays: relays(undefined, { type: '' }),
    receivers: receivers(undefined, { type: '' }),
    serialPorts: serialPorts(undefined, { type: '' }),
    contactInformation: contactInformation(undefined, { type: '' }),
    mobileData: mobileData(undefined, { type: '' }),
    eagleEye: eagleEye(undefined, { type: '' }),
    eagleEyeTexts: eagleEyeTexts(undefined, { type: '' }),
    line: line(undefined, { type: '' }),
    ipProtocolsDc09s: ipProtocolsDc09s(undefined, { type: '' }),
    voicePhrases: voicePhrases(undefined, { type: '' }),
    voiceActivated: false,
    country: 'OTHER',
    adminNotes: '',
    bacnet: bacnet(undefined, { type: '' }),
    portForwardingEnabled: false,
    portForwardingRules: portForwarding(undefined, { type: '' }),
    isFavorite: false,
    lineSpeed: 'auto',
    lastUnsavedChange: false
  };
}
