/** @format */

import { fromJS } from 'immutable';
import events from './events';
import cameras from './cameras';
import receivers from './receivers';

const INITIAL_STATE = fromJS({
  events: events(undefined, { type: '' }),
  cameras: cameras(undefined, { type: '' }),
  receivers: receivers(undefined, { type: '' })
});

export default function configurationReducer(state = INITIAL_STATE, action) {
  state = state.set('events', events(state.get('events'), action));
  state = state.set('cameras', cameras(state.get('cameras'), action));
  state = state.set('receivers', receivers(state.get('receivers'), action));
  switch (action.type) {
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
