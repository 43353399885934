/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  error: '',
  done: true,
  canceled: false,
  alarms: {
    positions: [],
    testingNow: null
  },
  lastTestedPositions: [],
  showInputs: false
});

export default function alarmTestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ALARM_TEST_RESET_STATE':
      return INITIAL_STATE;
    case 'ALARM_TEST_CANCEL_STARTED': {
      return state.set('error', '').set('canceled', true);
    }
    case 'ALARM_TEST_CANCEL_FAILED': {
      return state.set('error', action.payload).set('canceled', false);
    }
    case 'LOAD_CONFIGURATION_DONE': {
      return INITIAL_STATE.set(
        'done',
        action.payload.busyWith !== 'alarmTest.ongoing'
      );
    }
    case 'ALARM_TEST_START_STARTED': {
      return state.set('error', '').set('done', false).set('canceled', false);
    }
    case 'ALARM_TEST_POLL_DONE': {
      if (action.payload) {
        state = state.set('alarms', fromJS(action.payload));
      }
      return state.set('done', !action.payload.testingNow);
    }
    case 'ALARM_TEST_START_FAILED':
    case 'ALARM_TEST_POLL_FAILED': {
      return state
        .setIn(['alarms', 'testingNow'], null)
        .set('error', action.payload)
        .set('done', true);
    }
    case 'ALARM_TEST_START_DONE': {
      return state
        .set('alarms', fromJS(action.payload.alarms))
        .set('lastTestedPositions', action.payload.testingPositions);
    }
    case 'SHOW_INPUTS': {
      return state.set('showInputs', true);
    }
    default: {
      return state;
    }
  }
}
