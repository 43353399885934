/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function outputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value } = action.payload;
      if (index !== undefined && type === 'outputs') {
        if (name === 'optionalFunctionality') {
          state = state.setIn([index, 'pulse'], false);
        } else if (name === 'isArm' && !value) {
          state = state.setIn([index, 'inputFeedback'], null);
        }
        return state.setIn([index, name], value);
      } else if (name === 'expansionBoard') {
        if (value === 'none') {
          state = state.filter((output) => output.get('position') < 5);
        } else if (state.size < 5) {
          const additionalPositions = [5, 6, 7, 8];
          state = state.concat(
            fromJS(additionalPositions.map(getDefaultOutput))
          );
        }
      } else if (name === 'product') {
        state = state.filter((output) => output.get('position') < 5);
      } else if (
        index !== undefined &&
        type === 'inputs' &&
        name === 'label' &&
        value === ''
      ) {
        const outputs = state.map((output) => {
          if (parseInt(output.get('inputFeedback'), 10) === index + 1) {
            output = output.set('inputFeedback', null);
          }
          return output;
        });
        return outputs;
      }
      return state;
    }
    case 'EAGLE_EYE_REMOTE_CONTROL_UPDATE': {
      return state.map((oldOutput) => {
        const updatedOutput = action.payload.outputs.find(
          (output) => output.position === oldOutput.get('position')
        );
        return updatedOutput ? fromJS(updatedOutput) : oldOutput;
      });
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const defaultPositions = [1, 2, 3, 4];
  return defaultPositions.map(getDefaultOutput);
}

function getDefaultOutput(position) {
  return {
    label: '',
    activeText: '',
    type: 'output',
    inactiveText: '',
    pulseLength: 0,
    isArm: false,
    outputType: null,
    pulse: false,
    position,
    outputFunction: null,
    optionalFunctionality: null,
    inputFeedback: null,
    isImported: false
  };
}
