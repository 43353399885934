/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'WARNING_NO_INVOICE_INFORMATION_MODAL_SHOW': {
      return state.set('show', true);
    }
    case 'WARNING_NO_INVOICE_INFORMATION_MODAL_HIDE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
