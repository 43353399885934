/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadSubscriptionConfiguration(id) {
  return async (dispatch) => {
    if (id === null || !isFinite(id)) {
      return dispatch({
        type: 'LOAD_SUBSCRIPTION_CONFIGURATION_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    id = parseInt(id, 10);
    dispatch({ type: 'LOAD_SUBSCRIPTION_CONFIGURATION_STARTED' });

    let response;
    try {
      response = await request.get(
        `${config.apiServer}/subscriptions/${id}/configuration`
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'LOAD_SUBSCRIPTION_CONFIGURATION_FAILED',
        payload: { msg: errorResponse.body.msg }
      });
    }
    const configuration = response.body;
    dispatch({
      type: 'LOAD_SUBSCRIPTION_CONFIGURATION_DONE',
      payload: configuration
    });
  };
}
