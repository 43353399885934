/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS([]);

export default function receiversReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'receivers'
      ) {
        const name = action.payload.name;
        const value = action.payload.value;
        if (name === 'voicePin') {
          return state.setIn(
            [action.payload.index, name],
            parseInt(value) || ''
          );
        }
        return state.setIn([action.payload.index, name], value);
      } else if (
        action.payload.type === 'eagleEye' &&
        action.payload.name === 'eagleEyeNotifications' &&
        !action.payload.value
      ) {
        return INITIAL_STATE;
      }
      return state;
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_RECEIVER_ADD': {
      const receivers = state.toJS();
      receivers.push(action.payload.toJS());
      return fromJS(receivers);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_RECEIVER': {
      const receivers = state.toJS();
      receivers.push(getInitialReceiver());
      return fromJS(receivers);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_RECEIVER': {
      const receivers = state.toJS();
      receivers.splice(action.payload.index, 1);
      return fromJS(receivers);
    }
    default: {
      return state;
    }
  }
}

function getInitialReceiver() {
  return {
    name: '',
    areaFilters: '',
    eventFilters: '',
    sectionFilters: '',
    address: '',
    type: 'push',
    language: 'en_GB'
  };
}
