/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  errors: {}
});

export default function kernelUpgradeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'KERNEL_UPGRADE_MISSING_PARAMETER':
      return state.set('errors', fromJS(action.payload.errors));
    case 'KERNEL_UPGRADE_HIDE_SELECT_MODAL':
      return INITIAL_STATE;
    case 'KERNEL_UPGRADE_SHOW_SELECT_MODAL':
      return INITIAL_STATE.set('isShowing', true);
    default:
      return state;
  }
}
