/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function editInvoiceReference() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_INVOICE_REFERENCE_STARTED' });
    const state = getState();
    const configurationId = state.modals.getIn([
      'editInvoiceReference',
      'configurationId'
    ]);
    const invoiceReference = state.modals.getIn([
      'editInvoiceReference',
      'invoiceReference'
    ]);

    try {
      await request.post(
        `${config.apiServer}/configurations/invoice-reference/${configurationId}`,
        { body: { invoiceReference } }
      );
    } catch (errorResponse) {
      dispatch({
        type: 'EDIT_INVOICE_REFERENCE_FAILED',
        payload: {
          error:
            (errorResponse.body && errorResponse.body.msg) || 'errors.server'
        }
      });
      return;
    }

    dispatch({
      type: 'EDIT_INVOICE_REFERENCE_DONE',
      payload: { invoiceReference, configurationId }
    });
  };
}
