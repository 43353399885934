/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  showConfirmDialog: false,
  isSending: false,
  form: '',
  formError: ''
});

export default function deleteConfigurationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'EXPORT_CONFIGURATION_SHOW': {
      return state.set('isShowing', true);
    }
    case 'EXPORT_CONFIGURATION_HIDE': {
      return INITIAL_STATE;
    }
    case 'EXPORT_CONFIGURATION_NEXT_DIALOG': {
      return state.set('showConfirmDialog', true);
    }
    case 'EXPORT_CONFIGURATION_FORM_CHANGE': {
      return state.set('form', action.payload).set('formError', '');
    }
    case 'EXPORT_CONFIGURATION_FORM_ERROR': {
      return state.set('formError', action.payload);
    }
    case 'EXPORT_CONFIGURATION_START': {
      return state.set('formError', '').set('isSending', true);
    }
    case 'EXPORT_CONFIGURATION_DONE': {
      return state
        .set('isSending', false)
        .set('showConfirmDialog', false)
        .set('isShowing', false);
    }
    case 'EXPORT_CONFIGURATION_FAILED': {
      return state.set('isSending', false).set('showConfirmDialog', false);
    }
    default: {
      return state;
    }
  }
}
