/** @format */

import config from 'config.json';
import LanguageContainer from './languagePicker/LanguageContainer';
import NavigationContainer from './navigation/NavigationContainer';
import { useAppSelector } from 'utils/hooks/redux';

const Layout = () => {
  const session = useAppSelector((state) => state.session);

  const { loggedIn } = session.toJS();

  return loggedIn || config.homePage !== 'addsecure' ? (
    <>
      <LanguageContainer />
      <NavigationContainer />
    </>
  ) : (
    <></>
  );
};

export default Layout;
