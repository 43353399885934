/** @format */

import { fromJS } from 'immutable';

const NUMBER_OF_RELAYS = 5;

const INITIAL_STATE = fromJS(getInitialState());

export default function outputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'relays'
      ) {
        if (action.payload.name === 'optionalFunctionality') {
          state = state.setIn([action.payload.index, 'pulse'], false);
        }
        return state.setIn(
          [action.payload.index, action.payload.name],
          action.payload.value
        );
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_RELAYS_JSON = [];
  for (let i = 0; i < NUMBER_OF_RELAYS; i++) {
    const position = i + 1;
    INITIAL_RELAYS_JSON.push({
      label: '',
      type: 'relay',
      activeText: '',
      inactiveText: '',
      pulseLength: 0,
      isArm: false,
      outputType: null,
      pulse: false,
      position,
      outputFunction: null,
      optionalFunctionality: null,
      inputFeedback: null,
      isImported: false
    });
  }
  return INITIAL_RELAYS_JSON;
}
