/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  showDebug: false,
  errors: {}
});

export default function firmwareUpgradeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FIRMWARE_UPGRADE_MISSING_PARAMETER':
      return state.set('errors', fromJS(action.payload.errors));
    case 'FIRMWARE_UPGRADE_HIDE_SELECT_MODAL':
      return INITIAL_STATE;
    case 'FIRMWARE_UPGRADE_SHOW_SELECT_MODAL':
      return INITIAL_STATE.set('isShowing', true);
    case 'CHANGE_SHOW_DEBUG':
      return state.set('showDebug', !state.get('showDebug'));
    default:
      return state;
  }
}
