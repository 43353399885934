/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    name: ''
  },
  isShowing: false,
  isRenaming: false,
  renamed: false,
  error: ''
});

export default function renameFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'RENAME_FOLDER_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'SHOW_RENAME_FOLDER_MODAL': {
      return state
        .set('isShowing', true)
        .set(
          'form',
          Map({
            name: action.payload.name
          })
        )
        .set('isRenaming', false)
        .set('renamed', false)
        .set('isMoving', false);
    }
    case 'HIDE_RENAME_FOLDER_MODAL': {
      return state.set('isShowing', false);
    }
    case 'RENAME_FOLDER_START': {
      return state.set('isRenaming', true).set('error', Map());
    }
    case 'RENAME_FOLDER_DONE': {
      return state.set('renamed', true);
    }
    case 'RENAME_FOLDER_FAILED': {
      return state.set('isRenaming', false).set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
