/** @format */

import securityRouterConfigurationEditValidation from './validation/securityRouter';

export default function validateSecurityRouter(serviceName) {
  return async (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['currentSecurityRouter', 'configuration'])
      .toJS();
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );
    const country = state.global.get('country');
    const products = state.global.get('securityRouterProducts');
    const services = state.configuration.getIn([
      'currentSecurityRouter',
      'services'
    ]);
    const errors = securityRouterConfigurationEditValidation({
      configuration,
      serviceName,
      services,
      country,
      products,
      editConfigurationFeatures
    });
    dispatch({ type: 'VALIDATE_SECURITY_ROUTER_CONFIGURATION_STARTED' });
    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (Object.keys(errors).length) {
      errors.generalError = 'configuration.error.top';
      return dispatch({
        type: 'VALIDATE_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: errors
      });
    }

    dispatch({
      type: 'VALIDATE_SECURITY_ROUTER_CONFIGURATION_DONE'
    });
  };
}
