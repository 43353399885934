/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isResending: false,
  resent: false,
  id: undefined,
  name: '',
  error: null
});

export default function resendUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_USER_RESEND_MODAL': {
      return state
        .set('isShowing', true)
        .set('isResending', false)
        .set('name', action.payload.name)
        .set('id', action.payload.id)
        .set('error', null)
        .set('resent', false);
    }
    case 'HIDE_USER_RESEND_MODAL': {
      return state.set('isShowing', false).set('id', undefined);
    }
    case 'USER_RESEND_START': {
      return state.set('isResending', true).set('error', null);
    }
    case 'USER_RESEND_DONE': {
      return state.set('resent', true).set('isResending', false);
    }
    case 'USER_RESEND_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isResending', false)
        .set('resent', false)
        .set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
