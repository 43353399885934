/** @format */
import config from '../config.json';
import addsecureTheme from './addsecure';

const defaultTheme = {
  desktopSmall: '700px',
  desktopMedium: '1170px',
  desktopLarge: '1250px',
  desktopHuge: '1400px',
  desktopSmallInt: 700,

  navigationBarBackgroundColor: '#fff',
  navigationBarBackgroundAccentColor: '#f5f5f5',
  navigationBarBorderColor: 'rgba(0,0,0,0.1)',
  navigationBarItemBorderColor: 'transparent',
  navigationBarTextColor: '#000',

  generalBorderColor: '#d8d8d8',
  tableColor: '#686868',
  tableHeaderColor: '#f5f6f6',
  tableBorderColor: '#828a87',
  stripeYellow: 'rgba(215,188,55,0.18)',
  black: '#222',

  lighterGrey: '#fafafa',
  lightGrey: '#d8d8d8',
  grey: '#a8a6a8',
  middleDarkGrey: '#888888',
  darkGrey: '#545454',

  faGrey: '#656666',

  lightBackgroundColor: '#fbfcfc',

  inactiveGrey: '#a6a6a6',

  successGreen: '#68bd52',
  successGreenBackground: '#d9eed4',

  indicatorGreen: '#61bb48',
  indicatorYellow: '#f2ec61',
  indicatorOrange: '#f6aa1b',
  indicatorRed: '#d5656e',
  indicatorGrey: '#a8a6a8',

  errorRed: '#ed5e5e',
  errorRedBackground: '#fbd0d0',

  errorAlt: '#eb1919',
  errorAltLight: '#b66f75',

  linkColor: '#00b7c5',
  linkColorHover: '#00585f',

  ewmTurquoise: '#00b7c5',
  ewmTurquoiseLight: '#acc8d2',
  menuBackgroundBlack: '#2f2f2f',

  detailsConfigurationBackgroundColor: '#f7f8f8',
  detailsConfigurationInformationHeaderColor: '#e2e2e2',

  mobileButtonIconColor: '#a6a6a6',

  boxShadow: ' 0px 0px 7px 0px rgba(0, 0, 0, 0.12)',

  startPageOrange: '#f49b52',
  startPageBlack: '#273438',
  welcomeBannerGrey: '#d3d3d3',
  testModeBlue: '#4aaeee',
  yellowToggle: '#f1e935'
};

let overrides = {};

if (config.theme === 'addsecure') {
  overrides = addsecureTheme;
}

const generatedTheme = { ...defaultTheme, ...overrides };

export default generatedTheme;
