/** @format */

import { fromJS } from 'immutable';
import config from 'config';

const INITIAL_STATE = fromJS([]);

export default function vpnReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value } = action.payload;
      if (index !== undefined && type === 'vpns') {
        if (name === 'lanPosition' && value) {
          return state.setIn([index, name], parseInt(value, 10));
        }
        return state.setIn([index, name], value);
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_VPN': {
      const lans = action.payload.configuration.getIn(['lans', 'data']);
      const lanPosition = lans.getIn([0, 'position']);
      const lanMatch = lans.find((lan) => lan.get('position') === lanPosition);
      const position = state.size + 1;
      const serviceName = action.payload.params;
      return state.push(
        fromJS({
          type: serviceName === 'vpnSiteToSite' ? 'custom' : 'temporary',
          label: 'Vpn ' + position,
          lanPosition,
          position,
          localSubnet: `${lanMatch.get('ipAddress')}/${lanMatch.get('prefix')}`,
          remoteSubnet: config.vpnClientSubnet,
          VpnNats: []
        })
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_VPN': {
      state = state.filter((_, index) => index !== action.payload.index);
      state.forEach((element, i) => {
        state = state.setIn([i, 'position'], i + 1);
      });
      return state;
    }
    case 'COPIED_SECURITY_ROUTER_CONFIGURATION_DONE': {
      return state.map((vpn) => vpn.set('leftId', null));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_NAT': {
      const { vpnIndex } = action.payload;
      const vpnCopy = state.get(vpnIndex);
      const newVpn = vpnCopy.set(
        'VpnNats',
        vpnCopy.get('VpnNats').push(fromJS(getDefaultVpnNat()))
      );
      return state.set(vpnIndex, newVpn);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_NAT': {
      const { vpnIndex, natIndex } = action.payload;
      const vpnCopy = state.get(vpnIndex);
      const vpnNatsCopy = vpnCopy.get('VpnNats');
      const newVpn = vpnCopy.set(
        'VpnNats',
        vpnNatsCopy
          .slice(0, natIndex)
          .concat(vpnNatsCopy.slice(natIndex + 1, vpnNatsCopy.length))
      );
      return state.set(vpnIndex, newVpn);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_INPUT_CHANGE_NAT': {
      const { vpnIndex, natIndex, name, value } = action.payload;
      const vpnCopy = state.get(vpnIndex);
      const newVpn = vpnCopy.set(
        'VpnNats',
        vpnCopy
          .get('VpnNats')
          .set(natIndex, vpnCopy.get('VpnNats').get(natIndex).set(name, value))
      );
      return state.set(vpnIndex, newVpn);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_VPN_TYPE_CHANGED': {
      const { vpnIndex, type } = action.payload;
      const vpnCopy = state.get(vpnIndex);

      return state.set(
        vpnIndex,
        fromJS({
          type,
          id: vpnCopy.get('id'),
          lanId: vpnCopy.get('lanId'),
          lanPosition: vpnCopy.get('lanPosition'),
          position: vpnCopy.get('position'),
          VpnNats: [],
          ...(type === 'custom' && getDefaultCustomValues())
        })
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_CHANGE_VPN_SHARE': {
      const { userId, index, remove } = action.payload;
      let sharesToRemove = state.getIn([index, 'sharesToRemove']);
      let sharesToAdd = state.getIn([index, 'sharesToAdd']);
      if (remove) {
        if (sharesToRemove && sharesToRemove.size > 0) {
          if (!sharesToRemove.includes(userId)) {
            sharesToRemove = sharesToRemove.push(userId);
          }
        } else {
          sharesToRemove = fromJS([userId]);
        }
        if (sharesToAdd && sharesToAdd.size > 0) {
          sharesToAdd = sharesToAdd.filter((share) => share !== userId);
        }
      } else {
        if (sharesToAdd && sharesToAdd.size > 0) {
          if (!sharesToAdd.includes(userId)) {
            sharesToAdd = sharesToAdd.push(userId);
          }
        } else {
          sharesToAdd = fromJS([userId]);
        }
        if (sharesToRemove && sharesToRemove.size > 0) {
          sharesToRemove = sharesToRemove.filter((share) => share !== userId);
        }
      }
      return state
        .setIn([index, 'sharesToAdd'], sharesToAdd)
        .setIn([index, 'sharesToRemove'], sharesToRemove);
    }
    default: {
      return state;
    }
  }
}

function getDefaultVpnNat() {
  return {
    sourceAddress: '',
    destinationAddress: ''
  };
}

function getDefaultCustomValues() {
  return {
    authenticationMethod: 'psk',
    hash: 'sha256',
    dhGroup: 'modp2048',
    keyExchangeProtocol: 'ikev2',
    dpdAction: 'restart',
    mode: 'tunnel',
    securityProtocol: 'esp',
    securityEncryption: 'aes256',
    securityHash: 'sha256',
    pfsGroup: 'modp2048',
    encryption: 'aes256',
    remoteAddress: '',
    remoteSubnet: '',
    leftId: '',
    rightId: '',
    lifetimeConnection: 86400,
    lifetimeIke: 14400,
    disableRekey: false,
    disableReauth: false,
    disableMobike: false,
    autoConnect: false,
    dpdDelay: 30,
    localSubnet: '',
    VpnNats: [getDefaultVpnNat()]
  };
}
