/** @format */

import expect from '@edgeguideab/expect';
import { List, Map, Iterable } from 'immutable';
import validateAlarmTransmitter from './alarmTransmittter/index';
import validateEagleEye from './eagleEye/index';
import validateIpv4 from './ipv4';
import validateLan from './lan';
import validatePortForwardingRule from './portForwardingRule';
import validateContactInformation from './contactInformation';

export default function validateConfiguration({
  configuration,
  serviceName,
  country,
  products,
  services,
  editConfigurationFeatures
}) {
  if (Iterable.isIterable(configuration)) configuration = configuration.toJS();
  if (Iterable.isIterable(services)) services = services.toJS();
  const product = (products && products.get(configuration.product)) || Map();
  const productFeatures = product.get('features') || List();
  switch (serviceName) {
    case 'mobile':
      return expect(
        {
          mobileData: {
            type: 'object',
            keys: {
              data: {
                condition: (data) =>
                  data &&
                  data !== '0MB' &&
                  (data === 'privateSim' ||
                    Boolean(
                      configuration.mobileData.dataPlans.find(
                        (dp) => dp.bundle === data
                      )
                    )),
                type: 'string',
                errorCode: 'validation.mobile.data.choose'
              },
              apn: {
                type: 'string',
                errorCode: 'validation.gprsApn.notselected',
                allowNull: configuration.mobileData.data !== 'privateSim'
              },
              triggerEmails: {
                type: 'array',
                items: () => {
                  return {
                    type: 'object',
                    keys: {
                      email: {
                        type: 'string',
                        allowNull: !editConfigurationFeatures.includes(
                          'requireTriggerEmails'
                        ),
                        condition: (email) =>
                          /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                            email
                          ),
                        errorCode: 'validation.mobile.trigger.email'
                      }
                    }
                  };
                }
              },
              wwanKeepAlive: {
                type: 'number',
                errorCode: 'validation.privateSim.wwanKeepAlive',
                condition: (wwanKeepAlive) =>
                  0 < wwanKeepAlive && wwanKeepAlive <= 20,
                allowNull: false
              }
            }
          }
        },
        configuration
      ).errors();
    case 'vpn':
      return expect(
        {
          vpns: {
            type: 'array',
            items: (vpn) => {
              return {
                type: 'object',
                keys: {
                  lanPosition: {
                    type: 'number',
                    parse: true,
                    errorCode: 'validation.required.lan.position'
                  },
                  remoteSubnet: {
                    type: 'string',
                    condition: (ipAddress) =>
                      ipAddress
                        .split(',')
                        .every((address) =>
                          /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}\/(3[0-2]|[12]?[0-9])$/.test(
                            address
                          )
                        ),
                    errorCode: 'placeholder.vpn.subnet.address'
                  },
                  localSubnet: {
                    type: 'string',
                    allowNull: vpn.type !== 'custom',
                    condition: (ipAddress) =>
                      /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}\/(3[0-2]|[12]?[0-9])$/.test(
                        ipAddress
                      ),
                    errorCode: 'placeholder.vpn.ip.address'
                  }
                }
              };
            }
          }
        },
        configuration
      ).errors();
    case 'alarmTransmitter':
      return validateAlarmTransmitter({
        configuration,
        country,
        productFeatures,
        editConfigurationFeatures
      });
    case 'eagleEye':
      return validateEagleEye({ configuration });
    default:
      return expect(
        {
          name: {
            type: 'string',
            allowNullErrorCode: 'validation.required.name'
          },
          invoiceReference: {
            type: 'string',
            allowNull: productFeatures.includes('noInvoiceReference'),
            errorCode: 'validation.invoice.reference'
          },
          product: {
            type: 'string',
            condition: (prod) => products[prod] !== -1,
            errorCode: 'validation.product.notselected'
          },
          folderId: {
            type: 'number',
            parse: true,
            allowNull: true,
            errorCode: 'folder.choose.folder'
          },
          ipv4: validateIpv4({
            ipv4: configuration.ipv4,
            lans: configuration.lans.data
          }),
          lans: {
            type: 'object',
            keys: {
              data: {
                type: 'array',
                items: (lan) =>
                  validateLan(
                    {
                      lan,
                      ipv4: configuration.ipv4,
                      product: configuration.product,
                      lans: configuration.lans.data
                    },
                    product,
                    configuration,
                    services
                  )
              }
            }
          },
          contactInformation: validateContactInformation(
            configuration.contactInformation.invoice,
            country,
            editConfigurationFeatures
          ),
          portForwardingRules: {
            type: 'array',
            items: (rule) => validatePortForwardingRule(rule)
          }
        },
        configuration
      ).errors();
  }
}
