/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  id: null,
  loading: false,
  done: false,
  error: '',
  product: ''
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'THING_TERMINATE_MODAL_SHOW': {
      return state
        .set('show', true)
        .set('id', action.payload.id)
        .set('product', action.payload.product);
    }
    case 'THING_TERMINATE_MODAL_HIDE': {
      return INITIAL_STATE;
    }
    case 'THING_TERMINATE_STARTED': {
      return state.set('loading', true);
    }
    case 'THING_TERMINATE_DONE': {
      return state.set('done', true).set('loading', false);
    }
    case 'THING_TERMINATE_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    default: {
      return state;
    }
  }
};
