/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isUpdating: false,
  alarmCenterId: undefined,
  newAuthenticationCode: '',
  error: false
});

export default function editAuthenticationCodeReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'OPEN_EDIT_AUTHENTICATION_CODE_MODAL': {
      return state
        .set('isShowing', true)
        .set('isUpdating', false)
        .set('alarmCenterId', action.payload.alarmCenterId)
        .set('error', false);
    }
    case 'CLOSE_EDIT_AUTHENTICATION_CODE_MODAL': {
      return state
        .set('isShowing', false)
        .set('alarmCenterId', undefined)
        .set('newAuthenticationCode', '')
        .set('error', false);
    }
    case 'EDIT_AUTHENTICATION_CODE_INPUT_CHANGE': {
      return state.set('newAuthenticationCode', action.payload.value);
    }
    case 'EDIT_AUTHENTICATION_CODE_STARTED': {
      return state.set('isUpdating', true).set('error', false);
    }
    case 'EDIT_AUTHENTICATION_CODE_DONE': {
      return INITIAL_STATE;
    }
    case 'EDIT_AUTHENTICATION_CODE_FAILED': {
      return state.set('isUpdating', false).set('error', true);
    }
    default: {
      return state;
    }
  }
}
