/** @format */

import { translate } from './language';

export default {
  validateCredentials,
  buildFolderTree,
  getFolderName,
  searchTree,
  generateCharacterString,
  isIOSApp,
  isAndroidApp
};

const CHARACTERS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'T',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];
export function generateCharacterString(length) {
  let string = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * CHARACTERS.length);
    string += CHARACTERS[randomIndex];
  }
  return string;
}

function getFolderName(folders, selected) {
  if (selected === undefined) {
    return translate('configuration.all');
  }

  if (selected === null) {
    return translate('validation.folder.unsorted');
  }

  const { selectedFolder } = searchTree(folders, selected);
  return selectedFolder ? selectedFolder.get('name') : '';
}

export function searchTree(folders, selected) {
  if (!selected) return { selectedFolder: null };

  const selectedFolder = folders.find((f) => f.get('id') === selected);
  if (selectedFolder) return { selectedFolder };

  for (let i = 0; i < folders.size; i++) {
    const { selectedFolder: subFolder } = searchTree(
      folders.getIn([i, 'children']),
      selected
    );
    if (subFolder) return { selectedFolder: subFolder, parent: folders.get(i) };
  }

  return { selectedFolder: null };
}

export function validateCredentials({ username, password }) {
  return !!username && !!password;
}

export function buildFolderTree(folders) {
  const ownFolders = folders
    .filter((folder) => !folder.external)
    .map((folder) => ({ ...folder, children: [] }));

  const externalFolders = folders
    .filter((folder) => folder.external)
    .map((folder) => ({ ...folder, children: [] }));

  const root = ownFolders.filter(
    (folder) => !isChildToParents(folder, ownFolders)
  );
  const ownTree = buildFolderTreeHelper({
    tree: root,
    parents: root,
    remaining: ownFolders.filter(
      (folder) => !root.some((rootFolder) => rootFolder.id === folder.id)
    )
  });

  return ownTree.concat(buildExternalTree(externalFolders));
}

export function buildExternalTree(folders) {
  const externalRootFolders = folders
    .filter((folder) => folder.id === `organization${folder.organizationId}`)
    .map((organization) => ({
      ...organization,
      children: folders.filter(
        (folder) =>
          !isChildToParents(folder, folders) &&
          folder.id !== organization.id &&
          folder.organizationId === organization.organizationId
      )
    }));

  return buildFolderTreeHelper({
    tree: externalRootFolders,
    parents: externalRootFolders
      .map((organization) => organization.children)
      .flat(),
    remaining: folders.filter(
      (folder) =>
        !externalRootFolders.some(
          (root) =>
            root.id === folder.id ||
            root.children.some((child) => child.id === folder.id)
        )
    )
  });
}

function buildFolderTreeHelper({ tree, parents, remaining }) {
  if (!remaining.length) {
    return tree;
  }

  parents.forEach(
    (parent) =>
      (parent.children = remaining.filter(
        (folder) => folder.parentId === parent.id
      ))
  );

  return buildFolderTreeHelper({
    tree,
    parents: remaining.filter((folder) => isChildToParents(folder, parents)),
    remaining: remaining.filter((folder) => !isChildToParents(folder, parents))
  });
}

function isChildToParents(folder, parents) {
  return parents.some(
    (parent) => parent.id !== undefined && parent.id === folder.parentId
  );
}

function isIOSApp() {
  return /DualTech.*iOS/.test(window.navigator.userAgent);
}

function isAndroidApp() {
  return !isIOSApp() && window.navigator.userAgent.includes('DualTech');
}
