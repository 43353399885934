/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  list: []
});

export default function dalmStatusList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_DALM_STATUSES_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_DALM_STATUSES_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_DALM_STATUSES_FAILED': {
      return state.set('error', action.payload.msg).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
