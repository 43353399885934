/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isDeleting: false,
  deleted: false,
  id: undefined,
  name: '',
  configurationCount: null,
  error: ''
});

export default function deleteConfigurationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_DELETE_ORGANIZATION_MODAL': {
      return state
        .set('isShowing', true)
        .set('isDeleting', false)
        .set('name', action.payload.name)
        .set('id', action.payload.id)
        .set('configurationCount', action.payload.configurationCount);
    }
    case 'HIDE_DELETE_ORGANIZATION_MODAL': {
      return INITIAL_STATE;
    }
    case 'DELETE_ORGANIZATION_START': {
      return state.set('isDeleting', true).set('error', '');
    }
    case 'DELETE_ORGANIZATION_DONE': {
      return state.set('deleted', true);
    }
    case 'DELETE_ORGANIZATION_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isDeleting', false)
        .set('deleted', false)
        .set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
