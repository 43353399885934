/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    parentFolder: undefined,
    name: ''
  },
  isShowing: false,
  isCreating: false,
  created: false,
  error: Map()
});

export default function createFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CREATE_FOLDER_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'SHOW_CREATE_FOLDER_MODAL': {
      return state
        .merge(INITIAL_STATE)
        .set('isShowing', true)
        .set('isCreating', false)
        .setIn(
          ['form', 'parentFolder'],
          action.payload.selectedFolder.get('id')
        );
    }
    case 'HIDE_CREATE_FOLDER_MODAL': {
      return state.set('isShowing', false);
    }
    case 'CREATE_FOLDER_START': {
      return state.set('isCreating', true).set('error', Map());
    }
    case 'CREATE_FOLDER_DONE': {
      return state.set('created', true);
    }
    case 'CREATE_FOLDER_FAILED': {
      const error = action.payload.msg;
      return state.set('isCreating', false).set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
