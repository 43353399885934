/** @format */

import expect from '@edgeguideab/expect';
import validateReceiver from './receiver';
import validateOutput from './output';
import validateVoice from './voice';

export default function validateEagleEye({ configuration }) {
  return expect(
    {
      facilityName: {
        type: 'string',
        allowNull: !configuration.eagleEye.eagleEyeNotifications,
        errorCode: 'validation.eagle.eye.facility.name'
      },
      receivers: {
        type: 'array',
        items: validateReceiver
      },
      relays: {
        type: 'object',
        keys: {
          data: {
            type: 'array',
            items: validateOutput
          }
        }
      },
      outputs: {
        type: 'object',
        keys: {
          data: {
            type: 'array',
            items: validateOutput
          }
        }
      },
      voicePhrases: {
        type: 'array',
        items: validateVoice
      }
    },
    configuration
  ).errors();
}
