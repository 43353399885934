/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false
});

export default function alarmTestResultReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CLOSE_ALARM_TEST_RESULT_MODAL': {
      return state.set('isShowing', false);
    }
    case 'OPEN_ALARM_TEST_RESULT_MODAL': {
      return state.set('isShowing', true);
    }
    default: {
      return state;
    }
  }
}
