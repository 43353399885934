/** @format */

export default function validateAlarmCenter(
  alarmCenter = {},
  country,
  voucher,
  editConfigurationFeatures
) {
  return {
    type: 'object',
    allowNull: true,
    ...(Object.keys(alarmCenter).length
      ? {
          keys: {
            ...validatePolling(
              country,
              voucher['arc' + alarmCenter.position],
              editConfigurationFeatures
            )
          }
        }
      : {})
  };
}
const pollingMap = {
  none: 'none',
  'ipReceiver.supInt.sp2': 's1',
  'ipReceiver.supInt.sp3': 's2',
  'ipReceiver.supInt.sp4': 's3',
  'ipReceiver.supInt.sp5': 's4',
  'ipReceiver.supInt.sp6': 's4',
  'ipReceiver.supInt.dp1': 's1',
  'ipReceiver.supInt.dp2': 's2',
  'ipReceiver.supInt.dp3': 's3',
  'ipReceiver.supInt.dp4': 's4',
  fire: 's4',
  'alarm.class.1.s': 's1',
  'alarm.class.1.d': 's1',
  'alarm.class.2.s': 's3',
  'alarm.class.2.d': 's2',
  'alarm.class.3.4.d': 's4',
  'alarm.class.fire.s': 's4',
  'alarm.class.fire.d': 's4'
};

function validatePolling(country, voucherValue, editConfigurationFeatures) {
  if (
    country.get('isoCode') === 'SE' &&
    editConfigurationFeatures.includes('alarmClass')
  ) {
    return {
      alarmClass: {
        type: 'string',
        allowNull: true,
        condition: (alarmClass) => pollingMap[alarmClass] === voucherValue,
        errorCode: 'validation.ipReceiver.alarm.class.not.matched.voucher'
      }
    };
  }
  return {
    pollingInterval: {
      type: 'string',
      allowNull: true,
      condition: (pollingInterval) =>
        pollingMap[pollingInterval] === voucherValue,
      errorCode: 'validation.ipReceiver.polling.not.matched.voucher'
    }
  };
}
