/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  validationCode: null,
  isShowing: false,
  loading: false,
  done: false,
  error: null
});

export default function activateThingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_ACTIVATE_THING_MODAL': {
      return state
        .set('isShowing', true)
        .set('validationCode', action.value.validationCode);
    }
    case 'HIDE_ACTIVATE_THING_MODAL': {
      return INITIAL_STATE;
    }
    case 'ACTIVATE_THING_START': {
      return state.set('loading', true);
    }
    case 'ACTIVATE_THING_DONE': {
      return state.set('loading', false).set('done', true);
    }
    case 'ACTIVATE_THING_FAILED': {
      return state.set('loading', false).set('error', action.payload.msg);
    }
    default: {
      return state;
    }
  }
}
