/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  selectedAlarmCenter: -1,
  dalmStatusId: null,
  loading: false,
  progress: 0,
  status: 'initial',
  commissioning: false,
  error: ''
});

export default function commissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE':
    case 'LOAD_CONFIGURATION_DONE': {
      return action.payload.status === 'commissioning'
        ? INITIAL_STATE.set('status', 'started')
        : INITIAL_STATE;
    }
    case 'CONFIGURATION_COMMISSION_CANCEL': {
      return state
        .set('status', 'initial')
        .set('commissioning', false)
        .set('progress', 0)
        .set('error', '');
    }
    case 'CONFIGURATION_COMMISSION_STARTED': {
      return state
        .set('status', 'started')
        .set('progress', 0)
        .set('commissioning', true)
        .set('loading', false)
        .set('error', '');
    }
    case 'DALM_STATUS_INITIATE_FAILED':
    case 'DALM_STATUS_POLL_FAILED':
    case 'CONFIGURATION_COMMISSION_FAILED': {
      return state
        .set('loading', false)
        .set('status', 'initial')
        .set('progress', 0)
        .set('commissioning', false)
        .set('error', action.payload);
    }
    case 'CONFIGURATION_COMMISSION_STATUS_POLL_STARTED': {
      return state.set('showProgress', true);
    }
    case 'CONFIGURATION_COMMISSION_STATUS_POLL_DONE': {
      if (action.payload === 100) {
        state = state.set('status', 'done');
      }
      return state.set('progress', action.payload);
    }
    case 'ALARM_CENTER_SELECT': {
      return state.set('selectedAlarmCenter', action.payload.id);
    }
    case 'ABORT_COMMISSION': {
      return state
        .set('status', 'initial')
        .set('commissioning', false)
        .set('progress', 0);
    }
    case 'DALM_STATUS_INITIATE_DONE': {
      return state.set('status', 'statusPolling').set('progress', 0);
    }
    case 'DALM_STATUS_POLL_DONE': {
      return state
        .set('dalmStatusId', action.payload.id)
        .set('progress', action.payload.progress);
    }
    case 'SHOW_WARNING_BEFORE_COMMISSION': {
      return state.set('loading', false);
    }
    case 'DALM_STATUS_INITIATE_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'CONFIGURATION_DECOMMISSION_STARTED': {
      return state
        .set('loading', true)
        .set('error', '')
        .set('commissioning', true);
    }
    case 'CONFIGURATION_DECOMMISSION_FINISHED': {
      return state
        .set('loading', false)
        .set('error', '')
        .set('commissioning', false);
    }
    case 'CONFIGURATION_DECOMMISSION_FAILED': {
      return state
        .set('loading', false)
        .set('error', action.payload)
        .set('commissioning', false);
    }
    default: {
      return state;
    }
  }
}
