/** @format */

import request from '@edgeguideab/client-request';
import history from 'utils/history';
import config from 'config';

export default { changeLanguage };

const requestUrl = `${config.apiServer}/language`;

function changeLanguage(languageCode) {
  return async (dispatch) => {
    dispatch({ type: 'CHANGE_LANGUAGE_STARTED', payload: languageCode });
    try {
      const response = await request.get(requestUrl, {
        query: { languageCode }
      });
      window.LanguageKeys = response.body;
      localStorage.setItem('language', languageCode);
      dispatch({ type: 'CHANGE_LANGUAGE_DONE', payload: languageCode });
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search
      });
    } catch (response) {
      dispatch({ type: 'CHANGE_LANGUAGE_ERROR', payload: response });
    }
  };
}
