/** @format */

export default function validateReceiverSettings({
  receivers,
  receiverSettings
}) {
  const hasReceiver =
    Array.isArray(receivers) && receivers.some((receiver) => !!receiver.type);

  return {
    type: 'object',
    keys: {
      objectIdentity: {
        type: 'string',
        allowNull: !hasReceiver && !receiverSettings.inputResetText,
        errorCode: 'validation.siteIdentity'
      },
      inputResetText: {
        type: 'string',
        allowNull: !hasReceiver && !receiverSettings.objectIdentity,
        errorCode: 'validation.resetText'
      },
      emailSenderAddress: {
        type: 'string',
        condition: (email) =>
          /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(email),
        allowNull: true,
        errorCode: 'validation.not.valid.email'
      },
      smtpPassword: {
        type: 'string',
        allowNull: true,
        equalTo: ['receiverSettings', 'smtpPasswordRepeat'],
        errorCode: 'validation.required.password.match'
      }
    }
  };
}
