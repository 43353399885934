/** @format */

import { fromJS } from 'immutable';
import { getDefaultEndpoints } from './defaultWanMonitoringEndPoints';

const INITIAL_STATE = fromJS({
  ipAddress: '',
  dns: '',
  defaultGateway: '',
  netmask: '',
  networkType: 'networkType.dhcp',
  respondToWanPing: false,
  wanMonitoringSettings: {
    active: true,
    testInterval: 60,
    retries: 3
  },
  wanMonitoringList: getDefaultEndpoints()
});

export default function ipv4Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'ipv4') {
        const name = action.payload.name;
        return state.set(name, action.payload.value);
      } else if (action.payload.type === 'wanMonitoringSettings') {
        if (action.payload.index !== undefined) {
          return state.setIn(
            ['wanMonitoringList', action.payload.index, action.payload.name],
            action.payload.value
          );
        } else if (action.payload.name === 'active' && action.payload.value) {
          state = state.set('wanMonitoringList', fromJS(getDefaultEndpoints()));
          state = state.setIn(['wanMonitoringSettings', 'testInterval'], 60);
          state = state.setIn(['wanMonitoringSettings', 'retries'], 3);
        }
        return state.setIn(
          ['wanMonitoringSettings', action.payload.name],
          action.payload.value
        );
      } else if (action.payload.name === 'product') {
        return state
          .set('networkType', 'networkType.disabled')
          .setIn(['wanMonitoringSettings', 'active'], fromJS(false));
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_WAN_MONITOR_ITEM': {
      const items = state.get('wanMonitoringList').toJS();
      items.push({
        id: getNextId(items),
        address: '',
        type: 'ping',
        position: items.length + 1
      });
      return state.set('wanMonitoringList', fromJS(items));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_WAN_MONITOR_ITEM': {
      let items = state.get('wanMonitoringList');
      const index = items.findIndex(
        (item) => item.get('id') === action.payload.index
      );
      items = items.delete(index).toJS();

      items.forEach((item, i) => {
        item.position = i + 1;
      });
      return state.set('wanMonitoringList', fromJS(items));
    }
    case 'DRAG_ENDED': {
      const { result } = action.payload;
      const list = reorder(
        state.get('wanMonitoringList').toJS(),
        result.source.index,
        result.destination.index
      );
      list.forEach((item, i) => {
        item.position = i + 1;
      });
      return state.set('wanMonitoringList', fromJS(list));
    }
    case 'MOVE_END_POINT': {
      const { direction, index } = action.payload;
      const endIndex = direction === 'up' ? index - 1 : index + 1;
      const list = reorder(
        state.get('wanMonitoringList').toJS(),
        index,
        endIndex
      );
      list.forEach((item, i) => {
        item.position = i + 1;
      });
      return state.set('wanMonitoringList', fromJS(list));
    }
    default: {
      return state;
    }
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getNextId = (list) => {
  for (let i = 0; i <= list.length; i++) {
    if (list.findIndex((item) => item.id === i) === -1) {
      return i;
    }
  }
};
