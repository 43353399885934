/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showProgress: false,
  errors: {},
  validationCode: '',
  progress: 0,
  status: '',
  showActivate: false
});

export default function programReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_PROGRAM_CLICK': {
      return state.get('configurationId') === action.payload.id &&
        (state.get('programFailed') || state.get('programDone'))
        ? INITIAL_STATE
        : state;
    }
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE':
    case 'LOAD_CONFIGURATION_DONE': {
      const differentOperation =
        (state.get('configurationId') &&
          state.get('configurationId') !== action.payload.id) ||
        (state.get('programFailed') &&
          (!state.get('requestStarted') ||
            state.get('programFailed') < state.get('requestStarted'))) ||
        (state.get('programDone') &&
          (!state.get('requestStarted') ||
            state.get('programDone') < state.get('requestStarted')));
      let latest = (state.get('requestStarted') && !differentOperation
        ? state
        : INITIAL_STATE
      )
        .set('loadDone', Date.now())
        .set('configurationId', action.payload.id);
      const loadRequestRace =
        latest.get('requestStarted') &&
        latest.get('loadDone') > latest.get('requestStarted') &&
        (!latest.get('requestDone') ||
          latest.get('loadDone') <= latest.get('requestDone')) &&
        (!latest.get('programFailed') ||
          latest.get('loadDone') <= latest.get('programFailed'));
      latest = (action.payload.status === 'programming'
        ? latest.set('status', 'started')
        : latest
      ).set('loadRequestRace', loadRequestRace);
      return latest;
    }
    case 'LOAD_CONFIGURATION_STARTED': {
      return state.set('loadRequestRace', false);
    }
    case 'CONFIGURATION_PROGRAM_REQUEST_DONE': {
      return state.set('status', 'started').set('requestDone', Date.now());
    }
    case 'CONFIGURATION_PROGRAM_HIDE_PROGRESS': {
      return state.set('showProgress', false);
    }
    case 'CONFIGURATION_VALIDATION_CODE_CHANGE': {
      return state.set('validationCode', action.payload);
    }
    case 'CONFIGURATION_PROGRAM_REQUEST_STARTED': {
      return INITIAL_STATE.set('requestStarted', Date.now())
        .set('showProgress', true)
        .set('progress', 0)
        .set('errors', fromJS({}));
    }
    case 'CONFIGURATION_PROGRAM_POLL_STARTED': {
      return state.set('showProgress', true);
    }
    case 'CONFIGURATION_PROGRAM_POLL': {
      if (action.payload.status === 'failed')
        state = state.set('programFailed', Date.now());
      if (action.payload.status === 'done')
        state = state.set('programDone', Date.now());
      return state
        .set('status', action.payload.status)
        .set('progress', action.payload.progress);
    }
    case 'CONFIGURATION_PROGRAM_FORM_ERRORS': {
      return state.set('errors', fromJS(action.payload));
    }
    case 'CONFIGURATION_PROGRAM_FAILED': {
      return state
        .set('programFailed', Date.now())
        .set('showProgress', false)
        .set('showActivate', action.payload.showActivate)
        .setIn(['errors', 'general'], fromJS(action.payload.msg));
    }
    case 'ACTIVATE_THING_DONE': {
      return state.set('showActivate', false).set('errors', fromJS({}));
    }
    default: {
      return state;
    }
  }
}
