/** @format */

import React, { lazy } from 'react';
import { Route } from 'react-router';
import urls from 'utils/urls';
const ListContainer = lazy(() =>
  import('components/views/EagleEye/List/EagleEyeListContainer')
);
const EndCustomerContainer = lazy(() =>
  import('components/views/EagleEye/EndCustomer/EndCustomerContainer')
);
const SecurityRouterEndCustomerContainer = lazy(() =>
  import(
    'components/views/EagleEye/EndCustomer/SecurityRouter/EndCustomerContainer'
  )
);
const EventLogListContainer = lazy(() =>
  import('components/views/EagleEye/EventLog/List')
);

export default function ConfigurationRoutes(withSession) {
  return [
    <Route
      exact
      key={urls.eagleEyeList}
      path={urls.eagleEyeList}
      component={withSession(ListContainer)}
    />,
    <Route
      exact
      key={urls.eagleEyeEndCustomer}
      path={urls.eagleEyeEndCustomer}
      component={withSession(EndCustomerContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterEagleEyeEndCustomer}
      path={urls.securityRouterEagleEyeEndCustomer}
      component={withSession(SecurityRouterEndCustomerContainer)}
    />,
    <Route
      exact
      key={urls.eagleEyeEventLog}
      path={urls.eagleEyeEventLog}
      component={withSession(EventLogListContainer)}
    />
  ];
}
