/** @format */

export default function validateInput({ input, productFeatures }) {
  const flickerSettings = [
    'flickerCounter',
    'flickerDetectTime',
    'flickerModeTime'
  ];
  return {
    type: 'object',
    keys: {
      position: {
        type: 'number',
        parse: true,
        condition: (position) => Number(position),
        errorCode: 'validation.input.position'
      },
      label: {
        type: 'string',
        allowNull: productFeatures.includes('noInputLabel'),
        errorCode: 'validation.required.label'
      },
      inputType: {
        type: 'string',
        errorCode: 'validation.type.notselected'
      },
      delay: {
        type: 'number',
        condition: (delay) => delay <= 32000 && delay >= 0,
        errorCode: 'validation.input.delay.invalid'
      },
      ...flickerSettings.reduce(
        (acc, settingsKey) => ({
          ...acc,
          [settingsKey]: {
            type: 'number',
            condition: (setting) => setting > 0,
            allowNull: flickerSettings.every((setting) => !input[setting]),
            errorCode: 'validation.flicker.detection.invalid'
          }
        }),
        {}
      )
    }
  };
}
