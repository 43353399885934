/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showProgress: false,
  errors: {},
  loading: false,
  tacCode: '',
  progress: 0,
  status: ''
});

export default function activateSubscriptionReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SUBSCRIPTION_ACTIVATE_CLICK': {
      return state.get('configurationId') === action.payload.id &&
        (state.get('activateFailed') || state.get('activateDone'))
        ? INITIAL_STATE
        : state;
    }
    case 'LOAD_SUBSCRIPTION_SUMMARY_DONE': {
      const differentOperation =
        (state.get('configurationId') &&
          state.get('configurationId') !== action.payload.id) ||
        (state.get('activateFailed') &&
          (!state.get('requestStarted') ||
            state.get('activateFailed') < state.get('requestStarted'))) ||
        (state.get('activateDone') &&
          (!state.get('requestStarted') ||
            state.get('activateDone') < state.get('requestStarted')));
      let latest = (
        state.get('requestStarted') && !differentOperation
          ? state
          : INITIAL_STATE
      )
        .set('loadDone', Date.now())
        .set('configurationId', action.payload.id);
      const loadRequestRace =
        latest.get('requestStarted') &&
        latest.get('loadDone') > latest.get('requestStarted') &&
        (!latest.get('requestDone') ||
          latest.get('loadDone') <= latest.get('requestDone')) &&
        (!latest.get('activateFailed') ||
          latest.get('loadDone') <= latest.get('activateFailed'));
      latest = (
        action.payload.status === 'activatingSubscription'
          ? latest.set('status', 'started')
          : latest
      ).set('loadRequestRace', loadRequestRace);
      return latest;
    }
    case 'LOAD_SUBSCRIPTION_SUMMARY_STARTED': {
      return state.set('loadRequestRace', false);
    }
    case 'SUBSCRIPTION_ACTIVATE_HIDE_PROGRESS': {
      return state.set('showProgress', false);
    }
    case 'SUBSCRIPTION_TAC_CODE_CHANGE': {
      return state.set('tacCode', action.payload);
    }
    case 'SUBSCRIPTION_ACTIVATE_REQUEST_STARTED': {
      return INITIAL_STATE.set('requestStarted', Date.now())
        .set('progress', 0)
        .set('loading', true)
        .set('errors', fromJS({}))
        .set('tacCode', state.get('tacCode'));
    }
    case 'SUBSCRIPTION_ACTIVATE_REQUEST_DONE': {
      return state
        .set('status', 'done')
        .set('showProgress', true)
        .set('loading', false)
        .set('requestDone', Date.now());
    }
    case 'SUBSCRIPTION_ACTIVATE_POLL_STARTED': {
      return state.set('showProgress', true);
    }
    case 'SUBSCRIPTION_ACTIVATE_FORM_ERRORS': {
      return state.set('errors', fromJS(action.payload));
    }
    case 'SUBSCRIPTION_ACTIVATE_FAILED': {
      return state
        .set('activationFailed', Date.now())
        .set('showProgress', false)
        .set('loading', false)
        .setIn(['errors', 'general'], fromJS(action.payload.msg))
        .setIn(['errors', 'errorText'], fromJS(action.payload.errorText));
    }
    case 'SUBSCRIPTION_ACTIVATE_RESET_STATE': {
      if (
        state.get('status') === 'done' ||
        state.getIn(['errors', 'general']) ||
        state.getIn(['errors', 'errorText'])
      )
        return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
