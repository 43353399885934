/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  userId: null,
  isShowing: false,
  isSending: false
});

export default function activateUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ACTIVATE_USER_MODAL_SHOW': {
      return state.set('isShowing', true).set('userId', action.payload);
    }
    case 'ACTIVATE_USER_MODAL_HIDE': {
      return state.set('isShowing', false);
    }
    case 'ADMIN_ACTIVATE_USER_START': {
      return state.set('isSending', true);
    }
    case 'ADMIN_ACTIVATE_USER_DONE': {
      return state.set('isSending', false);
    }
    case 'ADMIN_ACTIVATE_USER_FAILED': {
      return state.set('isSending', false);
    }
    default: {
      return state;
    }
  }
}
