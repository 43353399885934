/** @format */

export default function validateCamera(camera) {
  return {
    type: 'object',
    keys: {
      ipAddress: {
        type: 'string',
        allowNull: !camera.url,
        errorCode: 'validation.ipAddress.notselected'
      },
      url: {
        type: 'string',
        allowNull: !camera.ipAddress,
        errorCode: 'validation.url.not.entered'
      }
    }
  };
}
