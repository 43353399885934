/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  content: {}
});

export default function dalmStatus(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_DALM_STATUS_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_DALM_STATUS_DONE': {
      return state.set('content', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_DALM_STATUS_FAILED': {
      return state.set('error', action.payload.msg).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
