/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  id: null,
  isShowing: false
});

export default function removeWanMonitoringItemReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_REMOVE_WAN_MONITORING_ITEM_MODAL': {
      return INITIAL_STATE.set('isShowing', true).set(
        'id',
        action.payload.wanMonitoringId
      );
    }
    case 'HIDE_REMOVE_WAN_MONITORING_ITEM_MODAL': {
      return INITIAL_STATE;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_WAN_MONITOR_ITEM': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
