/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    userId: '',
    validUntil: '',
    organizationFriendlyId: '',
    rights: 'read'
  },
  loadingUsers: false,
  isShowing: false,
  isCreating: false,
  created: false,
  type: 'user',
  productFamily: 'dalm',
  error: Map()
});

export default function shareConfigurationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_SHARE_CONFIGURATION_MODAL': {
      return INITIAL_STATE.set('isShowing', true)
        .set('productFamily', action.payload.productFamily)
        .setIn(['shareConfigurationModal', 'form', 'id'], action.payload.id)
        .set('type', action.payload.type);
    }
    case 'HIDE_SHARE_CONFIGURATION_MODAL': {
      return state.set('isShowing', false);
    }
    case 'LOAD_ORGANIZATION_USERS_STARTED': {
      return state.set('loadingUsers', true);
    }
    case 'LOAD_ORGANIZATION_USERS_DONE': {
      return state.set('loadingUsers', false);
    }
    case 'LOAD_ORGANIZATION_USERS_FAILED': {
      return state.set('loadingUsers', false);
    }
    case 'SHARE_CONFIGURATION_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'CONFIGURATION_SHARE_START': {
      return state.set('isCreating', true).set('error', Map());
    }
    case 'CONFIGURATION_SHARE_DONE': {
      return state.set('created', true);
    }
    case 'CONFIGURATION_SHARE_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isCreating', false)
        .set('created', false)
        .set('error', fromJS(error));
    }
    case 'USER_CREATE_MODAL_DONE': {
      return state.setIn(['form', 'userId'], action.payload);
    }
    default: {
      return state;
    }
  }
}
