/** @format */

import { Map, fromJS } from 'immutable';
import moment from 'moment';

const INITIAL_STATE = fromJS({
  current: {},
  users: [],
  searchQuery: '',
  sortedBy: 'upgradeStatus',
  sortOrder: 'descending',
  organizations: [],
  organization: {
    name: '',
    friendlyId: ''
  },
  organizationForm: {
    navCustomerPrefix: 'AddSecure AB'
  },
  isFetching: false,
  formErrors: {},
  isSending: false,
  pagination: {
    users: {
      total: 0,
      page: 0,
      start: 0
    },
    organizations: {
      total: 0,
      page: 0,
      start: 0
    }
  },
  uploadedSpecification: {},
  error: '',
  msg: '',
  filterCountry: '',
  countries: []
});

const sessions = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'ORGANIZATION_REQUEST_UPGRADE_START': {
      return state.set('isSending', true);
    }
    case 'ORGANIZATION_REQUEST_UPGRADE_FAILED': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_REQUEST_UPGRADE_DONE': {
      return state
        .set('isSending', false)
        .setIn(['current', 'upgradeStatus'], 'requested');
    }
    case 'ORGANIZATION_REQUEST_DOWNGRADE_START': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_REQUEST_DOWNGRADE_FAILED': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_REQUEST_DOWNGRADE_DONE': {
      return state
        .set('isSending', false)
        .set('current', state.get('current').delete('upgradeStatus'))
        .setIn(['current', 'accountType'], 'base');
    }
    case 'ORGANIZATION_CANCEL_UPGRADE_START': {
      return state.set('isSending', true);
    }
    case 'ORGANIZATION_CANCEL_UPGRADE_FAILED': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_CANCEL_UPGRADE_DONE': {
      return state
        .set('isSending', false)
        .set('current', state.get('current').delete('upgradeStatus'));
    }
    case 'ORGANIZATION_ACCEPT_UPGRADE_START': {
      return state.set('isSending', true);
    }
    case 'ORGANIZATION_ACCEPT_UPGRADE_FAILED': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_ACCEPT_UPGRADE_DONE': {
      return state
        .set('isSending', false)
        .setIn(['current', 'upgradeStatus'], 'accepted')
        .setIn(['current', 'accountType'], 'pro');
    }
    case 'ORGANIZATION_REJECT_UPGRADE_START': {
      return state.set('isSending', true);
    }
    case 'ORGANIZATION_REJECT_UPGRADE_FAILED': {
      return state.set('isSending', false);
    }
    case 'ORGANIZATION_REJECT_UPGRADE_DONE': {
      return state
        .set('isSending', false)
        .setIn(['current', 'upgradeStatus'], 'rejected');
    }
    case 'ORGANIZATION_DOWNGRADE_OPEN': {
      return state.set('showDowngradeModal', true);
    }
    case 'ORGANIZATION_DOWNGRADE_CLOSE': {
      return state
        .set('showDowngradeModal', false)
        .set('showConfirmationDialog', false);
    }
    case 'ORGANIZATION_DOWNGRADE_WARNING_SUBMIT': {
      return state.set('showConfirmationDialog', true);
    }
    case 'ORGANIZATION_ADMIN_SORT': {
      return state
        .set('sortedBy', action.payload.column)
        .set('sortOrder', action.payload.order);
    }
    case 'USER_DELETE_DONE': {
      const deletedId = action.payload.id;
      return state
        .set(
          'users',
          state.get('users').filter((user) => user.get('id') !== deletedId)
        )
        .setIn(
          ['pagination', 'users', 'total'],
          state.getIn(['pagination', 'users', 'total']) - 1
        );
    }
    case 'ORGANIZATION_LIST_SEARCH_INPUT_CHANGE': {
      return state.set('searchQuery', action.payload);
    }
    case 'LOAD_ORGANIZATION_USERS_STARTED': {
      return state.set('isFetching', true);
    }
    case 'LOAD_ORGANIZATION_USERS_DONE': {
      let users = fromJS(action.payload.users);
      const organization = action.payload.organization;
      users = action.payload.append ? state.get('users').push(...users) : users;
      return state
        .set('users', users)
        .set('organization', fromJS(organization))
        .set('isFetching', false)
        .setIn(['pagination', 'users', 'total'], action.payload.total)
        .setIn(['pagination', 'users', 'page'], action.payload.page)
        .setIn(['pagination', 'users', 'start'], action.payload.start);
    }
    case 'LOAD_ORGANIZATION_USERS_FAILED': {
      return state.set('error', action.payload);
    }
    case 'FILTER_ORGANIZATIONS': {
      return state.set('filterCountry', action.payload);
    }
    case 'LOAD_ORGANIZATIONS_STARTED': {
      return state.set('isFetching', true);
    }
    case 'LOAD_ORGANIZATIONS_DONE': {
      const organizations = action.payload.organizations;
      return state
        .set('organizations', fromJS(organizations))
        .set('isFetching', false)
        .setIn(['pagination', 'organizations', 'total'], action.payload.total)
        .setIn(['pagination', 'organizations', 'page'], action.payload.page)
        .setIn(['pagination', 'organizations', 'start'], action.payload.start);
    }
    case 'LOAD_ORGANIZATIONS_FAILED': {
      return state.set('error', action.payload);
    }
    case 'LOAD_ORGANIZATION_STARTED': {
      return state.set('isFetching', true);
    }
    case 'LOAD_ORGANIZATION_DONE': {
      const organization = fromJS(action.payload.organization);
      return state
        .set('isFetching', false)
        .set('organizationForm', organization)
        .set('current', organization);
    }
    case 'LOAD_ORGANIZATION_FAILED': {
      return state
        .set('isFetching', false)
        .set('fetchError', action.payload.msg);
    }
    case 'ORGANIZATION_FORM_CHANGED': {
      const name = action.payload.name;
      const value = action.payload.value || null;
      if (name === 'twoFactorAuthenticationOn') {
        state = state.setIn(['organizationForm', '2FA'], !value ? null : 'sms');
      } else if (name === 'suspend') {
        state = state.setIn(
          ['organizationForm', 'suspendedAt'],
          !value ? null : moment().utc().format('YYYY-MM-DD HH:mm:ss')
        );
      }
      return state.setIn(['organizationForm', name], value).set('msg', '');
    }
    case 'ORGANIZATION_UPDATE_START': {
      return state
        .set('isSending', true)
        .set('error', '')
        .set('msg', '')
        .set('formErrors', Map());
    }
    case 'ORGANIZATION_UPDATE_DONE': {
      return state
        .set('isSending', false)
        .set('current', state.get('organizationForm'))
        .set('msg', 'edit.organization.done');
    }
    case 'ORGANIZATION_UPDATE_FAILED': {
      const error = action.payload;
      return state.set('isSending', false).set('error', error);
    }
    case 'ORGANIZATION_FORM_ERRORS': {
      const errors = action.payload;
      return state.set('formErrors', errors);
    }
    case 'ORGANIZATION_REMOVE_LOGO_START': {
      return state.setIn(['organizationForm', 'logo'], '');
    }
    case 'CLEAR_SEARCH': {
      return state.set('searchQuery', '');
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_CHANGE': {
      return state.set('uploadedSpecification', action.payload);
    }
    case 'SEQUENCE_CHANNEL_SPECIFICATION_UPLOAD_DONE': {
      return state
        .setIn(
          ['organizationForm', 'seqChSpecificationName'],
          action.payload.seqChSpecificationName
        )
        .setIn(
          ['organizationForm', 'seqChSpecificationUpdatedAt'],
          action.payload.seqChSpecificationUpdatedAt
        );
    }
    case 'RESET_ORGANIZATION_STATE':
      return INITIAL_STATE.set('filterCountry', state.get('filterCountry'));
    case 'SESSION_LOGOUT_DONE':
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

export default sessions;
