/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadDalmStatuses({ configurationId, limit }) {
  return async (dispatch) => {
    if (configurationId === null || !isFinite(configurationId)) {
      return dispatch({
        type: 'LOAD_DALM_STATUSES_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }
    configurationId = parseInt(configurationId, 10);

    dispatch({
      type: 'LOAD_DALM_STATUSES_STARTED',
      payload: configurationId
    });
    const queryParams = limit && limit < Infinity ? `?limit=${limit}` : '';
    const requestUrl = `${config.apiServer}/configurations/${configurationId}/dalm-status${queryParams}`;
    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'LOAD_DALM_STATUSES_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_DALM_STATUSES_FAILED',
        payload: response.body
      });
    }
  };
}
