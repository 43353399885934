/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import urls from 'utils/urls';
import dalmConfigurationEditValidation from './validation/dalm';
import { formatAlarmCenterForSave } from 'actions/configuration/utils';
import history from 'utils/history';

export default function save() {
  return async (dispatch, getState) => {
    dispatch({ type: 'SAVE_CONFIGURATION_STARTED' });
    const state = getState();
    const configuration = state.configuration
      .getIn(['current', 'configuration'])
      .toJS();
    const country = state.global.get('country');
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );
    if (
      (configuration.product.includes('000as') ||
        configuration.oem === 'aras') &&
      (configuration.remoteUnits[0].label ||
        configuration.remoteUnits[0].address)
    ) {
      configuration.remoteUnits[0].type = 'remote.unit.nox';
      configuration.remoteUnits[0].port = 0;
    } else if (
      configuration.product.includes('000as') ||
      configuration.oem === 'aras'
    ) {
      configuration.remoteUnits[0].type = '';
      configuration.remoteUnits[0].port = '';
    }
    const errors = dalmConfigurationEditValidation({
      configuration,
      editConfigurationFeatures,
      country
    });

    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated

    if (Object.keys(errors).length !== 0) {
      errors.generalError = 'configuration.error.top';
      return dispatch({ type: 'SAVE_CONFIGURATION_FAILED', payload: errors });
    }
    configuration.alarmCenters = configuration.alarmCenters
      .filter(Boolean)
      .map((arc) =>
        formatAlarmCenterForSave(
          arc,
          editConfigurationFeatures.includes('showAlarmCenterIps')
        )
      );
    if (configuration.bacnet) {
      if (configuration.bacnet.fsInstance) {
        const fsInstances = configuration.bacnet.fsInstance
          .split(/(?:[,\s]+)/)
          .filter((i) => Boolean(i));
        configuration.bacnet.fsInstance = fsInstances.join(',');
      }
      if (configuration.bacnet.operationMessages) {
        Object.keys(configuration.bacnet.operationMessages).forEach((key) => {
          if (configuration.bacnet.operationMessages[key] === '')
            delete configuration.bacnet.operationMessages[key];
        });
      }
      if (configuration.bacnet.alarmInfo) {
        Object.keys(configuration.bacnet.alarmInfo).forEach((key) => {
          if (configuration.bacnet.alarmInfo[key] === '')
            delete configuration.bacnet.alarmInfo[key];
        });
      }
    }
    let response;
    try {
      response = await request.patch(
        `${config.apiServer}/configurations/${configuration.id}`,
        { body: configuration }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'SAVE_CONFIGURATION_FAILED',
        payload: errorResponse.body.msg || {
          generalError: 'configuration.server.error.top'
        }
      });
    }

    dispatch({ type: 'SAVE_CONFIGURATION_DONE', payload: response.body });
    history.push(urls.configurationDetails.replace(':id', configuration.id));
  };
}
