/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';

export default function getUnactiveSubscriptions({ offset = 0, append } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const limit = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'pageLimit'
    ]);
    const query = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'searchQuery'
    ]);
    offset =
      offset ||
      state.configuration.getIn(['listUnactiveSubscriptions', 'offset']);
    const sortOrder = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'sortOrder'
    ]);
    const orderBy = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'sortedBy'
    ]);

    dispatch({
      type: 'UNACTIVE_SUBSCRIPTIONS_LOAD_START',
      payload: { append }
    });
    let response;
    try {
      response = await request.get(
        `${config.apiServer}/subscriptions/unactive/list`,
        {
          query: {
            limit,
            offset,
            query,
            orderBy,
            ascending: sortOrder === 'ascending'
          }
        }
      );

      const stateAfterRequest = getState().configuration.get(
        'listUnactiveSubscriptions'
      );
      const hasChangedPage = !stateAfterRequest.get('loading');
      const hasChangedQuery = stateAfterRequest.get('searchQuery') !== query;
      if (hasChangedPage || hasChangedQuery) {
        return;
      }

      if (typeof response !== 'object') {
        const error = new Error('badResponseType');
        error.body = {
          msg: 'errors.badResponseType'
        };
        throw error;
      }
    } catch (errorResponse) {
      return dispatch({
        type: 'UNACTIVE_SUBSCRIPTIONS_LOAD_FAILED',
        payload: errorResponse.body
      });
    }

    offset = Math.min(offset, parseInt(response.body.total, 10));
    const page = Math.floor(offset / limit) + 1;

    dispatch({
      type: 'UNACTIVE_SUBSCRIPTIONS_LOAD_DONE',
      payload: {
        total: response.body.total,
        subscriptions: response.body.subscriptions,
        offset,
        page,
        append
      }
    });
  };
}
