/** @format */

import { fromJS } from 'immutable';

import list from './list';
import current from './current';

const INITIAL_STATE = fromJS({
  list: list(undefined, { type: '' }),
  current: current(undefined, { type: '' })
});

export default function reducer(state = INITIAL_STATE, action) {
  state = state.set('list', list(state.get('list'), action));
  state = state.set('current', current(state.get('current'), action));
  return state;
}
