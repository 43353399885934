/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS([]);

export default function ipProtocolsDc09sReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value } = action.payload;
      if (index !== undefined && type === 'ipProtocolsDc09') {
        if (name === 'function' && value === 'nox') {
          return state.set(
            index,
            fromJS(
              getDefaultNoxIpProtocolsDc09s(
                state.getIn([index, 'position']),
                state.get(index)
              )
            )
          );
        } else if (name === 'function') {
          return state.set(
            index,
            fromJS(
              getDefaultIpProtocolsDc09s(
                state.getIn([index, 'position']),
                state.get(index)
              )
            )
          );
        }
        return state.setIn([index, name], value);
      } else if (name === 'product' || name === 'oem') {
        return INITIAL_STATE;
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_IP_PROTOCOLS_DC09': {
      const ipProtocolsDc09s = state.toJS();

      ipProtocolsDc09s.push(
        getDefaultIpProtocolsDc09s(ipProtocolsDc09s.length + 1, fromJS({}))
      );
      return fromJS(ipProtocolsDc09s);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_IP_PROTOCOLS_DC09': {
      let ipProtocolsDc09s = state.delete(action.payload.index);
      for (let i = 0; i < ipProtocolsDc09s.size; i++) {
        ipProtocolsDc09s = ipProtocolsDc09s.setIn([i, 'position'], i + 1);
      }
      return ipProtocolsDc09s;
    }
    default: {
      return state;
    }
  }
}

function getDefaultIpProtocolsDc09s(position, oldProtocol) {
  return {
    lanPosition: oldProtocol.get('lanPosition') || null,
    position: position,
    port: 0,
    networkProtocol: '',
    enableTimeValidation: true,
    supervision: true,
    supervisionInterval: 0,
    encryptionKey: '',
    function: '',
    label: oldProtocol.get('label') || ''
  };
}

function getDefaultNoxIpProtocolsDc09s(position, oldProtocol) {
  return {
    lanPosition: oldProtocol.get('lanPosition') || null,
    position: position,
    port: 18500,
    networkProtocol: 'tcp',
    enableTimeValidation: false,
    supervision: true,
    supervisionInterval: 90,
    encryptionKey: '0123456789ABCDEF0123456789ABCDEF',
    function: 'nox',
    label: oldProtocol.get('label') || ''
  };
}
