/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  current: {},
  searchQuery: '',
  simCards: [],
  showSimCards: 'all',
  showAutoExtendDialog: false,
  showTerminated: false,
  isFetching: true,
  isSending: false,
  sortedBy: 'name',
  sortOrder: 'ascending',
  pagination: {
    simCards: {
      total: 0,
      page: 0,
      start: 0
    }
  },
  uploadedFile: {},
  error: ''
});

const sessions = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'SIMCARD_LIST_SEARCH_INPUT_CHANGE': {
      return state.set('searchQuery', action.payload);
    }
    case 'LOAD_SIMCARDS_STARTED': {
      return state.set('isFetching', true);
    }
    case 'LOAD_SIMCARDS_DONE': {
      let simCards = fromJS(action.payload.simCards);
      simCards = action.payload.append
        ? state.get('simCards').push(...simCards)
        : simCards;
      return state
        .set('simCards', simCards)
        .set('showTerminated', action.payload.showTerminated)
        .set('isFetching', false)
        .setIn(['pagination', 'simCards', 'total'], action.payload.total)
        .setIn(['pagination', 'simCards', 'page'], action.payload.page)
        .setIn(['pagination', 'simCards', 'start'], action.payload.start);
    }
    case 'LOAD_SIMCARDS_FAILED': {
      return state.set('error', action.payload).set('isFetching', false);
    }
    case 'AUTOEXTEND_CHANGE_STARTED': {
      return state.set('isSending', true);
    }
    case 'AUTOEXTEND_CHANGE_DONE': {
      const findSimCardIndex = state.get('simCards').findIndex((sim) => {
        return sim.get('id') === action.payload.id;
      });

      const updateSimCard = (simCard) => {
        return simCard.set(
          'requestedTerminationDate',
          action.payload.requestedTerminationDate
        );
      };

      const updatedSimCards = state
        .get('simCards')
        .update(findSimCardIndex, updateSimCard);
      return state.set('simCards', updatedSimCards).set('isSending', false);
    }
    case 'AUTOEXTEND_CHANGE_FAILED': {
      return state.set('error', action.payload).set('isSending', false);
    }
    case 'LOAD_SIMCARD_STARTED': {
      return state.set('isFetching', true);
    }
    case 'LOAD_SIMCARD_DONE': {
      const simCard = action.payload.simCard;
      return state
        .set('isFetching', false)
        .set('showTerminated', action.payload.showTerminated)
        .set('current', fromJS(simCard));
    }
    case 'LOAD_SIMCARD_FAILED': {
      return state.set('error', action.payload).set('isFetching', false);
    }
    case 'SIMCARD_LIST_SORT': {
      const column = action.payload.column;
      const order = action.payload.order;
      return state.set('sortedBy', column).set('sortOrder', order);
    }
    case 'SIMCARD_UPLOAD_FILE_CHANGE': {
      return state.set('uploadedFile', action.payload);
    }
    case 'SIMCARD_UPLOAD_FILE_DONE': {
      return state.set('uploadedFile', {});
    }
    case 'SIMCARD_DROP_DOWN_CHANGE': {
      return state.set('showSimCards', action.payload);
    }
    case 'EDIT_INVOICE_REFERENCE_DONE': {
      const simCards = state.get('simCards').toJS();
      const index = simCards.findIndex(
        (sim) => sim.configurationId === action.payload.configurationId
      );
      if (index !== -1) {
        return state.setIn(
          ['simCards', index, 'invoiceReference'],
          action.payload.invoiceReference
        );
      }
      return state;
    }
    case 'SIMCARD_RESET_STATE':
    case 'SESSION_LOGOUT_DONE':
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

export default sessions;
