/** @format */

export default function validateErrorInput(editConfigurationFeatures) {
  return {
    type: 'object',
    keys: editConfigurationFeatures.includes('inputsExtendedSettings')
      ? {
          eventCode: {
            type: 'string',
            condition: (eventCode) => eventCode.length <= 25,
            errorCode: 'validation.input.eventCode.not.1to25'
          },
          sectionNumber: {
            type: 'string',
            condition: (sectionNumber) => sectionNumber.length <= 5,
            errorCode: 'validation.input.sectionNumber.not.0to5'
          },
          sectionText: {
            type: 'string',
            allowNull: true,
            condition: (sectionText) => sectionText.length <= 40,
            errorCode: 'validation.input.sectionText.not.0to40'
          },
          delay: {
            type: 'number',
            parse: true,
            condition: (delay) => delay <= 32000,
            errorCode: 'validation.input.delay.invalid'
          }
        }
      : {}
  };
}
