/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  eagleEye: true,
  eagleEyeNotifications: false,
  eagleEyeClientApp: false,
  eagleEyeEventLog: false,
  eagleEyeRemoteControl: false,
  eagleEyeStatusCheck: false
});

export default function eagleEyeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'eagleEye') {
        if (
          action.payload.name === 'eagleEyeClientApp' &&
          !action.payload.value
        ) {
          return state
            .set('eagleEyeClientApp', false)
            .set('eagleEyeRemoteControl', false)
            .set('eagleEyeEventLog', false);
        }
        return state.set(action.payload.name, action.payload.value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
