/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export function poll() {
  return async (dispatch) => {
    const requestUrl = `${config.apiServer}/maintenance-texts`;
    dispatch({ type: 'MAINTENANCE_TEXTS_POLL_START' });
    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'MAINTENANCE_TEXTS_POLL_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'MAINTENANCE_TEXTS_POLL_ERROR',
        payload: (response.body && response.body.msg) || 'errors.maintenance'
      });
    }
  };
}

export default { poll };
