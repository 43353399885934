/** @format */

export default function validateVoice() {
  return {
    type: 'object',
    keys: {
      event: {
        type: 'string',
        allowNull: false,
        errorCode: 'validation.required.voice.event'
      },
      section: {
        type: 'string',
        allowNull: true,
        errorCode: 'validation.voice.section'
      },
      message: {
        type: 'string',
        allowNull: false,
        condition: (m) => m.length > 0 && m.length < 101,
        errorCode: 'validation.voice.message'
      }
    }
  };
}
