/** @format */

import { fromJS } from 'immutable';

const INITIAL_FREE_POSITIONS = ['1', '2', '3', '4', '5', '6', '7', '8'];
const INITIAL_STATE = fromJS(getInitialState());

export default function inputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, params, value } = action.payload;
      if (index !== undefined && type === 'inputs') {
        if (name === 'flickerDetectionCheckbox') {
          if (value) {
            state = state
              .setIn(
                ['data', index, 'flickerCounter'],
                params.get('flickerCounter')
              )
              .setIn(
                ['data', index, 'flickerDetectTime'],
                params.get('flickerDetectTime')
              )
              .setIn(
                ['data', index, 'flickerModeTime'],
                params.get('flickerModeTime')
              );
          } else {
            state = state
              .setIn(['data', index, 'flickerCounter'], null)
              .setIn(['data', index, 'flickerDetectTime'], null)
              .setIn(['data', index, 'flickerModeTime'], null);
          }
        }
        if (name === 'sequenceChannel') {
          const position = state.getIn(['data', index, 'position']);
          const currentEventCode = state.getIn(['data', index, 'eventCode']);
          const currentSequenceChannel = state.getIn([
            index,
            'sequenceChannel'
          ]);
          const { eventCode: defaultEventCode } = getExtendedSettingsDc09(
            position,
            currentSequenceChannel
          );
          if (currentEventCode === defaultEventCode) {
            const { eventCode: newEventCode } = getExtendedSettingsDc09(
              position,
              value
            );
            state = state.setIn(['data', index, 'eventCode'], newEventCode);
          }
        }
        if (name === 'position') {
          state = state.setIn(['data', index, name], value);
          state = state.setIn(
            ['data', index, 'eventCode'],
            value ? value.padStart(2, '0') : '00'
          );
          const freePositions = state
            .get('INITIAL_FREE_POSITIONS')
            .filter(
              (position) =>
                !state
                  .get('data')
                  .find((input) => input.get('position') + '' === position)
            );
          return state.set('freePositions', fromJS(freePositions));
        }
        return state.setIn(['data', index, name], value);
      } else if (action.payload.name === 'product') {
        const data = state
          .get('data')
          .filter((input) => parseInt(input.get('position'), 10) < 9);
        state = state.set('data', data);
      } else if (action.payload.name === 'expansionBoard') {
        return updateInitialPositionForExtensionBoard(
          action.payload.value,
          state
        );
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_INPUT': {
      const inputs = state.get('data').toJS();
      inputs.push({
        ...getInitialInput(),
        ...getExtendedSettingsV4(),
        v4: true
      });
      return state.set('data', fromJS(inputs));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_INPUT': {
      const inputs = state.get('data').delete(action.payload.index);
      const freePositions = state
        .get('INITIAL_FREE_POSITIONS')
        .filter(
          (position) =>
            !inputs.find((input) => input.get('position') + '' === position)
        );
      return state
        .set('data', inputs)
        .set('freePositions', fromJS(freePositions));
    }
    case 'EAGLE_EYE_REMOTE_CONTROL_UPDATE': {
      const inputs = state.get('data').map((oldInput) => {
        const updatedInput = action.payload.inputs.find(
          (input) => input.position === oldInput.get('position')
        );
        return updatedInput ? fromJS(updatedInput) : oldInput;
      });
      return state.set('data', inputs);
    }
    default: {
      return state;
    }
  }
}

function getExtendedSettingsV4() {
  return {
    eventCode: '00',
    sectionText: 'Section 1',
    sectionNumber: '01'
  };
}

function getExtendedSettingsDc09(position, sequenceChannel = '') {
  return {
    eventCode: `UA/UR${sequenceChannel && `;${sequenceChannel}`}`,
    sectionText: `Section ${position}`,
    sectionNumber: `000${position}`
  };
}

function getInitialInput() {
  return {
    text: '',
    position: null,
    label: '',
    flickerCounter: null,
    flickerDetectTime: null,
    flickerModeTime: null,
    inputType: null,
    sequenceChannel: '',
    delay: 0,
    delayType: 'P',
    twentyFourHourZone: false,
    sendReset: true,
    resetDefinition: 'N',
    showExtendedBody: false,
    reservedFields: []
  };
}
function getInitialState() {
  const INITIAL_INPUTS_JSON = {
    maxInputs: 8,
    freePositions: INITIAL_FREE_POSITIONS,
    data: [
      {
        ...getInitialInput(),
        ...getExtendedSettingsV4(),
        v4: true
      }
    ],
    INITIAL_FREE_POSITIONS
  };
  return INITIAL_INPUTS_JSON;
}

function updateInitialPositionForExtensionBoard(value, state) {
  if (value !== 'none') {
    if (state.get('INITIAL_FREE_POSITIONS').length < 9) {
      state = state.set(
        'INITIAL_FREE_POSITIONS',
        state
          .get('INITIAL_FREE_POSITIONS')
          .push('9', '10', '11', '12', '13', '14', '15', '16')
      );
    }
    const freePositions = state
      .get('INITIAL_FREE_POSITIONS')
      .filter(
        (position) =>
          !state
            .get('data')
            .find((input) => input.get('position') + '' === position)
      );
    return state
      .set('freePositions', fromJS(freePositions))
      .set('maxInputs', 16);
  } else if (state.get('INITIAL_FREE_POSITIONS').length === 16) {
    const inputs = state
      .get('data')
      .filter((input) => parseInt(input.get('position'), 10) < 9);
    state = state.set(
      'INITIAL_FREE_POSITIONS',
      state.get('INITIAL_FREE_POSITIONS').splice(8, 8)
    );
    const freePositions = state
      .get('INITIAL_FREE_POSITIONS')
      .filter(
        (position) =>
          !inputs.find((input) => input.get('position') + '' === position)
      );
    return state
      .set('freePositions', fromJS(freePositions))
      .set('data', inputs)
      .set('maxInputs', 8);
  }
  return state;
}
