/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  id: undefined,
  service: undefined
});

export default function deleteUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_PROGRAMMING_MODAL': {
      return state
        .set('isShowing', true)
        .set('id', action.payload.id)
        .set('service', action.payload.service);
    }
    case 'HIDE_PROGRAMMING_MODAL': {
      return state.set('isShowing', false).set('id', undefined);
    }
    default: {
      return state;
    }
  }
}
