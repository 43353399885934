/** @format */

import { connect } from 'react-redux';

import languageActions from 'actions/language';
import maintenanceTextsActions from 'actions/maintenanceTexts';
import LanguageBar from './LanguageBar';

const mapStateToProps = (state) => {
  return {
    chosenLanguage: state.global.get('language'),
    maintenanceText: state.global.get('maintenanceText')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: function (language) {
      dispatch(languageActions.changeLanguage(language));
    },
    pollMaintenanceText: () => dispatch(maintenanceTextsActions.poll())
  };
};

const LanguageBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageBar);

export default LanguageBarContainer;
