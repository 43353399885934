/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  create: false,
  voucherErrors: null
});

export default function billingPlanReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'HIDE_BILLING_PERIOD_MODAL': {
      return state.set('show', false);
    }
    case 'SHOW_BILLING_PERIOD_MODAL': {
      return state.set('show', true).set('create', action.payload.create);
    }
    case 'VALIDATE_VOUCHER_STARTED': {
      return state.set('voucherErrors', null);
    }
    case 'VALIDATE_VOUCHER_FAILED': {
      return state.set('voucherErrors', fromJS(action.payload));
    }
    default: {
      return state;
    }
  }
}
