/** @format */

import React from 'react';
import { translate } from 'utils/language';

const ErrorView = ({ error }) => {
  const headerKey = 'error.page.header';
  const translatedHeader = translate(headerKey);
  const bodyKey = 'error.page.body1';
  const translatedBody = translate(bodyKey);

  return (
    <div className='view error-view'>
      <h1>
        {translatedHeader === headerKey
          ? 'An unexpected error occured'
          : translatedHeader}
      </h1>
      <p>
        {translatedBody === bodyKey
          ? 'Server is currently down. Please try again later.'
          : translatedBody}
      </p>
      {process.env.NODE_ENV !== 'production' && <div>{error}</div>}
    </div>
  );
};

export default ErrorView;
