/** @format */

import { fromJS } from 'immutable';

const INITIAL_SERIAL_PORT = fromJS({
  name: '',
  supervision: false,
  delay: 30,
  serialError: false,
  eventCode: '',
  lanPosition: null,
  remotePort: 10000
});

const INITIAL_FREE_POSITIONS = ['COM1', 'COM2', 'COM3 (5V)', 'RS485'];

const INITIAL_STATE = fromJS({
  data: [],
  freePositions: INITIAL_FREE_POSITIONS
});

export default function serialPortReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'serialPorts') {
        const { name, index, value } = action.payload;
        if (name === 'position') {
          state = state.setIn(['data', index], INITIAL_SERIAL_PORT);
          state = state.setIn(['data', index, name], value);
          const freePositions = INITIAL_FREE_POSITIONS.filter(
            (position) =>
              !state
                .get('data')
                .find((serialPort) => serialPort.get('position') === position)
          );
          return state.set('freePositions', fromJS(freePositions));
        } else if (name.includes('type')) {
          state = state.setIn(['data', index, 'name'], value.get('name'));
          return state
            .setIn(['data', index, 'name'], value.get('name'))
            .setIn(['data', index, 'eventCode'], value.get('eventCode'))
            .setIn(['data', index, 'serialError'], value.get('serialError'))
            .setIn(['data', index, 'lanConnection'], value.get('lanConnection'))
            .setIn(['data', index, 'lanPosition'], null)
            .setIn(['data', index, 'remotePort'], 10000);
        }
        return state.setIn(['data', index, name], value);
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_SERIAL_PORT': {
      const serialPorts = state.get('data').toJS();
      serialPorts.push(INITIAL_SERIAL_PORT);
      return state.set('data', fromJS(serialPorts));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_SERIAL_PORT': {
      const serialPorts = state.get('data').delete(action.payload.index);
      const freePositions = INITIAL_FREE_POSITIONS.filter(
        (position) =>
          !serialPorts.find(
            (serialPort) => serialPort.get('position') === position
          )
      );
      return state
        .set('data', serialPorts)
        .set('freePositions', fromJS(freePositions));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_LAN': {
      const removingPosition = action.payload.index + 1;
      let serialPorts = state.get('data');
      serialPorts.forEach((serialPort, i) => {
        if (serialPort.get('lanPosition') !== null) {
          if (serialPort.get('lanPosition') === removingPosition) {
            serialPorts = serialPorts.setIn([i, 'lanPosition'], null);
          } else if (serialPort.get('lanPosition') > removingPosition) {
            const newLanPosition = serialPort.get('lanPosition') - 1;
            serialPorts = serialPorts.setIn([i, 'lanPosition'], newLanPosition);
          }
        }
      });
      return state.set('data', serialPorts);
    }
    default: {
      return state;
    }
  }
}
