/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  documents: [],
  total: 0,
  offset: 0,
  loading: true,
  query: '',
  error: '',
  orderBy: 'createdAt',
  ascending: false,
  upload: {
    showDialog: false,
    isUploading: false,
    uploadError: '',
    uploaded: false,
    progress: 0,
    errors: {
      pdf: [],
      name: [],
      version: []
    },
    form: {
      pdf: null,
      name: '',
      version: ''
    }
  },
  delete: {
    showDialog: false,
    isDeleting: false,
    error: '',
    deleted: false,
    id: ''
  }
});

export default function documentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DOCUMENTS_LIST_LOAD':
      const documents = action.payload.append
        ? state.get('documents').concat(fromJS(action.payload.documents))
        : fromJS(action.payload.documents);
      return state
        .set('error', '')
        .set('loading', false)
        .set('documents', documents)
        .set(
          'offset',
          action.payload.append ? documents.size : state.get('offset')
        )
        .set('total', action.payload.total);
    case 'DOCUMENTS_LIST_OFFSET':
      return state
        .set('offset', action.payload)
        .set('loading', action.payload !== state.get('offset'));
    case 'DOCUMENTS_LIST_QUERY':
      return state
        .set('offset', 0)
        .set('query', action.payload)
        .set('loading', true);
    case 'DOCUMENTS_LIST_ORDER':
      return state
        .set('orderBy', action.payload)
        .set(
          'ascending',
          state.get('orderBy') === action.payload && !state.get('ascending')
        )
        .set(
          'offset',
          state.get('orderBy') === action.payload ? state.get('offset') : 0
        )
        .set('loading', true);
    case 'DOCUMENTS_LIST_LOAD_MORE':
      return state.set('loading', true).set('error', '');
    case 'DOCUMENTS_LIST_ERROR':
      return state.set('loading', false).set('error', action.payload);
    case 'DOCUMENT_DELETE_START':
      return state
        .setIn(['delete', 'isDeleting'], true)
        .setIn(['delete', 'error'], '');
    case 'DOCUMENT_DELETE_ERROR':
      return state
        .setIn(['delete', 'isDeleting'], false)
        .setIn(['delete', 'error'], action.payload);
    case 'DOCUMENT_DELETE_DONE':
      return state
        .setIn(['delete', 'isDeleting'], false)
        .setIn(['delete', 'error'], '')
        .setIn(['delete', 'deleted'], true);
    case 'OPEN_DOCUMENT_DELETE_DIALOG':
      return state
        .setIn(['delete', 'showDialog'], true)
        .setIn(['delete', 'id'], action.payload);
    case 'CLOSE_DOCUMENT_DELETE_DIALOG':
      return state.set('delete', INITIAL_STATE.get('delete'));
    case 'OPEN_DOCUMENT_DIALOG':
      return state.setIn(['upload', 'showDialog'], true);
    case 'CLOSE_DOCUMENT_DIALOG':
      return state.set('upload', INITIAL_STATE.get('upload'));
    case 'DOCUMENT_UPLOAD_FORM_ERROR':
      return state.setIn(['upload', 'errors'], fromJS(action.payload.errors));
    case 'DOCUMENT_UPLOAD_START': {
      return state
        .setIn(['upload', 'isUploading'], true)
        .setIn(['upload', 'errors'], INITIAL_STATE.getIn(['upload', 'errors']))
        .setIn(['upload', 'uploaded'], false)
        .setIn(['upload', 'progress'], 0);
    }
    case 'DOCUMENT_UPLOAD_DONE': {
      return state
        .setIn(['upload', 'isUploading'], false)
        .setIn(['upload', 'uploaded'], true)
        .setIn(
          ['upload', 'form'],
          fromJS({
            pdf: null,
            name: '',
            version: ''
          })
        );
    }
    case 'DOCUMENT_UPLOAD_PROGRESS': {
      const progress =
        parseFloat(
          parseFloat(
            action.payload.progress.loaded / action.payload.progress.total
          ).toFixed(3)
        ) * 100;
      return state.setIn(['upload', 'progress'], progress);
    }
    case 'DOCUMENT_UPLOAD_ERROR': {
      return state
        .setIn(['upload', 'isUploading'], false)
        .setIn(['upload', 'uploadError'], action.payload);
    }
    case 'DOCUMENT_UPLOAD_FORM_CHANGE': {
      return state.setIn(
        ['upload', 'form', fromJS(action.payload.name)],
        fromJS(action.payload.value)
      );
    }
    default:
      return state;
  }
}
