/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  msg: '',
  error: '',
  services: {}
});

export default function additionalServices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADDITIONAL_SERVICES_RESET_STATE':
      return INITIAL_STATE;
    case 'LOAD_ADDITIONAL_SERVICES_START': {
      return INITIAL_STATE.set('loading', true);
    }
    case 'LOAD_ADDITIONAL_SERVICES_DONE': {
      return state
        .set('services', fromJS(action.payload.services))
        .set('error', '')
        .set('loading', false);
    }
    case 'LOAD_ADDITIONAL_SERVICES_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    case 'ADDITIONAL_SERVICES_UPDATE_STARTED': {
      return state.set('msg', '').set('error', '');
    }
    case 'ADDITIONAL_SERVICES_UPDATE_DONE': {
      return state
        .set('services', fromJS(action.payload))
        .set('msg', 'service.saved');
    }
    case 'ADDITIONAL_SERVICES_UPDATE_FAILED': {
      return state.set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
