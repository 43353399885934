/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  featureKey: {
    key: ['', '', '', ''],
    feature: '',
    id: null
  },
  error: '',
  loading: false,
  done: false,
  timeUntilNextTry: 0
});

export default function featureKeyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'HIDE_FEATURE_KEY_MODAL': {
      return INITIAL_STATE;
    }
    case 'SHOW_FEATURE_KEY_MODAL': {
      return state.set('show', true);
    }
    case 'CHANGE_FEATURE_KEY': {
      return state.setIn(
        ['featureKey', 'key', action.payload.index],
        action.payload.value
      );
    }
    case 'FEATURE_KEY_GET_STARTED': {
      return state.set(
        'featureKey',
        fromJS({
          key: action.payload.key,
          feature: ''
        })
      );
    }
    case 'FEATURE_KEY_GET_DONE': {
      return state
        .set('featureKey', fromJS(action.payload.featureKey))
        .set('error', '')
        .set('timeUntilNextTry', 0);
    }
    case 'FEATURE_KEY_GET_ERROR': {
      return state
        .set('error', action.payload.error)
        .set('timeUntilNextTry', action.payload.timeUntilNextTry);
    }
    case 'CLEAR_FEATURE_KEY': {
      return state.set(
        'featureKey',
        fromJS({
          key: ['', '', '', ''],
          feature: '',
          id: null
        })
      );
    }
    case 'FEATURE_KEY_ACTIVATE_START': {
      return state.set('error', '').set('loading', true);
    }
    case 'FEATURE_KEY_ACTIVATE_ERROR': {
      return state.set('error', action.payload.error).set('loading', false);
    }
    case 'FEATURE_KEY_ACTIVATE_DONE': {
      return state
        .set('error', '')
        .set('loading', false)
        .set('featureKey', fromJS(action.payload.featureKey))
        .set('done', true);
    }
    default: {
      return state;
    }
  }
}
