/** @format */

import util from 'utils/util';

export default function rememberLogin(accountCredentials) {
  if (util.isIOSApp()) {
    rememberLoginIOS(accountCredentials);
  } else if (util.isAndroidApp()) {
    rememberLoginAndroid(accountCredentials);
  }
}

function rememberLoginIOS(accountCredentials) {
  if (!window.webkit) {
    console.error('No webkit for iOS');
    return;
  }

  window.webkit.messageHandlers.callback.postMessage(
    JSON.stringify({
      action: 'rememberLogin',
      username: accountCredentials.username,
      password: accountCredentials.password
    })
  );
}

function rememberLoginAndroid(accountCredentials) {
  if (!window.webAppInterface) {
    console.error('No web app interface for Android');
    return;
  }

  window.webAppInterface.postMessage(
    JSON.stringify({
      action: 'rememberLogin',
      username: accountCredentials.username,
      password: accountCredentials.password
    })
  );
}
