/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());
const TRIGGER_LENGTH = 16;

function getInitialState() {
  return {
    lanPosition: 0 /*
    lanPort: nnnnn,
    deviceInstance: 11002,
    deviceName: 'TUS0001',
    fsInstance: 11101,
    bbmdIp: '192.168.1.1',
    bbmdPort: 47818,
    bbmdSubnet: '255.255.255.0',
    bbmdGateway: '192.168.1.1', */,
    operationMessages: {
      /* 
      section: 254,
      sequenceChannel: 800,
      limit: 100 */
    },
    alarmInfo: {
      /*
      trigger: "0000000000000001",
      sequenceChannel: 900,
      inputRely: false */
    }
  };
}

export default function bacnetReducer(state = INITIAL_STATE, action) {
  let newState = state;
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload &&
        action.payload.type &&
        action.payload.type.startsWith('bacnet')
      ) {
        const path = action.payload.type
          .substr(6) // "bacnet".length
          .split('.')
          .filter((s) => s);
        const name = action.payload.name;
        const value = action.payload.value;
        if (path.includes('alarmInfo') && name === 'trigger') {
          let val = '';
          if (/1/.test(value)) {
            const initial = new Array(TRIGGER_LENGTH).fill(0);
            for (let i = 0; i < value.length; i++) {
              initial[i] = value.charAt(i);
            }
            val = initial.join('');
          }
          newState = state.setIn([...path, name], val);
        } else {
          newState = state.setIn([...path, name], value);
        }
      }
      break;
    }
    default:
      break;
  }
  return newState;
}
