/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import urls from 'utils/urls';
import securityRouterConfigurationEditValidation from './validation/securityRouter';
import configurationLoadActions from '../load';
import { formatAlarmCenterForSave } from 'actions/configuration/utils';
import history from 'utils/history';

export default function saveSecurityRouter(serviceName) {
  return async (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['currentSecurityRouter', 'configuration'])
      .toJS();

    if (!(configuration.mobileData.data === 'privateSim')) {
      const response = await dispatch(
        configurationLoadActions.loadDataPlans(
          configuration.id,
          configuration.country,
          configuration.oem,
          configuration.featureKeys,
          configuration.hardwareVersion
        )
      );
      const availablePlans = response && response.payload;
      const dataPlan =
        availablePlans &&
        availablePlans.find(
          (plan) => plan.bundle === configuration.mobileData.data
        );
      if (!dataPlan) {
        configuration.mobileData.data = '0MB';
      }
    }
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );

    const country = state.global.get('country');
    const products = state.global.get('securityRouterProducts');
    const errors = securityRouterConfigurationEditValidation({
      configuration,
      serviceName,
      country,
      products,
      editConfigurationFeatures
    });
    dispatch({ type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_STARTED' });
    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (Object.keys(errors).length) {
      errors.generalError = 'configuration.error.top';
      return dispatch({
        type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: errors
      });
    }
    configuration.alarmCenters = configuration.alarmCenters
      .filter(Boolean)
      .map((arc) => formatAlarmCenterForSave(arc, false));

    let response;
    try {
      response = await request.post(
        `${config.apiServer}/configurations/security-router/${configuration.id}`,
        { body: { configuration, serviceName } }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: {
          generalError: 'configuration.server.error.top',
          ...(errorResponse.body && errorResponse.body.msg)
        }
      });
    }
    dispatch({
      type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_DONE',
      payload: response.body
    });
    history.push(
      urls.securityRouterConfigurationDetails.replace(':id', configuration.id)
    );
    dispatch(
      configurationLoadActions.loadSecurityRouterConfiguration(configuration.id)
    );
    dispatch(
      configurationLoadActions.loadSecurityRouterServices(configuration.id)
    );
    if (response.body.noInvoiceInformation) {
      dispatch({ type: 'WARNING_NO_INVOICE_INFORMATION_MODAL_SHOW' });
    }
  };
}
