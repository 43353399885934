/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadPollingIntervals(product) {
  return async (dispatch) => {
    dispatch({ type: 'LOAD_POLLING_INTERVALS_STARTED' });
    try {
      const requestUrl = `${config.apiServer}/configurations/polling-intervals`;
      const response = await request.get(requestUrl, { query: { product } });
      dispatch({
        type: 'LOAD_POLLING_INTERVALS_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_POLLING_INTERVALS_FAILED',
        payload: response.body
      });
    }
  };
}
