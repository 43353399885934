/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_NO_RECEIVERS_WARNING_MODAL': {
      return state
        .set('show', true)
        .set('configuration', action.payload.configuration)
        .set('inputs', action.payload.inputs)
        .set('create', action.payload.create);
    }
    case 'HIDE_NO_RECEIVERS_WARNING_MODAL': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
