/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function errorInputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index: selectedIndex, name, type, value } = action.payload;
      if (selectedIndex !== undefined && type === 'errorInputs') {
        return state.setIn([selectedIndex, name], value);
      } else if (name === 'product') {
        const index = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.ethernet'
        );
        if (index !== -1) {
          state = state.setIn([index, 'disabled'], value === 'nena');
        }
        const index2 = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.exp'
        );
        if (index2 !== -1) {
          state = state.setIn([index2, 'disabled'], value === 'nena');
        }
        const index3 = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.battery'
        );
        if (index3 !== -1) {
          state = state.setIn([index3, 'disabled'], value === 'nena');
        }
        const index4 = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.sabotage'
        );
        if (index4 !== -1) {
          state = state.setIn([index4, 'disabled'], value === 'nena');
        }
      } else if (type === 'ipv4') {
        const index = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.ethernet'
        );
        if (index !== -1 && name !== 'respondToWanPing') {
          return state.setIn([index, 'disabled'], value.includes('disabled'));
        }
      } else if (name === 'expansionBoard') {
        const index = state.findIndex(
          (errorInput) =>
            errorInput.get('errorInputType') === 'errorInputType.exp'
        );
        if (index !== -1) {
          return state.setIn([index, 'disabled'], value === 'none');
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_ERROR_INPUTS_JSON = [];
  return INITIAL_ERROR_INPUTS_JSON;
}
