/** @format */

export default function validateRelay(relay) {
  if (!!relay.outputFunction) {
    return {
      type: 'object'
    };
  }
  return {
    type: 'object',
    keys: {
      position: {
        type: 'number',
        parse: true,
        condition: (position) => Number(position),
        errorCode: 'validation.input.position'
      },
      label: {
        type: 'string',
        errorCode: 'validation.required.label'
      },
      optionalFunctionality: {
        type: 'string',
        errorCode: 'validation.optional.functionality.notselected'
      }
    }
  };
}
