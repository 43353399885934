/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  error: '',
  expires: null,
  warningTimeout: null,
  extensions: 0,
  extensionLimit: null,
  isConfirmed: false,
  done: true,
  status: 'inactive',
  showModal: false,
  testModeError: ''
});

export default function testModeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_CONFIGURATION_STARTED': {
      return state;
    }
    case 'LOAD_CONFIGURATION_DONE': {
      return action.payload.status === 'testMode'
        ? state.set('status', 'active')
        : state;
    }
    case 'TEST_MODE_STARTED': {
      return state.set('done', false).set('error', '');
    }
    case 'TEST_MODE_POLL_DONE': {
      if (
        action.payload.status !== 'pending' &&
        state.get('showModal') &&
        !action.payload.error
      ) {
        state = state.set('showModal', false);
      }
      return state
        .set('error', '')
        .set('testModeError', action.payload.error)
        .set('status', action.payload.status)
        .set('expires', action.payload.expires)
        .set('warningTimeout', action.payload.warningTimeout)
        .set('extensions', action.payload.extensions)
        .set('extensionLimit', action.payload.extensionLimit)
        .set('isConfirmed', action.payload.isConfirmed)
        .set('done', action.payload.status === 'inactive');
    }
    case 'TEST_MODE_DONE': {
      return state
        .set('status', 'pending')
        .set('expires', null)
        .set('showModal', true)
        .set('testModeError', '');
    }
    case 'TEST_MODE_MODAL_HIDE': {
      return state.set('showModal', false);
    }
    case 'TEST_MODE_FAILED':
    case 'TEST_MODE_POLL_FAILED': {
      return INITIAL_STATE.set('error', action.payload);
    }
    case 'TEST_MODE_TIME_OUT': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
