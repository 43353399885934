/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false
});

export default function warningBeforeAlarmTestReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'WARNING_BEFORE_ALARM_TEST_MODAL_SHOW': {
      return state
        .set('show', true)
        .set('configurationId', action.payload.configurationId)
        .set('inputs', fromJS(action.payload.inputs))
        .set('errorInputs', fromJS(action.payload.errorInputs))
        .set('outputs', fromJS(action.payload.outputs))
        .set('alarmCenterTests', fromJS(action.payload.alarmCenterTests))
        .set('vds', fromJS(action.payload.vds))
        .set('vdsOutputs', fromJS(action.payload.vdsOutputs));
    }
    case 'WARNING_BEFORE_ALARM_TEST_MODAL_HIDE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
