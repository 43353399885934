/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';

export default function editAdditionalInformation() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_ADDITIONAL_INFORMATION_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const form = state.modals.getIn(['editAdditionalInformation', 'form']);

    try {
      await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveAdditionalInformation`,
        {
          body: {
            form,
            subscriptionId
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_ADDITIONAL_INFORMATION_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );

    dispatch({
      type: 'EDIT_ADDITIONAL_INFORMATION_DONE'
    });
  };
}
