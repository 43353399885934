/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  events: [],
  hasPolled: false,
  primaryTotal: 0,
  secondaryTotal: 0,
  primaryOffset: 0,
  secondaryOffset: 0,
  sortedBy: 'time',
  sortOrder: 'descending',
  searchQuery: '',
  selected: null
});

export default function eventLog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EAGLE_EYE_EVENT_LOG_INPUT_CHANGE': {
      return state
        .set(action.payload.name, action.payload.value)
        .set('hasPolled', false)
        .set('primaryTotal', 0)
        .set('secondaryTotal', 0)
        .set('primaryOffset', 0)
        .set('secondaryOffset', 0);
    }
    case 'EAGLE_EYE_EVENT_LOG_SORT': {
      return state
        .set('hasPolled', false)
        .set('primaryTotal', 0)
        .set('secondaryTotal', 0)
        .set('primaryOffset', 0)
        .set('secondaryOffset', 0)
        .set('sortedBy', action.payload.column)
        .set('sortOrder', action.payload.order);
    }
    case 'EAGLE_EYE_EVENT_LOG_SELECT': {
      return state
        .set('selected', action.payload)
        .set('hasPolled', false)
        .set('primaryTotal', 0)
        .set('secondaryTotal', 0)
        .set('primaryOffset', 0)
        .set('secondaryOffset', 0);
    }
    case 'EAGLE_EYE_EVENT_LOG_DESELECT': {
      return state
        .set('selected', null)
        .set('hasPolled', false)
        .set('primaryTotal', 0)
        .set('secondaryTotal', 0)
        .set('primaryOffset', 0)
        .set('secondaryOffset', 0);
    }
    case 'LOAD_EAGLE_EYE_EVENT_LOG_STARTED': {
      return state.set('loading', true);
    }
    case 'EAGLE_EYE_EVENT_LOG_RESET_STATE':
      return INITIAL_STATE;
    case 'LOAD_EAGLE_EYE_EVENT_LOG_DONE': {
      const {
        events,
        append,
        extend,
        primaryTotal,
        secondaryTotal,
        primaryOffset,
        secondaryOffset
      } = action.payload;

      if (append && state.get('sortOrder') === 'descending') {
        state = state
          .set(
            'events',
            events.length
              ? state.get('events').unshift(...fromJS(events).reverse())
              : state.get('events')
          )
          .set(
            'primaryOffset',
            state.get('primaryOffset') +
              (primaryTotal - state.get('primaryTotal'))
          )
          .set(
            'secondaryOffset',
            state.get('secondaryOffset') +
              (secondaryTotal - state.get('secondaryTotal'))
          );
      } else if (!append) {
        state = state
          .set(
            'events',
            extend ? state.get('events').concat(fromJS(events)) : fromJS(events)
          )
          .set('primaryOffset', primaryOffset)
          .set('secondaryOffset', secondaryOffset);
      }

      return state
        .set('error', '')
        .set('loading', false)
        .set('hasPolled', true)
        .set('primaryTotal', primaryTotal)
        .set('secondaryTotal', secondaryTotal);
    }
    case 'LOAD_EAGLE_EYE_EVENT_LOG_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
