/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isDeleting: false,
  deleted: false,
  id: undefined,
  name: '',
  error: Map(),
  securityRouter: false,
  isDaas: false
});

export default function deleteConfigurationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_CONFIGURATION_DELETION_MODAL': {
      return state
        .set('isShowing', true)
        .set('isDeleting', false)
        .set('name', action.payload.name)
        .set('id', action.payload.id)
        .set('securityRouter', action.payload.securityRouter)
        .set('isDaas', action.payload.isDaas);
    }
    case 'HIDE_CONFIGURATION_DELETION_MODAL': {
      return INITIAL_STATE;
    }
    case 'DELETE_CONFIGURATION_START': {
      return state.set('isDeleting', true).set('error', Map());
    }
    case 'DELETE_CONFIGURATION_DONE': {
      return state.set('deleted', true);
    }
    case 'DELETE_CONFIGURATION_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isDeleting', false)
        .set('deleted', false)
        .set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
