/** @format */

import util from 'utils/util';

export default function forgetLogin() {
  if (util.isIOSApp()) {
    forgetLoginIOS();
  } else if (util.isAndroidApp()) {
    forgetLoginAndroid();
  }
}

function forgetLoginIOS() {
  if (!window.webkit) {
    console.error('No webkit for iOS');
    return;
  }

  window.webkit.messageHandlers.callback.postMessage(
    JSON.stringify({
      action: 'forgetLogin'
    })
  );
}

function forgetLoginAndroid() {
  if (!window.webAppInterface) {
    console.error('No web app interface for Android');
    return;
  }

  window.webAppInterface.postMessage(
    JSON.stringify({
      action: 'forgetLogin'
    })
  );
}
