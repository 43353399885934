/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  selectedService: {}
});

export default function securityRouterServices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_SECURITY_ROUTER_SERVICES_MODAL': {
      return state
        .set(action.payload.modal, true)
        .set('loading', false)
        .set('selectedService', action.payload.service);
    }
    case 'HIDE_SECURITY_ROUTER_SERVICES_MODAL': {
      return INITIAL_STATE;
    }
    case 'SAVE_SECURITY_ROUTER_CONFIGURATION_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
