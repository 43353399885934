/** @format */

import request from 'actions/request';

export default function getSamlLink() {
  return request({
    action: 'GET_SAML_LINK',
    path: '/sessions/saml/getLink'
  });
}
