/** @format */

import util from 'utils/util';

export default function getLogin() {
  if (util.isIOSApp()) {
    return getLoginIOS();
  } else if (util.isAndroidApp()) {
    return getLoginAndroid();
  }
}

function getLoginIOS() {
  return new Promise((resolve) => {
    window.addEventListener('nativeEWMMessage', messageReceiver);
    if (!window.webkit) {
      resolve(null);
    }

    window.webkit.messageHandlers.callback.postMessage(
      JSON.stringify({
        action: 'getLogin'
      })
    );

    const timer = setTimeout(() => {
      resolve(null);
    }, 1000);

    function messageReceiver(event) {
      if (event.action !== 'getLogin') {
        return;
      }
      clearTimeout(timer);
      window.removeEventListener('nativeEWMMessage', messageReceiver);
      resolve(event.payload);
    }
  });
}

function getLoginAndroid() {
  return new Promise((resolve) => {
    window.addEventListener('nativeEWMMessage', messageReceiver);
    if (!window.webAppInterface) {
      resolve(null);
    }

    window.webAppInterface.postMessage(
      JSON.stringify({
        action: 'getLogin'
      })
    );

    const timer = setTimeout(() => {
      window.removeEventListener('nativeEWMMessage', messageReceiver);
      resolve(null);
    }, 1000);

    function messageReceiver(event) {
      if (event.action !== 'getLogin') {
        return;
      }

      clearTimeout(timer);
      window.removeEventListener('nativeEWMMessage', messageReceiver);
      resolve(event.payload);
      //resolve({ username: 'invalid', password: 'invalid' });
    }
  });
}
