/** @format */

import history from 'utils/history';
import urls from 'utils/urls';
import request from '@edgeguideab/client-request';
import config from 'config.json';

export default function handleActiveTerminal(tacCode) {
  return async () => {
    let response;
    try {
      response = await request.get(
        `${config.apiServer}/configurations/tacCode/${tacCode}`
      );
      const { configurationId } = response.body || null;
      if (!configurationId) return;
      history.push(
        urls.subscriptionConfigurationDetails.replace(':id', configurationId)
      );
    } catch (error) {
      return;
    }
  };
}
