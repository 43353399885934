/** @format */

import React from 'react';
import GlobalModal from 'components/global/Modal';
import Dialog from 'components/global/Dialog';
import { translate } from 'utils/language';

class Modal extends React.Component {
  render() {
    const { show, loading, changeModalState, versionDetails } = this.props;
    const { version, releaseDate } = JSON.parse(versionDetails);

    return (
      <GlobalModal show={show}>
        <Dialog
          title={translate('main.about')}
          confirmText={translate('ok')}
          abortText={translate('ok')}
          onAbortClick={changeModalState}
          hideAbort={loading}
          hideConfirm={true}
        >
          <p>
            Version is {version}, release date {releaseDate}
          </p>
        </Dialog>
      </GlobalModal>
    );
  }
}
export default Modal;
