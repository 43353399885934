/** @format */

import { phoneCondition } from 'utils/helpers';

const approvedCountryCodes = [
  '+46',
  '+45',
  '+47',
  '+358',
  '+31',
  '+32',
  '+33',
  '+49',
  '+41',
  '+43',
  '+39',
  '+352',
  '+423',
  '+34',
  '+351'
];
export default function validateReceiver(receiver) {
  return {
    type: 'object',
    keys: {
      name: {
        type: 'string',
        errorCode: 'validation.required.name'
      },
      address:
        receiver.type === 'email'
          ? {
              type: 'string',
              condition: (email) =>
                /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                  email
                ),
              errorCode: 'validation.email.valid'
            }
          : receiver.type === 'push'
          ? {
              type: 'string',
              errorCode: 'validation.device.id',
              condition: (push) => /^[A-Fa-f0-9-]*$/.test(push)
            }
          : {
              type: 'string',
              errorCode: 'validation.phone.notphonenumber',
              condition: (address) =>
                phoneCondition(address) &&
                (approvedCountryCodes.includes(address.substring(0, 3)) ||
                  approvedCountryCodes.includes(address.substring(0, 4)))
            },
      voicePin: {
        type: 'number',
        allowNull: receiver.type !== 'voice',
        condition: (pin) => pin > 999,
        errorCode:
          'validation.required.eagleeye.alarm.forward.receiver.voice.pin'
      }
    }
  };
}
