/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadEwmLog({
  configurationId,
  limit,
  offset = 0,
  query = ''
}) {
  return async (dispatch) => {
    const id = configurationId;
    if (id === null || !isFinite(id)) {
      return dispatch({
        type: 'LOAD_EVENT_LOG_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    dispatch({ type: 'LOAD_EVENT_LOG_STARTED', payload: id });
    const queryParams =
      `?offset=${offset}&query=${query}` +
      (limit && limit < Infinity ? `&limit=${limit}` : ``);
    const requestUrl = `${config.apiServer}/configurations/${id}/log${queryParams}`;
    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'LOAD_EVENT_LOG_DONE',
        payload: { ...response.body, offset }
      });
    } catch (response) {
      dispatch({ type: 'LOAD_EVENT_LOG_FAILED', payload: response.body });
    }
  };
}
