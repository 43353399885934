/** @format */

import { fromJS } from 'immutable';
import config from 'config';
import { List } from 'immutable';

let defaultLanguage = config.defaultLanguage;
try {
  defaultLanguage = localStorage.getItem('language') || defaultLanguage;
} catch (e) {
  // Ignore that we don't have localStorage
}
const INITIAL_STATE = fromJS({
  language: defaultLanguage,
  supportedEeprom: {},
  ewmFeatures: [],
  vpnSettings: {},
  editConfigurationFeatures: [],
  editConfigurationFeatureFields: [],
  flickerDetectionDefaults: {},
  country: {},
  appVersion: '{}',
  countries: [],
  remoteUnitTypes: [],
  showAbout: false,
  navigation: {
    showHamburgerMenu: false,
    expandedItems: []
  },
  dialerProtocolTypes: [],
  predefinedAlarmCenters: [],
  predefinedProviders: [],
  products: {},
  securityRouterProducts: {},
  userRoles: [],
  loadedConfig: false,
  priceMap: {}
});

const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_EWM_FEATURES': {
      const ewmFeatures = action.payload;
      return state.set('ewmFeatures', fromJS(ewmFeatures));
    }
    case 'LOAD_EWM_CONFIG': {
      const { payload } = action;
      const orderedProductsMap = fromJS(payload.products).sort();
      return state
        .set('loadedConfig', true)
        .set(
          'editConfigurationFeatures',
          fromJS(payload.editConfigurationFeatures)
        )
        .set(
          'editConfigurationFeatureFields',
          fromJS(payload.editConfigurationFeatureFields)
        )
        .set('userRoles', fromJS(payload.userRoles))
        .set('country', fromJS(payload.country))
        .set('countries', fromJS(payload.countries))
        .set('remoteUnitTypes', fromJS(payload.remoteUnitTypes))
        .set('dialerProtocolTypes', fromJS(payload.dialerProtocolTypes))
        .set('predefinedAlarmCenters', fromJS(payload.predefinedAlarmCenters))
        .set('predefinedProviders', fromJS(payload.predefinedProviders))
        .set(
          'flickerDetectionDefaults',
          fromJS(payload.flickerDetectionDefaults)
        )
        .set('products', orderedProductsMap)
        .set('vpnSettings', fromJS(payload.vpnSettings))
        .set('securityRouterProducts', fromJS(payload.securityRouterProducts))
        .set('priceMap', fromJS(payload.priceMap));
    }
    case 'OPEN_HAMBURGER_MENU': {
      return state.setIn(['navigation', 'showHamburgerMenu'], true);
    }
    case 'CHANGE_ABOUT_MODAL_STATE': {
      return state.set('aboutModal', !state.get('aboutModal'));
    }
    case 'LOAD_APP_VERSION': {
      return state.set('appVersion', JSON.stringify(action.payload));
    }
    case 'CLOSE_HAMBURGER_MENU': {
      return state
        .setIn(['navigation', 'showHamburgerMenu'], false)
        .setIn(['navigation', 'expandedItems'], List());
    }
    case 'ADD_EXPANDED_ITEM':
      return state.setIn(
        ['navigation', 'expandedItems'],
        fromJS([action.payload])
      );
    case 'TOGGLE_EXPANDABLE_NAVIGATION_ITEM': {
      const path = action.payload.path;
      const expandedNavigationItems = state.getIn([
        'navigation',
        'expandedItems'
      ]);
      const alreadyExpanded = Boolean(
        expandedNavigationItems.filter(
          (expandedName) => expandedName === path.id
        ).size
      );
      return alreadyExpanded
        ? state.updateIn(['navigation', 'expandedItems'], (items) =>
            items.filter((expandedName) => expandedName !== path.id)
          )
        : state.setIn(
            ['navigation', 'expandedItems'],
            expandedNavigationItems.concat(path.id)
          );
    }
    case 'CLOSE_EXPANDED_NAVIGATION_ITEM': {
      return state.setIn(['navigation', 'expandedItems'], List());
    }
    case 'CHANGE_LANGUAGE_DONE': {
      return state.set('language', action.payload);
    }
    case 'SET_FLAG_LANGUAGE': {
      return state.set('language', action.payload);
    }
    case 'SESSION_CHECK_DONE':
    case 'SESSION_LOGIN_DONE': {
      return state.set(
        'supportedEeprom',
        fromJS(action.payload.supportedEeprom)
      );
    }
    case 'MAINTENANCE_TEXTS_POLL_DONE': {
      return state.set('maintenanceText', action.payload);
    }
    default: {
      return state;
    }
  }
};

export default globalReducer;
