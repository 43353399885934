/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  id: null,
  show: false
});

export default function forceUpgradeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_FORCE_UPGRADE_MODAL': {
      return state.set('show', true).set('id', action.payload.id);
    }
    case 'HIDE_FORCE_UPGRADE_MODAL': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
