/** @format */

import isIp from 'is-ip';
import { hasCollidingIpConfiguration } from '../utils';

export default function validateIpv4({ ipv4, lans }) {
  const colliding =
    ipv4.networkType &&
    ipv4.networkType.includes('manual') &&
    isIp(ipv4.ipAddress) &&
    hasCollidingIpConfiguration({
      ipAddress: ipv4.ipAddress,
      lan: { netmask: ipv4.netmask },
      lans,
      global: true
    });
  return {
    type: 'object',
    keys: {
      networkType: 'string',
      ipAddress: {
        type: 'string',
        allowNull: ipv4.networkType !== 'networkType.manual',
        condition: (ipAddress) => isIp.v4(ipAddress) && !colliding,
        errorCode: colliding
          ? 'validation.lan.ipAddress.colliding'
          : 'validation.ipAddress.notselected'
      },
      netmask: {
        type: 'string',
        allowNull: ipv4.networkType !== 'networkType.manual',
        allowNullErrorCode: 'validation.netmask.notselected'
      },
      wanMonitoringSettings: {
        type: 'object',
        keys: {
          testInterval: {
            type: 'number',
            parse: true,
            condition: (interval) =>
              !ipv4.wanMonitoringSettings.active ||
              (interval > 29 && interval < 301),
            allowNull: !ipv4.wanMonitoringSettings.active,
            errorCode: 'validation.wan.monitoring.test.interval'
          },
          retries: {
            type: 'number',
            parse: true,
            condition: (retries) =>
              !ipv4.wanMonitoringSettings.active || retries < 4,
            allowNull: !ipv4.wanMonitoringSettings.active,
            errorCode: 'validation.wan.monitoring.retries'
          }
        }
      }
    }
  };
}
