/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  voucher: {
    code: '',
    name: ''
  },
  error: '',
  timeUntilNextTry: 0
});

export default function voucherReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'HIDE_VOUCHER_MODAL': {
      return INITIAL_STATE;
    }
    case 'SHOW_VOUCHER_MODAL': {
      return state.set('show', true);
    }
    case 'CHANGE_VOUCHER_CODE': {
      return state.setIn(['voucher', 'code'], action.payload.value);
    }
    case 'VOUCHER_GET_STARTED': {
      return state.set(
        'voucher',
        fromJS({
          code: action.payload.code,
          name: ''
        })
      );
    }
    case 'VOUCHER_GET_DONE': {
      return state
        .set('voucher', fromJS(action.payload.voucher))
        .set('error', '')
        .set('timeUntilNextTry', 0);
    }
    case 'VOUCHER_GET_ERROR': {
      return state
        .set('error', action.payload.error)
        .set('timeUntilNextTry', action.payload.timeUntilNextTry);
    }
    default: {
      return state;
    }
  }
}
