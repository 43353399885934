/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import urls from 'utils/urls';
import configurationLoadActions from '../load';
import history from 'utils/history';
//import { translate } from 'utils/language';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

export default function saveSubscription() {
  return async (dispatch, getState) => {
    const state = getState();
    const subscription = state.configuration
      .getIn(['currentSubscription', 'subscription'])
      .toJS();

    dispatch({ type: 'SAVE_SUBSCRIPTION_CONFIGURATION_STARTED' });
    const ajv = new Ajv({
      allErrors: true,
      multipleOfPrecision: 12
    });
    addFormats(ajv);
    const validate = ajv.compile(subscription.jsonValidationSchema);
    const valid = validate(subscription.jsonConfiguration.configuration);

    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (!valid) {
      const errors = validate.errors;
      errors.generalError = 'configuration.error.top';
      return dispatch({
        type: 'SAVE_SUBSCRIPTION_CONFIGURATION_FAILED',
        payload: errors
      });
    }
    let response;
    try {
      response = await request.post(
        `${config.apiServer}/subscriptions/${subscription.id}/configuration`,
        { body: { subscription } }
      );
    } catch (errorResponse) {
      const baseDispatch = {
        type: 'SAVE_SUBSCRIPTION_CONFIGURATION_FAILED',
        payload: {
          ...errorResponse.body,
          generalError: 'configuration.server.error.top'
        }
      };
      if (errorResponse.status === 400) {
        baseDispatch.payload.generalError = 'configuration.error.top';
        baseDispatch.payload = {
          ...baseDispatch.payload,
          ...errorResponse.body.errorText
        };
      }
      return dispatch(baseDispatch);
    }
    dispatch({
      type: 'SAVE_SUBSCRIPTION_CONFIGURATION_DONE',
      payload: response.body
    });
    history.push(
      urls.subscriptionConfigurationDetails.replace(':id', subscription.id)
    );
    dispatch(configurationLoadActions.loadSubscriptionSummary(subscription.id));
  };
}
