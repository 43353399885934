/** @format */

import { phoneCondition } from 'utils/helpers';

export default function validateContactInformation(
  editConfigurationFeatures,
  invoice,
  country
) {
  return {
    type: 'object',
    keys: {
      contact: {
        type: 'object',
        allowNull: !editConfigurationFeatures.includes('contact'),
        keys: {
          email: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: true,
            errorCode: 'validation.not.valid.email'
          }
        }
      },
      installer: {
        type: 'object',
        allowNull: !editConfigurationFeatures.includes('installer'),
        keys: {
          email: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: true,
            errorCode: 'validation.not.valid.email'
          }
        }
      },
      invoice: {
        type: 'object',
        allowNull: !editConfigurationFeatures.includes('invoice'),
        keys: {
          to: {
            type: 'string',
            errorCode: 'validation.invoice.to'
          },
          organizationName: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.invoice.organizationName'
          },
          vat: {
            type: 'string',
            allowNull:
              invoice.to === 'organization' ||
              !country.get('endCustomerInvoiceVat'),
            errorCode: 'validation.invoice.vat'
          },
          address: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.address.notselected'
          },
          zipCode: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.required.invoiceZipCode'
          },
          city: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.required.invoiceCity'
          },
          additionalEmail: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.email.valid'
          },
          country: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.invoice.country'
          },
          name: {
            type: 'string',
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.required.name'
          },
          email: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: invoice.to === 'organization',
            errorCode: 'validation.email.valid'
          },
          phoneNumber: {
            type: 'string',
            condition: phoneCondition,
            allowNull:
              invoice.to === 'organization' || !!invoice.mobilePhoneNumber,
            errorCode: 'validation.phone.notphonenumber'
          },
          mobilePhoneNumber: {
            type: 'string',
            condition: phoneCondition,
            allowNull: invoice.to === 'organization' || !!invoice.phoneNumber,
            errorCode: 'validation.phone.notphonenumber'
          }
        }
      }
    }
  };
}
