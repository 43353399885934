/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  loading: false,
  error: null,
  list: []
});

export default function arpTableReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'OPEN_ARP_TABLE_MODAL': {
      return state.set('show', true).set('loading', true);
    }
    case 'HIDE_ARP_TABLE_MODAL': {
      return INITIAL_STATE;
    }
    case 'FETCH_ARP_TABLE_POLL_DONE': {
      if (action.payload.status === 'done') {
        return state
          .set('loading', false)
          .set('list', fromJS(action.payload.list));
      }
      return state;
    }
    case 'FETCH_ARP_TABLE_FAILED':
    case 'FETCH_ARP_TABLE_POLL_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
