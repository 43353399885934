/** @format */

import { fromJS } from 'immutable';

const defaultState = fromJS({
  isShowing: false,
  fileName: '',
  userId: null,
  version: ''
});

export default function deleteUserReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SHOW_POLICY_MODAL': {
      return state
        .set('isShowing', true)
        .set('fileName', action.payload.policy.fileName)
        .set('version', action.payload.policy.version)
        .set('userId', action.payload.userId);
    }
    case 'HIDE_POLICY_MODAL': {
      return state
        .set('isShowing', false)
        .set('fileName', '')
        .set('userId', null);
    }
    default: {
      return state;
    }
  }
}
