/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isDeleting: false,
  deleted: false,
  id: undefined,
  name: '',
  error: Map()
});

export default function deleteUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_USER_DELETION_MODAL': {
      return state
        .set('isShowing', true)
        .set('isDeleting', false)
        .set('name', action.payload.name)
        .set('id', action.payload.id);
    }
    case 'HIDE_USER_DELETION_MODAL': {
      return state.set('isShowing', false).set('id', undefined);
    }
    case 'USER_DELETE_START': {
      return state.set('isDeleting', true).set('error', Map());
    }
    case 'USER_DELETE_DONE': {
      return state.set('deleted', true).set('isDeleting', false);
    }
    case 'USER_DELETE_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isDeleting', false)
        .set('deleted', false)
        .set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
