/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';

export default function editInstallationSite() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_INSTALLATION_SITE_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const installationSite = state.modals.getIn([
      'editInstallationSite',
      'installationSite'
    ]);

    try {
      await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveInstallationSite`,
        {
          body: {
            subscriptionId,
            installationSite
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_INSTALLATION_SITE_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );

    dispatch({
      type: 'EDIT_INSTALLATION_SITE_DONE'
    });
  };
}
