/** @format */

export default function validateOutput({
  output,
  editConfigurationFeatures,
  product
}) {
  const isSet = output.outputFunction || output.outputType || output.label;
  return {
    type: 'object',
    keys: editConfigurationFeatures.includes('showOutputLabels')
      ? validateWithLabel({ output, isSet })
      : validateWithoutLabel({ output, product, isSet })
  };
}

function validateWithLabel({ output, isSet }) {
  return {
    label: {
      type: 'string',
      allowNull: !isSet,
      errorCode: 'validation.required.label'
    },
    outputFunction: {
      type: 'string',
      allowNull: !isSet,
      errorCode: 'validation.output.function.notselected'
    },
    outputType: {
      type: 'string',
      allowNull: !isSet,
      errorCode: 'validation.type.notselected'
    },
    pulseLength: {
      type: 'number',
      allowNull: output.outputFunction !== 'outputControl.function.pulse',
      condition: (pulseLength) =>
        output.outputFunction !== 'outputControl.function.pulse' ||
        pulseLength > 0,
      errorcode: 'validation.pulselength.notselected'
    },
    inputFeedback: {
      type: 'string',
      allowNull: !output.isArm,
      errorCode: 'validation.output.inputFeedback.notselected'
    }
  };
}

function validateWithoutLabel({ output, product, isSet }) {
  return {
    pulseLength: {
      type: 'number',
      allowNull: !output.pulse,
      parse: (pulseLength) => (output.pulse ? pulseLength : null),
      condition: (pulseLength) => !output.pulse || pulseLength > 0,
      errorCode: 'validation.pulselength.notselected'
    },
    optionalFunctionality: {
      type: 'string',
      allowNull: product !== 'dalmLte' || !isSet,
      errorCode: 'validation.optional.functionality.notselected'
    }
  };
}
