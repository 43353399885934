/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import urls from 'utils/urls';
import securityRouterConfigurationEditValidation from './validation/securityRouter';
import history from 'utils/history';
import securityRouterServicesActions from '../securityRouterServices';
import { formatAlarmCenterForSave } from 'actions/configuration/utils';

export default function createSecurityRouter() {
  return async (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['currentSecurityRouter', 'configuration'])
      .toJS();
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );
    const country = state.global.get('country');
    const products = state.global.get('securityRouterProducts');
    const errors = securityRouterConfigurationEditValidation({
      configuration,
      country,
      products,
      editConfigurationFeatures
    });
    dispatch({ type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_STARTED' });
    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (Object.keys(errors).length) {
      errors.generalError = 'configuration.error.top';
      return dispatch({
        type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: errors
      });
    }
    configuration.alarmCenters = configuration.alarmCenters
      .filter(Boolean)
      .map((arc) => formatAlarmCenterForSave(arc, false));
    let response;
    try {
      response = await request.post(
        `${config.apiServer}/configurations/security-router`,
        { body: configuration }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: {
          generalError: 'configuration.server.error.top',
          ...(errorResponse.body && errorResponse.body.msg)
        }
      });
    }
    dispatch({
      type: 'SAVE_SECURITY_ROUTER_CONFIGURATION_DONE',
      payload: response.body
    });
    if (
      parseInt(configuration.hardwareVersion) === 2 &&
      configuration.product !== 'rt7020'
    ) {
      await dispatch(
        securityRouterServicesActions.updateServiceValue(
          { name: 'mobile' },
          true,
          response.body.id
        )
      );
    }
    if (configuration.product === 'rt7020') {
      await dispatch(
        securityRouterServicesActions.updateServiceValue(
          { name: 'internetAccess' },
          true,
          response.body.id
        )
      );
    }
    history.push(
      urls.securityRouterConfigurationDetails.replace(':id', response.body.id)
    );
    if (response.body.noInvoiceInformation) {
      dispatch({ type: 'WARNING_NO_INVOICE_INFORMATION_MODAL_SHOW' });
    }
  };
}
