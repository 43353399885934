/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isReactivating: false,
  reactivated: false,
  id: undefined,
  name: '',
  error: null
});

export default function reactivateUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_USER_REACTIVATE_MODAL': {
      return state
        .set('isShowing', true)
        .set('isReactivating', false)
        .set('name', action.payload.name)
        .set('id', action.payload.id)
        .set('error', null)
        .set('reactivated', false);
    }
    case 'HIDE_USER_REACTIVATE_MODAL': {
      return state.set('isShowing', false).set('id', undefined);
    }
    case 'USER_REACTIVATE_START': {
      return state.set('isReactivating', true).set('error', null);
    }
    case 'USER_REACTIVATE_DONE': {
      return state.set('reactivated', true).set('isReactivating', false);
    }
    case 'USER_REACTIVATE_FAILED': {
      const error = action.payload.msg;
      return state
        .set('isReactivating', false)
        .set('reactivated', false)
        .set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
