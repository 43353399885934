/** @format */

import { fromJS, List } from 'immutable';
import config from 'config';

const INITIAL_STATE = fromJS([]);

const voicePhraseTemplate = fromJS({
  event: '',
  section: '',
  message: '',
  language: config.defaultLanguage,
  errors: {},
  type: 'serial'
});

export default function voicePhrasesReceivers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_SERVICE_TOGGLE': {
      return List();
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_PHRASE_ADD': {
      const newVoicePhrase = fromJS(action.payload) || voicePhraseTemplate;
      return state.push(newVoicePhrase);
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_PHRASE_INPUT_CHANGE': {
      const { index, name, value, params } = action.payload;
      if (index === -1) {
        if (
          params &&
          (params.type === 'input' || params.type === 'errorInput') &&
          name !== 'language'
        ) {
          const newVoicePhrase = voicePhraseTemplate
            .set('type', params.type)
            .set('section', params.section)
            .set('event', params.event)
            .set('language', params.language)
            .set(name, value);
          return state.push(newVoicePhrase);
        } else return state;
      } else if (
        params &&
        (params.type === 'input' || params.type === 'errorInput') &&
        !value
      ) {
        return state.filter((voicePhrase, i) => i !== index);
      }
      return state.update(index, (voicePhrase) =>
        voicePhrase
          .set(name, value)
          .update('errors', (errors) => errors && errors.remove(name))
      );
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_PHRASE_REMOVE': {
      return state.filter((voicePhrase, index) => index !== action.payload);
    }
    case 'SECURITY_ROUTER_EAGLE_EYE_VOICE_PHRASE_PLAY': {
      return state;
    }
    default: {
      return state;
    }
  }
}
