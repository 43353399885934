/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function editEndCustomerDetails(
  configurationId,
  invoice,
  productName
) {
  return async (dispatch) => {
    dispatch({ type: 'EDIT_END_CUSTOMER_DETAILS_STARTED' });
    try {
      await request.post(
        `${config.apiServer}/configurations/edit-end-customer-details/${configurationId}`,
        { body: { invoice } }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_END_CUSTOMER_DETAILS_FAILED',
        payload: {
          error: 'errors.server'
        }
      });
    }
    dispatch({
      type: 'EDIT_END_CUSTOMER_DETAILS_DONE'
    });
    dispatch({
      type:
        productName === 'pangea' || productName === 'nena'
          ? 'SECURITY_ROUTER_CONFIGURATION_EDIT_END_CUSTOMER_DETAILS_CHANGED'
          : 'CONFIGURATION_EDIT_END_CUSTOMER_DETAILS_CHANGED',
      payload: { invoice }
    });
  };
}
