/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  addEndUser: false,
  isShowing: false,
  isCreating: false
});

export default function addUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_ADD_USER_MODAL': {
      return state
        .set('isShowing', true)
        .set('addEndUser', action.payload.addEndUser);
    }
    case 'HIDE_ADD_USER_MODAL': {
      return state.set('isShowing', false);
    }
    case 'USER_CREATE_MODAL_START': {
      return state.set('isCreating', true);
    }
    case 'USER_CREATE_MODAL_DONE': {
      return state.set('isCreating', false).set('isShowing', false);
    }
    case 'USER_CREATE_MODAL_FAILED': {
      return state.set('isCreating', false);
    }
    default: {
      return state;
    }
  }
}
