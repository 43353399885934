/** @format */

import voucherValidation from './validation/voucher';
import createConfiguration from './create';
import saveConfiguration from './save';
import loadConfiguration from '../load/configuration';

export default function validateVoucher(voucher, create) {
  return async (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['current', 'configuration'])
      .toJS();
    const country = state.global.get('country');
    const eagleEye = state.configuration.getIn([
      'additionalServices',
      'services',
      'eagleEye',
      'enabled'
    ]);
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );
    const errors = voucherValidation({
      configuration,
      voucher: voucher.toJS(),
      country,
      eagleEye,
      editConfigurationFeatures
    });
    dispatch({ type: 'VALIDATE_VOUCHER_STARTED' });
    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (Object.keys(errors).length) {
      return dispatch({
        type: 'VALIDATE_VOUCHER_FAILED',
        payload: errors
      });
    }

    dispatch({
      type: 'VALIDATE_VOUCHER_DONE'
    });
    if (create) {
      dispatch(createConfiguration());
    } else {
      await dispatch(saveConfiguration());
      dispatch(loadConfiguration(configuration.id));
    }
    dispatch({ type: 'HIDE_BILLING_PERIOD_MODAL' });
    dispatch({ type: 'HIDE_VOUCHER_MODAL' });
  };
}
