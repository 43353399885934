/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  operationIds: {
    networkStatus: '',
    networkDetach: ''
  },
  operationStatuses: {
    networkStatus: '',
    networkDetach: ''
  },
  isProcessingOperation: true,
  networkStatus: {},
  error: ''
});

export default (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'SIMCARD_NETWORK_STATUS_OPERATION_SET_INIT': {
      return state
        .set('isProcessingOperation', true)
        .setIn(['operationIds', 'networkStatus'], '')
        .setIn(['operationIds', 'networkDetach'], '')
        .setIn(['operationStatuses', 'networkStatus'], 'pending')
        .setIn(['operationStatuses', 'networkDetach'], '')
        .set('networkStatus', {})
        .set('error', '');
    }
    case 'SIMCARD_NETWORK_STATUS_OPERATION_SET_DONE': {
      return state.setIn(['operationIds', 'networkStatus'], action.payload);
    }
    case 'SIMCARD_NETWORK_STATUS_OPERATION_SET_FAILED': {
      return state
        .set('isProcessingOperation', false)
        .set('error', action.payload.msg)
        .setIn(['operationStatuses', 'networkStatus'], 'failed');
    }
    case 'POLL_NETWORK_STATUS_OPERATION_STARTED': {
      return state
        .set('isProcessingOperation', true)
        .setIn(['operationStatuses', 'networkStatus'], 'pending')
        .set('error', '');
    }
    case 'POLL_NETWORK_STATUS_OPERATION_DONE': {
      return state
        .setIn(
          ['operationStatuses', 'networkStatus'],
          action.payload.toLowerCase()
        )
        .set('error', '');
    }
    case 'POLL_NETWORK_STATUS_OPERATION_FAILED': {
      return state
        .set('isProcessingOperation', false)
        .setIn(['operationStatuses', 'networkStatus'], 'failed')
        .set('error', action.payload);
    }
    case 'SIMCARD_NETWORK_STATUS_RESULT_FETCH_INIT': {
      return state.set('isProcessingOperation', true).set('error', '');
    }
    case 'SIMCARD_NETWORK_STATUS_RESULT_FETCH_DONE': {
      return state
        .set('isProcessingOperation', false)
        .setIn(['operationStatuses', 'networkStatus'], 'finished')
        .set('networkStatus', action.payload[0])
        .set('error', '');
    }
    case 'SIMCARD_NETWORK_STATUS_RESULT_FETCH_FAILED': {
      return state
        .set('isProcessingOperation', false)
        .setIn(['operationStatuses', 'networkStatus'], 'failed')
        .set('error', action.payload);
    }
    case 'SIMCARD_NETWORK_DETACH_OPERATION_SET_INIT': {
      return state
        .set('isProcessingOperation', true)
        .setIn(['operationIds', 'networkDetach'], '')
        .setIn(['operationStatuses', 'networkDetach'], 'pending')
        .set('error', '');
    }
    case 'SIMCARD_NETWORK_DETACH_OPERATION_SET_DONE': {
      return state.setIn(['operationIds', 'networkDetach'], action.payload);
    }
    case 'SIMCARD_NETWORK_DETACH_OPERATION_SET_FAILED': {
      return state
        .set('isProcessingOperation', false)
        .set('error', action.payload.msg)
        .setIn(['operationStatuses', 'networkDetach'], 'failed');
    }
    case 'POLL_NETWORK_DETACH_OPERATION_STARTED': {
      return state
        .set('isProcessingOperation', true)
        .set('error', '')
        .setIn(['operationStatuses', 'networkDetach'], 'pending');
    }
    case 'POLL_NETWORK_DETACH_OPERATION_DONE': {
      return state
        .set('isProcessingOperation', false)
        .set('error', '')
        .setIn(
          ['operationStatuses', 'networkDetach'],
          action.payload.toLowerCase()
        );
    }
    case 'POLL_NETWORK_DETACH_OPERATION_FAILED': {
      return state
        .set('isProcessingOperation', false)
        .setIn(['operationStatuses', 'networkDetach'], 'failed')
        .set('error', action.payload);
    }
    default: {
      return state;
    }
  }
};
