/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  configurationId: '',
  error: '',
  invoice: Map()
});

export default function editEndCustomerDetailsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_EDIT_END_CUSTOMER_DETAILS_MODAL': {
      return state
        .set('isShowing', true)
        .set('configurationId', action.payload.configurationId)
        .set('invoice', action.payload.copiedInvoice);
    }
    case 'HIDE_EDIT_END_CUSTOMER_DETAILS_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_END_CUSTOMER_DETAILS_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_END_CUSTOMER_DETAILS_INPUT_CHANGE': {
      return state.setIn(
        ['invoice', action.payload.name],
        action.payload.value
      );
    }
    case 'EDIT_END_CUSTOMER_DETAILS_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_END_CUSTOMER_DETAILS_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    default: {
      return state;
    }
  }
}
