/** @format */

import { connect } from 'react-redux';
import Modal from './Modal';

const mapStateToProps = (state) => {
  return {
    show: state.global.get('aboutModal'),
    versionDetails: state.global.get('appVersion'),
    loading:
      state.configuration.getIn(['operations', 'testMode', 'status']) ===
      'pending',
    error: state.configuration.getIn([
      'operations',
      'testMode',
      'testModeError'
    ])
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeModalState() {
    dispatch({ type: 'CHANGE_ABOUT_MODAL_STATE' });
  }
});

const AboutModal = connect(mapStateToProps, mapDispatchToProps)(Modal);
export default AboutModal;
