/** @format */

import isIp from 'is-ip';

export default function validateBACnet(configuration) {
  const enabled = configuration.bacnet && configuration.bacnet.lanPosition;

  const opmEnabled =
    enabled &&
    configuration.bacnet.operationMessages &&
    configuration.bacnet.operationMessages.enabled;

  const aiTrigger =
    enabled &&
    configuration.bacnet.alarmInfo &&
    configuration.bacnet.alarmInfo.trigger;
  const cleansed =
    aiTrigger && configuration.bacnet.alarmInfo.trigger.replace(/'/g, '');
  const validated = /[01]*/.exec(cleansed);
  const atLeastOne = /1/.test(validated);
  const aiEnabled =
    enabled &&
    configuration.bacnet.alarmInfo &&
    configuration.bacnet.alarmInfo.enabled;

  return {
    lanPosition: {
      type: 'number',
      parse: true,
      condition: (pos) => pos >= 0 && pos <= 4,
      errorCode: 'validation.bacnet.lanPosition',
      allowNull: true
    },
    lanPort: {
      type: 'number',
      parse: true,
      condition: (port) => !enabled || (port >= 0x01 && port <= 0xffff),
      errorCode: 'validation.bacnet.lanPort',
      allowNull: !enabled
    },
    deviceInstance: {
      type: 'number',
      parse: true,
      condition: (i) => !enabled || (i >= 0 && i <= 4194302),
      errorCode: 'validation.bacnet.deviceInstance',
      allowNull: !enabled
    },
    deviceName: {
      type: 'string',
      condition: (s) => !enabled || s.length > 0,
      errorCode: 'validation.bacnet.deviceName',
      allowNull: !enabled
    },
    fsInstance: {
      type: 'string',
      condition: (string) => {
        const instances = string.split(/(?:[,\s]+)/).filter((i) => Boolean(i));
        return (
          !enabled ||
          (instances.length >= 1 &&
            instances.length <= 64 &&
            instances.every((i) => {
              const num = parseInt(i, 10);
              return !/\D/.test(i) && num >= 0 && num <= 4194302;
            }))
        );
      },
      errorCode: 'validation.bacnet.fsInstance',
      allowNull: !enabled
    },
    bbmdAddress: {
      type: 'string',
      condition: (address) => !enabled || isIp(address),
      errorCode: 'validation.bacnet.bbmdAddress',
      allowNull: !enabled
    },
    bbmdPort: {
      type: 'number',
      parse: true,
      condition: (port) => !enabled || (port >= 0x01 && port <= 0xffff),
      errorCode: 'validation.bacnet.bbmdPort',
      allowNull: !enabled
    },
    bbmdSubnet: {
      type: 'string',
      condition: (address) => !enabled || isIp(address),
      errorCode: 'validation.bacnet.bbmdSubnet',
      allowNull: !enabled
    },
    bbmdGateway: {
      type: 'string',
      condition: (address) => !enabled || isIp(address),
      errorCode: 'validation.bacnet.bbmdGateway',
      allowNull: !enabled
    },
    operationMessages: {
      type: 'object',
      allowNull: !enabled,
      keys: {
        section: {
          type: 'number',
          parse: true,
          condition: (s) => !opmEnabled || (s >= 1 && s <= 254),
          errorCode: 'validation.bacnet.operationMessages.section',
          allowNull: !opmEnabled
        },
        limit: {
          type: 'number',
          parse: true,
          condition: (l) => !opmEnabled || (l >= 50 && l <= 1000),
          errorCode: 'validation.bacnet.operationMessages.limit',
          allowNull: !opmEnabled
        }
      }
    },
    alarmInfo: {
      type: 'object',
      allowNull: !enabled,
      keys: {
        trigger: {
          type: 'string',
          condition: () =>
            !aiEnabled ||
            (validated &&
              validated.length === 1 &&
              validated[0].length === validated.input.length &&
              atLeastOne),
          errorCode: 'validation.bacnet.alarmInfo.trigger',
          allowNull: !aiEnabled
        }
      }
    }
  };
}
