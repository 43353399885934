/** @format */
import { fromJS } from 'immutable';
import {
  SEND_REG_SCHEME_REQUEST,
  SEND_REG_SCHEME_SUCCESS,
  SEND_REG_SCHEME_FAILURE,
  SEND_REG_SCHEME_RESET
} from 'actions/configuration/registrateSchema/actionTypes';

const initialState = fromJS({
  isLoading: false,
  data: null,
  error: null,
  status: null
});

const sendRegSchemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_REG_SCHEME_REQUEST:
      return state.set('isLoading', true);
    case SEND_REG_SCHEME_SUCCESS:
      return state
        .set('isLoading', false)
        .set('data', action.payload)
        .set('error', null)
        .set('status', 'done');
    case SEND_REG_SCHEME_FAILURE:
      return state
        .set('isLoading', false)
        .set('data', null)
        .set('error', action.payload.msg)
        .set('status', 'error');
    case SEND_REG_SCHEME_RESET:
      return initialState;
    default:
      return state;
  }
};

export default sendRegSchemeReducer;
