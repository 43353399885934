/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  endUser: {
    name: '',
    organizationNumber: '',
    address: '',
    addressAlt: '',
    zipCode: '',
    city: ''
  },
  contact: {
    name: '',
    phoneNumber: '',
    email: '',
    mobilePhoneNumber: ''
  },
  installationData: {
    address: '',
    addressAlt: '',
    zipCode: '',
    city: ''
  },
  installer: {
    name: '',
    phoneNumber: '',
    email: ''
  },
  invoice: {
    to: 'organization'
  }
});

export default function contactInformationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'contactInformation'
      ) {
        const name = action.payload.name;
        return state.setIn([action.payload.index, name], action.payload.value);
      }
      return state;
    }
    case 'CONFIGURATION_EDIT_END_CUSTOMER_DETAILS_CHANGED': {
      return state.set('invoice', action.payload.invoice);
    }
    default: {
      return state;
    }
  }
}
