/** @format */

import { fromJS } from 'immutable';
const INITIAL_STATE = fromJS(getInitialState());

export default function systemMessagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, name, type, value } = action.payload;
      if (index !== undefined && type === 'systemMessages') {
        return state.setIn([index, name], value);
      }

      if (name === 'product') {
        return INITIAL_STATE;
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const messagesToArc = [
    {
      label: 'system.message.system',
      position: 1,
      sequenceChannel: ''
    },
    {
      label: 'system.message.test.alarms',
      position: 2,
      sequenceChannel: ''
    }
  ];
  return messagesToArc;
}
