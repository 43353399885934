/** @format */

import { fromJS, List, Map } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function inputReceiverMatrixReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'inputs' &&
        action.payload.name === 'label'
      ) {
        const position = action.payload.index + 1;
        let inputReceiverMatrix = state;
        inputReceiverMatrix = inputReceiverMatrix.filter(
          (matrix) => matrix.get('type') !== `in${position}`
        );
        inputReceiverMatrix = !action.payload.value
          ? inputReceiverMatrix
          : inputReceiverMatrix.push(
              Map({
                type: `in${position}`,
                position,
                label: action.payload.value,
                receiverPositions: List(),
                alarmCenterPositions: List()
              })
            );

        inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
        return inputReceiverMatrix;
      } else if (
        action.payload.name === 'product' ||
        action.payload.name === 'networkType'
      ) {
        let inputReceiverMatrix = state;
        inputReceiverMatrix = inputReceiverMatrix.filter(
          (matrix) => matrix.get('label') !== 'errorInputType.ethernet'
        );
        if (
          action.payload.value !== 'dalm2000' &&
          action.payload.value !== 'networkType.disabled'
        ) {
          inputReceiverMatrix = inputReceiverMatrix.insert(
            inputReceiverMatrix.size,
            Map({
              type: 'errorInput',
              position: 3,
              label: 'errorInputType.ethernet',
              receiverPositions: List(),
              alarmCenterPositions: List()
            })
          );
        }
        inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
        return inputReceiverMatrix;
      } else if (action.payload.name === 'productType') {
        let inputReceiverMatrix = state;
        inputReceiverMatrix = inputReceiverMatrix.filter(
          (matrix) => matrix.get('label') !== 'errorInputType.line'
        );
        if (
          action.payload.value === '4G' &&
          action.payload.params.dialerProtocol !== 'None'
        ) {
          inputReceiverMatrix = inputReceiverMatrix.insert(
            inputReceiverMatrix.size,
            Map({
              type: 'errorInput',
              position: 9,
              label: 'errorInputType.line',
              receiverPositions: List(),
              alarmCenterPositions: List()
            })
          );
        }
        inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
        return inputReceiverMatrix;
      } else if (
        action.payload.type === 'errorInputs' &&
        action.payload.name === 'disabled'
      ) {
        const label = action.payload.params.label;
        let inputReceiverMatrix = state;
        inputReceiverMatrix = inputReceiverMatrix.filter(
          (matrix) => matrix.get('label') !== label
        );
        if (!action.payload.value) {
          inputReceiverMatrix = inputReceiverMatrix.insert(
            inputReceiverMatrix.size,
            Map({
              type: 'errorInput',
              position: label === 'errorInputType.line' ? 9 : 6,
              label: label,
              receiverPositions: List(),
              alarmCenterPositions: List()
            })
          );
        }
        inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
        return inputReceiverMatrix;
      }
      return state;
    }
    case 'CONFIGURATION_EDIT_INPUT_RECEIVER_MATRIX_CHANGE': {
      const input = state.get(action.payload.index);
      const group = action.payload.group;
      if (input.get(group).includes(action.payload.position)) {
        return state.setIn(
          [action.payload.index, group],
          input
            .get(group)
            .filter((position) => position !== action.payload.position)
        );
      } else {
        return state.setIn(
          [action.payload.index, group],
          input.get(group).push(action.payload.position)
        );
      }
    }
    case 'CHANGE_SERIAL_PORT': {
      const serialPort = action.payload;
      let inputReceiverMatrix = state;
      inputReceiverMatrix = inputReceiverMatrix.filter(
        (matrix) =>
          matrix.get('label') !== 'errorInputType.serialEspa' &&
          matrix.get('type') !== 'serialPort'
      );
      if (serialPort.get('serialError')) {
        inputReceiverMatrix = inputReceiverMatrix.insert(
          inputReceiverMatrix.size,
          Map({
            type: 'errorInput',
            position: 6,
            label: 'errorInputType.serialEspa',
            receiverPositions: List(),
            alarmCenterPositions: List()
          })
        );
        inputReceiverMatrix = inputReceiverMatrix.insert(
          inputReceiverMatrix.size,
          Map({
            type: 'serialPort',
            position: inputReceiverMatrix.size,
            label: 'edit.serialporttype.' + serialPort.get('name'),
            receiverPositions: List(),
            alarmCenterPositions: List()
          })
        );
      }
      inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
      return inputReceiverMatrix;
    }
    case 'CHANGE_DIALER_PROTOCOL': {
      const newDialerProtocol = action.payload.value;
      let inputReceiverMatrix = state;
      inputReceiverMatrix = inputReceiverMatrix.filter(
        (matrix) => matrix.get('type') !== 'dialerProtocol'
      );
      inputReceiverMatrix = inputReceiverMatrix.filter(
        (matrix) => matrix.get('label') !== 'errorInputType.line'
      );
      if (newDialerProtocol !== 'None') {
        inputReceiverMatrix = inputReceiverMatrix.insert(
          inputReceiverMatrix.size,
          Map({
            type: 'dialerProtocol',
            position: inputReceiverMatrix.size,
            label: newDialerProtocol,
            receiverPositions: List(),
            alarmCenterPositions: List()
          })
        );
        if (action.payload.params.productType === '4G') {
          inputReceiverMatrix = inputReceiverMatrix.insert(
            inputReceiverMatrix.size,
            Map({
              type: 'errorInput',
              position: 9,
              label: 'errorInputType.line',
              receiverPositions: List(),
              alarmCenterPositions: List()
            })
          );
        }
      }
      inputReceiverMatrix = inputReceiverMatrix.sort(sortMatrix);
      return inputReceiverMatrix;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_INPUT_RECEIVER_MATRIX = [
    {
      type: 'errorInput',
      position: 1,
      label: 'errorInputType.battery',
      receiverPositions: [],
      alarmCenterPositions: []
    },
    {
      type: 'errorInput',
      position: 3,
      label: 'errorInputType.ethernet',
      receiverPositions: [],
      alarmCenterPositions: []
    },
    {
      type: 'errorInput',
      position: 4,
      label: 'errorInputType.gprs',
      receiverPositions: [],
      alarmCenterPositions: []
    },
    {
      type: 'errorInput',
      position: 5,
      label: 'errorInputType.sabotage',
      receiverPositions: [],
      alarmCenterPositions: []
    }
  ];
  return INITIAL_INPUT_RECEIVER_MATRIX;
}

export const sortMatrix = (itemA, itemB) => {
  if (
    itemA.get('type').startsWith('in') &&
    !itemB.get('type').startsWith('in')
  ) {
    return -1;
  } else if (
    itemB.get('type').startsWith('in') &&
    !itemA.get('type').startsWith('in')
  ) {
    return 1;
  }
  if (
    itemA.get('type') === 'errorInput' &&
    itemB.get('type') !== 'errorInput'
  ) {
    return -1;
  } else if (
    itemB.get('type') === 'errorInput' &&
    itemA.get('type') !== 'errorInput'
  ) {
    return 1;
  }
  if (
    itemA.get('type') === 'dialerProtocol' &&
    itemB.get('type') !== 'dialerProtocol'
  ) {
    return -1;
  } else if (
    itemB.get('type') === 'dialerProtocol' &&
    itemA.get('type') !== 'dialerProtocol'
  ) {
    return 1;
  }
  return itemA.get('position') > itemB.get('position');
};
