/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  progress: 0,
  error: '',
  status: ''
});

export default function rebootDalmReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'REBOOT_DALM_RESET_STATE':
      return INITIAL_STATE;
    case 'LOAD_CONFIGURATION_DONE': {
      return action.payload.status === 'reboot'
        ? state.set('status', 'started')
        : state.set('status', '');
    }
    case 'REBOOT_DALM_INITIATE_STARTED': {
      return state.set('error', '').set('progress', 0);
    }
    case 'REBOOT_DALM_INITIATE_DONE': {
      return state.set('status', 'started');
    }
    case 'REBOOT_DALM_POLL_DONE': {
      return state
        .set('status', action.payload.status)
        .set('progress', action.payload.progress);
    }
    case 'REBOOT_DALM_FAILED': {
      return state.set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
