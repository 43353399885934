/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  DESKTOP_EXTRA_SMALL,
  DESKTOP_SMALL,
  DESKTOP_MEDIUM
} from 'utils/helpers';

export const StyledLandingPage = styled.div`
  font-family: 'Gill Sans Regular', sans-serif;
  background-color: #004c93;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: auto;
  gap: 7rem;

  @media (max-width: ${DESKTOP_MEDIUM}) {
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2rem;

    svg {
      width: 50%;
      height: auto;
    }

    #service-manager-sygnet {
      @media (orientation: landscape) {
        display: none;
      }
    }
  }

  @media (max-width: ${DESKTOP_SMALL}) and (orientation: landscape) {
    svg {
      display: none;
    }
  }

  @media (max-width: ${DESKTOP_EXTRA_SMALL}) and (orientation: portrait) {
    gap: 0rem;
  }
`;

export const Header = styled.div`
  text-align: center;

  h1 {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    letter-spacing: 2px;
  }

  h2 {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);

    svg {
      margin-left: 0.5rem;
      g {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  @media (max-width: ${DESKTOP_MEDIUM}) and (orientation: landscape) {
    h1 {
      font-size: 2rem;
      margin-top: 1.5rem;
    }
  }

  @media (max-width: ${DESKTOP_EXTRA_SMALL}) and (orientation: portrait) {
    h1 {
      font-size: 2rem;
    }
  }
`;

export const Form = styled.form`
  margin: 0.5rem 0 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;

  input {
    margin: 0.5rem auto;
    width: 250px;
    height: 35px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 40px;
    padding: 0.25rem 1.5rem;
    background-color: #004c93;
    font-weight: 400;
    color: white;
    line-height: 1.125rem;
    font-size: 14px;
    line-height: 18px;
    outline: none;
    transition: border 0.2s ease-in-out;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #004c93;
    }

    &[data-autocompleted] {
      background-color: transparent !important;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    :hover {
      border: 2px solid rgba(255, 255, 255, 1);
    }
  }
`;

export const SubmitButton = styled.button`
  width: 300px;
  margin: 0.5rem auto;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 40px;
  padding: 0.25rem 1.5rem;
  background-color: #fff;
  color: #004c93;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  :hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  :disabled {
    cursor: not-allowed;
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const Utils = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  font-size: 14px;
`;

export const Select = styled.select`
  border: none;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 400;
  color: #fff;
  line-height: 1.125rem;
  font-size: 14px;
  cursor: pointer;
  outline: none;

  option {
    background-color: #004c93;
  }
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  transition: color 0.2s ease-in-out;
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const CheckboxLabel = styled.label`
  display: block;
  width: 48px;
  height: 24px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    cursor: pointer;
  }

  &::before {
    width: 100%;
    height: 100%;
    background-color: #dedede;
    border-radius: 9999em;
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
  }

  &::after {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
  }
`;
export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  :checked + ${CheckboxLabel}::before {
    background-color: #89c12d;
  }

  :checked + ${CheckboxLabel}::after {
    left: 24px;
  }
`;

export const InputError = styled.p.attrs({ role: 'alert' })`
  margin: 0.7rem auto;
  color: white;
  font-size: 13px;
  border-left: 3px solid crimson;
  max-width: 250px;
  text-align: left;

  span {
    padding-left: 0.7rem;
  }
`;

export const AddSecureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;

  @media (max-width: ${DESKTOP_SMALL}) {
    right: 50%;
    transform: translateX(50%);

    svg {
      width: 200px;
    }
  }
`;
