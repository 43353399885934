/** @format */

import { List } from 'immutable';
export { translate, browserLocale };

function translate(key, ignoreWarning) {
  if (List.isList(key) && key.size === 1) key = key.get(0);
  if (window.LanguageKeys) {
    if (window.LanguageKeys[key] !== undefined) {
      return window.LanguageKeys[key];
    }

    if (!ignoreWarning && process.env.NODE_ENV === 'development') {
      console.error(
        `Attempted to read language string for key {${key}} but found no matching translation`
      );
    }
  }
  return key || '';
}

function browserLocale() {
  var lang = '';
  if (navigator.languages && navigator.languages.length) {
    for (
      var i = 0;
      i < navigator.languages.length && lang.indexOf('-') === -1;
      i++
    ) {
      lang = navigator.languages[i];
    }
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage;
  } else {
    lang = navigator.language;
  }
  lang = lang.split('-');
  if (lang[1]) {
    lang[1] = lang[1].toUpperCase();
    return lang.join('_');
  }
  return 'en_GB';
}
