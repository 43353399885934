/** @format */

import get from './get';
import getUnactiveSubscriptions from './getUnactiveSubscriptions';
import handleActiveTerminal from './handleActiveTerminal';
import getShares from './getShares';

const exports = {
  get,
  getUnactiveSubscriptions,
  getShares,
  sortBy,
  openMoveDialog,
  onSearchBarInputChange,
  setStateFromQuery,
  setUnactiveSubscriptionsStateFromQuery,
  sortUnactiveSubscriptionsBy,
  onSearchBarUnactiveSubscriptionsInputChange,
  handleActiveTerminal
};

export default exports;

function setStateFromQuery(params) {
  return {
    type: 'CONFIGURATION_LIST_INIT_STATE',
    payload: params
  };
}

function setUnactiveSubscriptionsStateFromQuery(params) {
  return {
    type: 'UNACTIVE_SUBSCRIPTIONS_LIST_INIT_STATE',
    payload: params
  };
}

function sortBy(column) {
  return (dispatch, getState) => {
    const state = getState();
    const currentColumn = state.configuration.getIn(['list', 'sortedBy']);
    const currentOrder = state.configuration.getIn(['list', 'sortOrder']);
    let newOrder = 'descending';

    if (currentColumn === column) {
      newOrder = currentOrder === 'descending' ? 'ascending' : 'descending';
    }

    dispatch({
      type: 'CONFIGURATION_LIST_SORT',
      payload: {
        order: newOrder,
        column
      }
    });
  };
}

function sortUnactiveSubscriptionsBy(column) {
  return (dispatch, getState) => {
    const state = getState();
    const currentColumn = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'sortedBy'
    ]);
    const currentOrder = state.configuration.getIn([
      'listUnactiveSubscriptions',
      'sortOrder'
    ]);
    let newOrder = 'descending';

    if (currentColumn === column) {
      newOrder = currentOrder === 'descending' ? 'ascending' : 'descending';
    }

    dispatch({
      type: 'UNACTIVE_SUBSCRIPTIONS_LIST_SORT',
      payload: {
        order: newOrder,
        column
      }
    });
  };
}

function openMoveDialog(configurationId) {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['list', 'configurations'])
      .find((c) => c.get('id') === configurationId);

    dispatch({
      type: 'SHOW_MOVE_CONFIGURATION_MODAL',
      payload: {
        configuration
      }
    });
  };
}

function onSearchBarInputChange(value) {
  return {
    type: 'CONFIGURATIONS_SEARCH_BAR_INPUT_CHANGE',
    payload: value
  };
}

function onSearchBarUnactiveSubscriptionsInputChange(value) {
  return {
    type: 'UNACTIVE_SUBSCRIPTIONS_SEARCH_BAR_INPUT_CHANGE',
    payload: value
  };
}
