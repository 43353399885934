/** @format */

import doLogin from './login';
import { logout, resetOtc } from './logout';
import checkSession from './check';
import sendOtc from './otc';
import getSamlLink from './getSamlLink';
import acceptPolicy from './acceptPolicy';

export default {
  doLogin,
  inputChange,
  checkSession,
  sendOtc,
  logout,
  resetOtc,
  getSamlLink,
  acceptPolicy
};

function inputChange({ value, name }) {
  return {
    type: 'SESSION_LOGIN_FORM_CHANGE',
    payload: {
      value,
      name
    }
  };
}
