/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';

export const DOWNLOAD_CSV_REQUEST = 'DOWNLOAD_CSV_REQUEST';
export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_FAILURE = 'DOWNLOAD_CSV_FAILURE';
export const LOADING_CSV_FILE_DATA = 'LOADING_CSV_FILE_DATA';
export const HIDE_DOWNLOAD_CSV_FILE_MODAL = 'HIDE_DOWNLOAD_CSV_FILE_MODAL';

const LIMIT = 2000;

export default function downloadCsvFileEdgeManager(selectedFolder) {
  return async (dispatch) => {
    await dispatch({
      type: DOWNLOAD_CSV_REQUEST,
      payload: {
        folder: selectedFolder.get('name') || 'All Configurations'
      }
    });

    let offset = 0;
    let moreData = true;
    let response;

    const data = [];

    while (moreData) {
      try {
        response = await request.get(
          `${config.apiServer}/configurations/download/csv`,
          {
            query: {
              folderId: selectedFolder.get('id'),
              limit: LIMIT,
              offset
            }
          }
        );
      } catch (errorResponse) {
        console.error(errorResponse);
        dispatch({
          type: DOWNLOAD_CSV_FAILURE,
          payload: {
            msg: errorResponse.body.msg
          }
        });
      }
      data.push(...response.body.data);
      dispatch({
        type: LOADING_CSV_FILE_DATA,
        payload: {
          total: response.body.total,
          count: data.length
        }
      });

      if (response.body.data.length < LIMIT) {
        moreData = false;
      } else {
        offset += LIMIT;
      }
    }

    dispatch({
      type: DOWNLOAD_CSV_SUCCESS,
      payload: data
    });
  };
}
