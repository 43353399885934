/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import { formatAlarmCenters } from 'actions/configuration/utils';

export default function loadSecurityRouterConfiguration(id) {
  return async (dispatch) => {
    if (id === null || !isFinite(id)) {
      return dispatch({
        type: 'LOAD_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    id = parseInt(id, 10);
    dispatch({ type: 'LOAD_SECURITY_ROUTER_CONFIGURATION_STARTED' });

    let response;
    try {
      response = await request.get(
        `${config.apiServer}/configurations/security-router/${id}`
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'LOAD_SECURITY_ROUTER_CONFIGURATION_FAILED',
        payload: { msg: errorResponse.body.msg }
      });
    }
    const { configuration, pollingIntervals, dataPlans } = response.body;
    configuration.alarmCenters = formatAlarmCenters(configuration.alarmCenters);
    dispatch({
      type: 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE',
      payload: configuration
    });
    dispatch({
      type: 'LOAD_POLLING_INTERVALS_DONE',
      payload: pollingIntervals
    });
    dispatch({
      type: 'LOAD_DATA_PLANS_DONE',
      payload: dataPlans
    });
  };
}
