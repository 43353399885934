/** @format */

export default function validateErrorInput() {
  return {
    type: 'object',
    keys: {
      delay: {
        type: 'number',
        parse: true,
        condition: (delay) => delay <= 32000 && delay >= 0,
        errorCode: 'validation.input.delay.invalid'
      }
    }
  };
}
