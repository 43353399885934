/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function camerasReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'cameras'
      ) {
        const name = action.payload.name;
        return state.setIn([action.payload.index, name], action.payload.value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_CAMERAS_JSON = [];
  for (let i = 0; i < 4; i++) {
    INITIAL_CAMERAS_JSON.push({
      position: i,
      ipAddress: '',
      url: ''
    });
  }
  return INITIAL_CAMERAS_JSON;
}
