/** @format */

import { Map, fromJS } from 'immutable';

const organizationForm =
  window.sessionStorage && window.sessionStorage.getItem('organizationForm')
    ? JSON.parse(window.sessionStorage.getItem('organizationForm'))
    : {
        country: 'OTHER',
        language: 'en_GB',
        navCustomerPrefix: 'AddSecure AB'
      };

const userForm =
  window.sessionStorage && window.sessionStorage.getItem('userForm')
    ? JSON.parse(window.sessionStorage.getItem('userForm'))
    : {};

const INITIAL_STATE = fromJS({
  stage: 'organization',
  isSending: false,
  error: '',
  organization: {
    organizationForm,
    formErrors: {}
  },
  user: {
    userForm,
    formErrors: {}
  },
  auth: {
    authForm: {},
    formErrors: {}
  },
  resetCaptcha: false
});

const sessions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REGISTER_START': {
      return state.set('isSending', true);
    }
    case 'REGISTER_DONE': {
      return state.set('isSending', false);
    }
    case 'REGISTER_FAILED': {
      return state
        .set('isSending', false)
        .set('error', action.payload.msg)
        .set('resetCaptcha', !!action.payload.resetCaptcha);
    }
    case 'REGISTER_ORGANIZATION_FORM_CHANGED': {
      const name = action.payload.name;
      const value = action.payload.value;
      const newState = state.setIn(
        ['organization', 'organizationForm', name],
        value
      );
      window.sessionStorage.setItem(
        'organizationForm',
        JSON.stringify(newState.getIn(['organization', 'organizationForm']))
      );
      return newState;
    }
    case 'REGISTER_ORGANIZATION_FORM_DONE': {
      return state
        .set('error', '')
        .setIn(['organization', 'formErrors'], Map());
    }
    case 'REGISTER_ORGANIZATION_FORM_ERRORS': {
      return state.setIn(['organization', 'formErrors'], action.payload);
    }
    case 'REGISTER_ORGANIZATION_FORM_FAILED': {
      return state.set('error', action.payload);
    }
    case 'REGISTER_USER_FORM_CHANGED': {
      const name = action.payload.name;
      const value = action.payload.value;
      const newState = state.setIn(['user', 'userForm', name], value);
      window.sessionStorage.setItem(
        'userForm',
        JSON.stringify(newState.getIn(['user', 'userForm']))
      );
      return newState;
    }
    case 'REGISTER_USER_FORM_DONE': {
      return state.set('error', '').setIn(['user', 'formErrors'], Map());
    }
    case 'REGISTER_USER_FORM_ERRORS': {
      return state.setIn(['user', 'formErrors'], fromJS(action.payload));
    }
    case 'REGISTER_USER_FORM_FAILED': {
      return state
        .set('error', action.payload.msg)
        .set('resetCaptcha', !!action.payload.resetCaptcha);
    }
    case 'REGISTER_OTC_FORM_CHANGED': {
      const name = action.payload.name;
      const value = action.payload.value;
      return state.setIn(['auth', 'authForm', name], fromJS(value));
    }
    case 'REGISTER_OTC_VALIDATE_START': {
      return state.setIn(['auth', 'formErrors'], Map());
    }
    case 'REGISTER_OTC_VALIDATE_DONE': {
      return state.setIn('error', '');
    }
    case 'REGISTER_OTC_VALIDATE_FAILED': {
      return state.set('error', action.payload);
    }
    case 'REGISTER_OTC_VALIDATE_FORM_ERROR': {
      return state.setIn(['auth', 'formErrors'], action.payload);
    }
    case 'REGISTER_CLEAR_PASSWORD': {
      const newUserForm = state
        .getIn(['user', 'userForm'])
        .delete('password')
        .delete('passwordConfirmation');
      window.sessionStorage.setItem('userForm', JSON.stringify(newUserForm));
      return state.setIn(['user', 'userForm'], fromJS(newUserForm));
    }
    case 'REGISTER_CHANGE_STAGE': {
      return state.set('stage', action.payload).set('error', '');
    }
    case 'REGISTER_RESET_STATE':
    case 'SESSION_LOGOUT_DONE':
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

export default sessions;
