/** @format */

import { fromJS } from 'immutable';
import {
  GET_SUBSCRIPTION_STATUS_REQUEST,
  GET_SUBSCRIPTION_STATUS_SUCCESS,
  GET_SUBSCRIPTION_STATUS_FAILURE,
  RESET_SUBSCRIPTION_STATUS
} from '../../actions/configuration/getSubscriptionStatus/actionTypes';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  data: {},
  status: ''
});

export default function edgeManagerStatus(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_STATUS_REQUEST: {
      return state
        .set('loading', true)
        .set('status', '')
        .set('data', fromJS({}));
    }
    case GET_SUBSCRIPTION_STATUS_SUCCESS: {
      return state
        .set('data', fromJS(action.payload))
        .set('loading', false)
        .set('status', 'success');
    }
    case GET_SUBSCRIPTION_STATUS_FAILURE: {
      return state
        .set('error', action.payload.msg)
        .set('loading', false)
        .set('status', 'error');
    }
    case RESET_SUBSCRIPTION_STATUS: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
