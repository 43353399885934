/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS(getInitialState());

export default function receiversReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'receivers'
      ) {
        const name = action.payload.name;
        return state.setIn([action.payload.index, name], action.payload.value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const INITIAL_RECEIVER_JSON = [];
  const NUMBER_OF_RECEIVERS = 7;
  for (let i = 0; i < NUMBER_OF_RECEIVERS; i++) {
    INITIAL_RECEIVER_JSON.push({
      id: null,
      label: '',
      position: i + 1,
      type: null,
      address: ''
    });
  }
  return INITIAL_RECEIVER_JSON;
}
