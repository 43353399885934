/** @format */

import React from 'react';
import config from 'config.json';
import { Select } from '../../views/LandingPage/LandingPage.styles';
import { translate } from 'utils/language';

export default class LanguageBar extends React.Component {
  componentDidMount() {
    this.props.pollMaintenanceText();
    this.poll();
  }
  poll() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.pollMaintenanceText();
      this.poll();
    }, 60000);
  }
  render() {
    const {
      chosenLanguage,
      changeLanguage,
      maintenanceText,
      landingPageVariant = false
    } = this.props;

    return landingPageVariant ? (
      <Select
        value={chosenLanguage}
        onChange={(e) => changeLanguage(e.target.value)}
        name='language-picker'
      >
        {config.languages.map((language, i) => {
          return (
            <option key={i} value={language.languageCode}>
              {translate(language.languageCode)}
            </option>
          );
        })}
      </Select>
    ) : (
      <div className={`language-bar ${!maintenanceText && 'mobile-hide'}`}>
        <div className='slogan'>{config.slogan}</div>
        {maintenanceText && (
          <div className='maintenanceText'>
            <span>{maintenanceText}</span>
          </div>
        )}
        <div className='flags'>
          {config.languages.map((language, i) => (
            <a
              key={i}
              className={`flag-wrapper ${
                chosenLanguage === language.languageCode ? 'selected' : ''
              }`}
              onClick={() => {
                changeLanguage(language.languageCode);
              }}
            >
              <div className={`flag flag-icon flag-icon-${language.flag}`} />
            </a>
          ))}
        </div>
      </div>
    );
  }
}
