/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  type: 'none',
  ipv6Address: '',
  prefixLength: 0,
  defaultGateway: '',
  dns: ''
});

export default function ipv6Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'ipv6') {
        const name = action.payload.name;
        return state.set(name, action.payload.value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
