/** @format */

import { fromJS } from 'immutable';

const INITIAL_NAT_STATE = fromJS({ sourceAddress: '', destinationAddress: '' });

const INITIAL_VPN_STATE = fromJS({
  remoteAddress: '',
  remoteSubnet: '',
  encryption: 'aes256',
  hash: 'sha256',
  dhGroup: 'modp2048',
  keyExchangeProtocol: 'ikev2',
  leftId: '',
  rightId: '',
  lifetimeConnection: 0,
  lifetimeIke: 0,
  disableRekey: false,
  disableReauth: false,
  disableMobike: false,
  dpdAction: 'none',
  dpdDelay: 0,
  mode: 'tunnel',
  localSubnet: '',
  securityProtocol: 'esp',
  securityEncryption: 'aes256',
  securityHash: 'sha256',
  pfsGroup: 'modp2048',
  authenticationMethod: 'psk',
  nats: [],
  loading: false,
  error: ''
});

const INITIAL_STATE = fromJS(
  Array(4)
    .fill()
    .map((_, index) => ({
      ipAddress: '',
      mtu: 1500,
      position: index + 1,
      vpn: INITIAL_VPN_STATE
    }))
);

export default function lansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_CONFIGURATION_DONE': {
      return state.map((lan) => {
        const lanVpn = lan.get('vpn') || INITIAL_VPN_STATE;
        const vpnNats = lanVpn.get('nats').size
          ? lanVpn.get('nats')
          : lanVpn.get('nats').push(INITIAL_NAT_STATE);
        return lan.set('vpn', lanVpn.set('nats', vpnNats));
      });
    }
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value, params } = action.payload;
      if (index !== undefined && type === 'lans') {
        return state.setIn(
          [
            index,
            ...(params.isVpn ? ['vpn'] : []),
            ...(Number.isFinite(params.natIndex)
              ? ['nats', params.natIndex]
              : []),
            name
          ],
          value
        );
      }
      return state;
    }
    case 'VPN_CONNECT_STARTED':
    case 'VPN_DISCONNECT_STARTED': {
      const { index } = action.payload;
      return state
        .setIn([index, 'loading'], true)
        .map((lan) => lan.set('error', ''));
    }
    case 'VPN_POLL_DONE': {
      return !action.payload.done
        ? state
        : state.map((lan) =>
            !lan.get('loading')
              ? lan
              : lan
                  .setIn(['vpn', 'isActive'], !lan.getIn(['vpn', 'isActive']))
                  .set('loading', false)
          );
    }
    case 'VPN_POLL_FAILED': {
      const { msg } = action.payload;
      return state.map((lan) =>
        !lan.get('loading') ? lan : lan.set('loading', false).set('error', msg)
      );
    }
    case 'VPN_CONNECT_FAILED':
    case 'VPN_DISCONNECT_FAILED': {
      const { index, msg } = action.payload;
      return state
        .setIn([index, 'loading'], false)
        .setIn([index, 'error'], msg);
    }
    case 'VPN_ADD_NAT': {
      const { index } = action.payload;
      return state.setIn(
        [index, 'vpn', 'nats'],
        state.getIn([index, 'vpn', 'nats']).push(INITIAL_NAT_STATE)
      );
    }
    case 'VPN_REMOVE_NAT': {
      const { index, natIndex } = action.payload;
      return state.setIn(
        [index, 'vpn', 'nats'],
        state.getIn([index, 'vpn', 'nats']).delete(natIndex)
      );
    }
    default: {
      return state;
    }
  }
}
