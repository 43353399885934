/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  things: [],
  total: 0,
  offset: 0,
  loading: true,
  query: '',
  error: '',
  orderBy: 'createdAt',
  ascending: false
});

export default function list(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'THINGS_USAGE_UPDATE_START': {
      if (action.payload && action.payload.id) {
        const things = state.get('things').toJS();
        const thing = things.find((t) => t.id === action.payload.id);
        thing.updatePending = true;
        return state.set('things', fromJS(things));
      } else return state;
    }
    case 'THINGS_USAGE_UPDATE': {
      if (action.payload && action.payload.id) {
        const things = state.get('things').toJS();
        const thing = things.find((t) => t.id === action.payload.id);
        thing.updatePending = false;
        if (action.payload.billable && action.payload.billable.reportedAt) {
          thing.dataUsage = action.payload.billable.usage.toFixed(2);
        }
        if (action.payload.provider && action.payload.provider.reportedAt) {
          thing.simTotal = action.payload.provider.usage.toFixed(2);
        }
        return state.set('things', fromJS(things));
      } else return state;
    }
    case 'THINGS_LIST_RESTORE':
    case 'THINGS_LIST_LOAD':
      const things = action.payload.append
        ? state.get('things').concat(fromJS(action.payload.things))
        : fromJS(action.payload.things);
      return state
        .set('error', '')
        .set('loading', false)
        .set('append', false)
        .set('things', things)
        .set(
          'offset',
          action.payload.append ? things.size : state.get('offset')
        )
        .set('total', action.payload.total);
    case 'THINGS_LIST_OFFSET': {
      if (state.get('offset') === action.payload) {
        return state;
      }
      return state
        .set('things', fromJS([]))
        .set('offset', action.payload)
        .set('loading', action.payload !== state.get('offset'));
    }
    case 'THINGS_LIST_QUERY':
      return state
        .set('offset', 0)
        .set('query', action.payload)
        .set('loading', true);
    case 'THINGS_LIST_ORDER':
      return state
        .set('orderBy', action.payload)
        .set(
          'ascending',
          state.get('orderBy') === action.payload && !state.get('ascending')
        )
        .set(
          'offset',
          state.get('orderBy') === action.payload ? state.get('offset') : 0
        )
        .set('loading', true);
    case 'THINGS_LIST_LOAD_MORE':
      return state
        .set('loading', true)
        .set('offset', state.get('things').size)
        .set('append', true);
    case 'THINGS_LIST_ERROR':
      return state.set('loading', false).set('error', action.payload);
    case 'TOGGLE_DEMO_START':
      return state.set('loading', true).set('error', '');
    case 'TOGGLE_DEMO_FAILED':
      return state.set('loading', false).set('error', action.payload);
    case 'TOGGLE_DEMO_DONE':
      return state.set('loading', false).set(
        'things',
        state
          .get('things')
          .map((thing) =>
            thing.get('id') === action.payload.id
              ? thing.set('demoStartedAt', action.payload.demoStartedAt)
              : thing
          )
      );
    default:
      return state;
  }
}
