/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  username: '',
  recoveryCode: '',
  password: '',
  passwordRepeat: '',
  step: 1,
  token: null,
  showCaptcha: false,
  messages: {
    step1: '',
    step2: '',
    step3: ''
  },
  errors: {
    step1: '',
    step2: '',
    step3: ''
  },
  validation: {
    step1: '',
    step2: '',
    step3: ''
  }
});

const sessions = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case 'PASSWORD_RECOVERY_MOUNT': {
      return INITIAL_STATE;
    }
    case 'PASSWORD_RECOVERY_FORM_INPUT_CHANGE': {
      return state
        .setIn(['validation', action.payload.name], '')
        .setIn(['errors', `step${state.get('step')}`], '')
        .set(action.payload.name, action.payload.value);
    }
    case 'PASSWORD_RECOVERY_STEP1_ERROR': {
      return state.setIn(['validation', 'step1'], action.payload.msg);
    }
    case 'PASSWORD_RECOVERY_STEP2_ERROR': {
      return state
        .setIn(['validation', 'step2'], action.payload.msg)
        .set('showCaptcha', action.payload.showCaptcha);
    }
    case 'PASSWORD_RECOVERY_STEP3_ERROR': {
      return state.setIn(['validation', 'step3'], action.payload.msg);
    }
    case 'PASSWORD_RECOVERY_GO_TO_NEXT_STEP': {
      return state
        .set('step', state.get('step') + 1)
        .set('showCaptcha', action.payload.showCaptcha);
    }
    case 'PASSWORD_RECOVERY_SET_TOKEN': {
      return state.set('token', action.payload);
    }
    case 'PASSWORD_RECOVERY_BACK': {
      return state.set('step', 1).setIn(['validation', 'step2'], '');
    }
    default: {
      return state;
    }
  }
};

export default sessions;
