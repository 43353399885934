/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS([]);

export default function portForwardingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'portForwarding'
      ) {
        const { name, value, index } = action.payload;
        return state.setIn([index, name], value);
      }
      if (
        action.payload.name === 'portForwardingEnabled' &&
        !action.payload.value
      ) {
        return INITIAL_STATE;
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_PORT_FORWARDING_RULE': {
      const rules = state.toJS();
      rules.push(getInitialRule());
      return fromJS(rules);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_PORT_FORWARDING_RULE': {
      const rules = state.toJS();
      rules.splice(action.payload.index, 1);
      return fromJS(rules);
    }
    default: {
      return state;
    }
  }
}

function getInitialRule() {
  return {
    externalInterface: 'wan',
    ports: '',
    protocol: 'tcp',
    destination: ''
  };
}
