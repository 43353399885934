/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  progress: 0,
  status: '',
  error: '',
  selectedFirmware: null,
  showSelectModal: false
});

export default function firmwareUpgradeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE':
    case 'LOAD_CONFIGURATION_DONE': {
      return action.payload.busyWith === 'firmware.upgrade.ongoing'
        ? INITIAL_STATE.set('status', 'started')
        : INITIAL_STATE;
    }
    case 'FIRMWARE_UPGRADE_STARTED': {
      return state.set('status', 'started');
    }
    case 'FIRMWARE_UPGRADE_SELECT_VERSION': {
      return state.set('selectedFirmware', action.payload.id);
    }
    case 'FIRMWARE_UPGRADE_HIDE_SELECT_MODAL': {
      return state.set('showSelectModal', false);
    }
    case 'FIRMWARE_UPGRADE_SHOW_SELECT_MODAL': {
      return state.set('showSelectModal', true);
    }
    case 'FIRMWARE_UPGRADE_STATUS_POLL_DONE': {
      const { progress, status } = action.payload;
      if (status !== 'failed') {
        state = state.set('status', status);
      }
      if (progress) {
        state = state.set('progress', progress);
      }
      return state;
    }
    case 'FIRMWARE_UPGRADE_STATUS_POLL_FAILED': {
      return state.set('error', action.payload);
    }
    case 'FIRMWARE_UPGRADE_FAILED': {
      return state.set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
