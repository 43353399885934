/** @format */

export default function validateRemoteUnit(remoteUnit) {
  const isSet =
    remoteUnit.label ||
    remoteUnit.type ||
    remoteUnit.address ||
    remoteUnit.port;
  return {
    type: 'object',
    keys: isSet
      ? {
          label: { type: 'string', errorCode: 'validation.required.label' },
          type: { type: 'string', errorCode: 'validation.type.notselected' },
          address: {
            type: 'string',
            errorCode: 'validation.not.valid.ip',
            allowNullErrorCode: 'validation.ipAddress.notselected'
          },
          port: {
            type: 'number',
            errorCode: 'validation.not.valid.port',
            allowNullErrorCode: 'validation.ipReceiver.port.not.entered'
          }
        }
      : {}
  };
}
