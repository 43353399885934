/** @format */

import isIp from 'is-ip';

export default function validatePortForwardingRule() {
  return {
    type: 'object',
    keys: {
      ports: {
        type: 'string',
        condition: (ports) => validatePorts(ports),
        allowNull: false,
        errorCode: 'validation.not.valid.port'
      },
      destination: {
        type: 'string',
        condition: (ipAddress) => isIp(ipAddress),
        allowNull: false,
        errorCode: 'validation.ipAddress.notselected'
      }
    }
  };
}
function validatePorts(ports) {
  if (ports.includes('-')) {
    const [x, y] = ports.split('-');
    if (isNaN(x) || isNaN(y)) return false;
    if (
      Number(x) < 1 ||
      Number(x) > 65535 ||
      Number(y) < 1 ||
      Number(y) > 65535
    )
      return false;
    if (Number(x) > Number(y)) return false;
  } else if (isNaN(ports)) {
    return false;
  } else if (Number(ports) < 1 || Number(ports) > 65535) return false;
  return true;
}
