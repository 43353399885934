/** @format */

import { subnetInfo } from 'ip-utils';
export { hasCollidingIpConfiguration };

function hasCollidingIpConfiguration({
  ipAddress,
  lan: { position, prefix, netmask },
  lans,
  global = false
}) {
  if (!ipAddress || (!prefix && !netmask)) return false;
  try {
    const subnet = ipAddress && netmask && subnetInfo(ipAddress, netmask);
    return lans
      .filter((someLan) => global || someLan.position !== position)
      .some((someLan) =>
        isCollidingCidr(
          `${someLan.ipAddress}/${someLan.prefix}`,
          `${ipAddress}/${subnet && subnet.cidrMask ? subnet.cidrMask : prefix}`
        )
      );
  } catch (error) {
    return false;
  }
}

function isCollidingCidr(cidrA, cidrB) {
  const [ipv4A, prefixA] = cidrA.split('/');
  const [ipv4B, prefixB] = cidrB.split('/');
  const [ipNumberA, ipNumberB] = [ipv4A, ipv4B].map(ipv4StringToNumber);
  const [prefixNumberA, prefixNumberB] = [prefixA, prefixB].map(
    cidrPrefixToNumber
  );
  const subnetNumberA = ipNumberA & prefixNumberA;
  const subnetNumberB = ipNumberB & prefixNumberB;

  return (
    (subnetNumberA & prefixNumberB) === subnetNumberB ||
    (subnetNumberB & prefixNumberA) === subnetNumberA
  );
}

function ipv4StringToNumber(ipv4) {
  const ipv4Regex = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
  const ips = ipv4.match(ipv4Regex).map(Number);
  return (ips[1] << 24) + (ips[2] << 16) + (ips[3] << 8) + ips[4];
}

function cidrPrefixToNumber(prefix) {
  return -1 << (32 - prefix);
}
