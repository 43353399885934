/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false
});

export default function hardwareResetModalReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_HARDWARE_RESET_MODAL': {
      return state.set('isShowing', true);
    }
    case 'HIDE_HARDWARE_RESET_MODAL': {
      return state.set('isShowing', false);
    }
    default: {
      return state;
    }
  }
}
