/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showModal: false,
  gprsFail: false,
  ethernetFail: false
});

export default function warningBeforeCommissionReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_WARNING_BEFORE_COMMISSION': {
      return state
        .set('isShowing', true)
        .set('gprsFail', action.payload.gprsFail)
        .set('ethernetFail', action.payload.ethernetFail);
    }
    case 'CLOSE_WARNING_BEFORE_COMMISSION': {
      return INITIAL_STATE;
    }
    case 'ABORT_COMMISSION': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
