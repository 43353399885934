/** @format */

export default function validateRelay(relay) {
  return {
    type: 'object',
    keys: {
      optionalFunctionality: {
        type: 'string',
        allowNull: !relay.label && !relay.outputType,
        errorCode: 'validation.optional.functionality.notselected'
      }
    }
  };
}
