/** @format */

import { fromJS, Map } from 'immutable';
import {
  getDefaultSerialPort,
  getDefaultEthernet,
  getDefaultPower,
  getDefaultTestMode,
  getDefaultCommunicationFailure,
  getDefaultSabotage,
  getDefaultLine,
  getDefaultGprs
} from './defaultErrorInputs';
const INITIAL_STATE = fromJS(getInitialState());

export default function errorInputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, name, type, value, params } = action.payload;
      if (index !== undefined && type === 'errorInputs') {
        return state.setIn([index, name], value);
      }

      if (
        name !== 'product' &&
        name !== 'productType' &&
        name !== 'networkType' &&
        name !== 'gprsDisabled' &&
        type !== 'ipv6'
      ) {
        return state;
      }
      if (name === 'networkType' || type === 'ipv6') {
        const oldV4Value = params.ipv4Type;
        const oldV6Value =
          action.payload.product === 'dalmLte' ? params.ipv6Type : 'none';
        const bothOldDisabled =
          oldV4Value === 'networkType.disabled' && oldV6Value === 'none';
        const bothNewDisabled =
          name === 'networkType'
            ? oldV6Value === 'none' && value === 'networkType.disabled'
            : oldV4Value === 'networkType.disabled' && value === 'none';
        if (bothOldDisabled || bothNewDisabled) {
          const shouldReplaceEthernet =
            value !== 'none' && value !== 'networkType.disabled';
          const ethernetReplacement =
            shouldReplaceEthernet && getDefaultEthernet();
          state = replaceErrorInput(
            state,
            'errorInputType.ethernet',
            ethernetReplacement
          );
        }
      } else if (name === 'gprsDisabled') {
        const nowDisabled = value === 'gprs.disabled';
        state = replaceErrorInput(
          state,
          'errorInputType.gprs',
          !nowDisabled && getDefaultGprs()
        );
      } else {
        const oldProduct = params.oldProduct;
        if (oldProduct === 'dalm2000' || value === 'dalm2000') {
          const shouldReplaceEthernet = value !== 'dalm2000';
          const ethernetReplacement =
            shouldReplaceEthernet && getDefaultEthernet();
          state = replaceErrorInput(
            state,
            'errorInputType.ethernet',
            ethernetReplacement
          );
        }
      }

      if (name === 'product') {
        const powerReplacement = value === 'dalmLte' && getDefaultPower();
        state = replaceErrorInput(
          state,
          'errorInputType.power',
          powerReplacement
        );
      }
      if (name === 'productType') {
        const lineReplacement =
          value === '4G' &&
          params.dialerProtocol !== 'None' &&
          getDefaultLine();
        state = replaceErrorInput(
          state,
          'errorInputType.line',
          lineReplacement
        );
      }
      return state;
    }
    case 'CHANGE_SERIAL_PORT': {
      const serialPort = action.payload;
      const replacement =
        serialPort.get('serialError') && getDefaultSerialPort();
      return replaceErrorInput(state, 'errorInputType.serialEspa', replacement);
    }
    case 'CHANGE_DIALER_PROTOCOL': {
      const lineReplacement =
        action.payload.value !== 'None' &&
        action.payload.params.productType === '4G' &&
        getDefaultLine();
      state = replaceErrorInput(state, 'errorInputType.line', lineReplacement);
      return state;
    }
    case 'ADD_ERROR_INPUT_TEST_MODE': {
      return state
        .push(Map(getDefaultTestMode()))
        .push(Map(getDefaultCommunicationFailure()))
        .sort((a, b) => a.get('position') > b.get('position'));
    }
    case 'ADD_ERROR_INPUT_SABOTAGE': {
      return state
        .push(Map(getDefaultSabotage()))
        .sort((a, b) => a.get('position') > b.get('position'));
    }
    case 'EDIT_CONFIGURATION_PREDEFINED_VALUES': {
      const { dialerProtocol, alarmCenter, notChangeSaved } = action.payload;
      const eventCodes = alarmCenter && alarmCenter.get('eventCodes');
      return eventCodes
        ? setPredefinedValues({
            errorInputs: state,
            eventCodes,
            dialerProtocol,
            notChangeSaved
          })
        : state;
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const errorInputTypes = [
    {
      label: 'errorInputType.battery',
      defaultEventCode: '81',
      defaultDelay: '60',
      defaultDelayType: 'C',
      position: 1,
      reservedFields: []
    },
    {
      label: 'errorInputType.ethernet',
      defaultEventCode: '82',
      defaultDelay: '300',
      defaultDelayType: 'C',
      position: 3,
      reservedFields: []
    },
    {
      label: 'errorInputType.gprs',
      defaultEventCode: '83',
      defaultDelay: '18000',
      defaultDelayType: 'C',
      position: 4,
      reservedFields: []
    }
  ];
  const INITIAL_ERROR_INPUTS_JSON = [];
  errorInputTypes.forEach((type) => {
    INITIAL_ERROR_INPUTS_JSON.push({
      label: type.label,
      sequenceChannel: '',
      delay: type.defaultDelay,
      showExtendedBody: false,
      text: '',
      eventCode: type.defaultEventCode,
      sectionNumber: '01',
      sectionText: 'Section 1',
      delayType: type.defaultDelayType,
      twentyFourHourZone: true,
      sendReset: true,
      resetDefinition: 'N',
      disabled: false,
      position: type.position,
      reservedFields: []
    });
  });
  return INITIAL_ERROR_INPUTS_JSON;
}

function replaceErrorInput(state, label, replacement) {
  const oldInput = state.find((input) => input.get('label') === label);
  const oldId = oldInput ? oldInput.get('id') : null;
  state = state.filter((input) => input.get('label') !== label);
  if (replacement) {
    replacement.id = oldId;
    state = state.insert(state.size, Map(replacement));
  }
  return state.sort((a, b) => a.get('position') > b.get('position'));
}

function setPredefinedValues({
  errorInputs,
  eventCodes,
  dialerProtocol,
  notChangeSaved
}) {
  return errorInputs.map((errorInput) => {
    if (notChangeSaved && errorInput.get('id')) return errorInput;
    const errorInputPositions = {
      'errorInputType.battery': 9,
      'errorInputType.ethernet': 10,
      'errorInputType.gprs': 11,
      'errorInputType.sabotage': 13,
      'errorInputType.testmode': 20,
      'errorInputType.line': 35,
      'errorInputType.serialEspa': 39
    };
    const inputEventCodes = eventCodes.find(
      (match) =>
        match.get('position') === errorInputPositions[errorInput.get('label')]
    );

    if (!inputEventCodes) {
      return errorInput;
    }

    const reservedFields = [];
    const editable = inputEventCodes.get('editable');
    const eventCode = inputEventCodes.get('eventCode', '');
    const sectionText = inputEventCodes.get('sectionText', '');
    const sectionNumber = inputEventCodes.get('sectionNumber', '');

    if (!editable) {
      reservedFields.push('eventCode', 'sectionText', 'sectionNumber');
    }

    if (dialerProtocol !== 'Contact ID') {
      return errorInput
        .set('reservedFields', fromJS(reservedFields))
        .set('eventCode', eventCode)
        .set('sectionText', sectionText)
        .set('sectionNumber', sectionNumber);
    }

    const cidEventCode = inputEventCodes.getIn(['cid', 'eventCode']);
    const cidSectionNumber = inputEventCodes.getIn(['cid', 'sectionNumber']);
    return errorInput
      .set('reservedFields', fromJS(reservedFields))
      .set('sectionText', sectionText)
      .set('eventCode', cidEventCode || eventCode)
      .set('sectionNumber', cidSectionNumber || sectionNumber);
  });
}
