/** @format */

import isIp from 'is-ip';
import { subnet } from 'ip-utils';

export default function validateReservation({ lan }) {
  return {
    type: 'object',
    keys: {
      ipAddress: {
        type: 'string',
        condition: (ipAddress) =>
          isIp(ipAddress) &&
          subnet(`${lan.ipAddress}/${lan.prefix}`).contains(ipAddress) &&
          ipAddress !== lan.ipAddress,
        errorCode: 'validation.reservation.ipAddress.not.valid'
      },
      macAddress: {
        type: 'string',
        condition: (macAddress) =>
          /^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/.test(macAddress),
        errorCode: 'validation.mac.address.not.valid'
      },
      label: {
        type: 'string',
        errorCode: 'validation.required.label'
      }
    }
  };
}
