/** @format */

import React from 'react';
import ErrorView from 'components/views/ErrorView';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return <ErrorView error={this.state.error.toString()} />;
  }
}

export default ErrorBoundary;
