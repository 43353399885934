/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function ({ body, method = 'GET', action, payload, path = '' }) {
  path = path.charAt(0) === '/' ? path : `/${path}`;
  path = method === 'GET' ? `${path}${path.endsWith('/') ? '' : '/'}` : path;

  return async (dispatch) => {
    dispatch({ type: `${action}_STARTED`, payload });
    try {
      const response = await request.ajax(
        method,
        `${config.apiServer}${path}`,
        {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'same-origin',
          body
        }
      );
      dispatch({
        type: `${action}_DONE`,
        payload: { ...payload, ...response.body }
      });
    } catch (response) {
      dispatch({
        type: `${action}_FAILED`,
        payload: {
          ...payload,
          msg:
            (response && response.body && response.body.msg) ||
            'errors.maintenance'
        }
      });
    }
  };
}
