/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function changeBillingPeriod(value) {
  return async (dispatch, getState) => {
    dispatch({ type: 'CHANGE_BILLING_PERIOD_STARTED' });
    const state = getState();
    const configuration = state.configuration.getIn([
      'current',
      'configuration'
    ]);
    try {
      await request.post(
        `${
          config.apiServer
        }/configurations/change-billing-period/${configuration.get('id')}`,
        { body: { value } }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'CHANGE_BILLING_PERIOD_FAILED',
        payload: {
          error: errorResponse.body.msg || 'server.error'
        }
      });
    }

    dispatch({ type: 'CHANGE_BILLING_PERIOD_DONE', payload: value });
  };
}
