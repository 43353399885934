/** @format */

export const notDalmAffectedFields = [
  'name',
  'freeText',
  'invoiceReference',
  'createdAt',
  'dalmUpdatedAt',
  'folderId',
  'noInstallationData',
  'contactInformation',
  'country',
  'contactInformation'
];
