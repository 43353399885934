/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {},
  formErrors: {},
  isShowing: false,
  isCreating: false,
  failedUserNames: [],
  numberOffAddedUsers: 0,
  imported: false
});

export default function addUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_IMPORT_USERS_MODAL': {
      state = INITIAL_STATE;
      return state.set('isShowing', true);
    }
    case 'HIDE_IMPORT_USERS_MODAL': {
      return state.set('isShowing', false);
    }
    case 'IMPORT_USERS_START': {
      return state.set('formErrors', fromJS({}));
    }
    case 'IMPORT_USERS_SENDING_START': {
      return state
        .set('isCreating', true)
        .set('imported', false)
        .set('failedUserNames', [])
        .set('numberOffAddedUsers', 0)
        .set('formErrors', fromJS({}));
    }
    case 'IMPORT_USERS_DONE': {
      return state
        .set('isCreating', false)
        .set('imported', true)
        .set('failedUserNames', action.payload.failedUserNames)
        .set('numberOffAddedUsers', action.payload.numberOffAddedUsers);
    }
    case 'IMPORT_USERS_FAILED': {
      return state
        .set('isCreating', false)
        .set('formErrors', fromJS(action.errors));
    }
    case 'IMPORT_USERS_CHANGED': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'IMPORT_USER_FORM_ERRORS': {
      return state.setIn(
        ['formErrors', 'userRole'],
        'validation.required.rights'
      );
    }
    case 'IMPORT_USERS_FILE_MISSING': {
      return state.setIn(
        ['formErrors', 'file'],
        ['import.users.required.file']
      );
    }
    default: {
      return state;
    }
  }
}
