/** @format */

import isIp from 'is-ip';
export default function validateAlarmCenter(
  alarmCenter = {},
  country,
  editConfigurationFeatures
) {
  return {
    type: 'object',
    keys: {
      copiedFromPredefinedAlarmCenter: {
        type: 'string',
        condition: (copiedFromPredefinedAlarmCenter) =>
          copiedFromPredefinedAlarmCenter !== 'none',
        errorCode: 'validation.ipReceiver.not.selected'
      },
      name: {
        type: 'string',
        errorCode: 'validation.ipReceiver.name.not.entered'
      },
      eventProtocol: {
        type: 'string',
        condition: (eventProtocol) => eventProtocol !== 'select',
        errorCode: 'validation.ipReceiver.event.protocol.not.selected'
      },
      ...validateIps(alarmCenter),
      ...(alarmCenter.hasDc09 ? validateDc09(alarmCenter) : validateV4()),
      ...validatePolling(country, editConfigurationFeatures),
      events: {
        type: 'array',
        items: (event) => validateEvent(event, alarmCenter)
      }
    }
  };
}

function validateV4() {
  return {
    v4: {
      type: 'object',
      keys: {
        transmitterCode: {
          type: 'string',
          condition: (transmitterCode) => transmitterCode.length <= 15,
          errorCode: 'validation.ipReceiver.transmitterCode.not.1to15'
        },
        transmitterType: {
          type: 'string',
          allowNull: true,
          condition: (transmitterType) => transmitterType.length <= 5,
          errorCode: 'validation.ipReceiver.transmitterType.not.0to5'
        },
        authenticationCode: {
          type: 'string',
          allowNull: true,
          condition: (authenticationCode) => authenticationCode.length <= 15,
          errorCode: 'validation.ipReceiver.authenticationCode.not.0to15'
        },
        sosAreaCode: {
          type: 'string',
          allowNull: true,
          condition: (sosAreaCode) => sosAreaCode.length <= 20,
          errorCode: 'validation.ipReceiver.sosAreaCode.not.0to20'
        },
        additionalInformation: {
          type: 'string',
          allowNull: true,
          condition: (additionalInformation) =>
            additionalInformation.length <= 100,
          errorCode: 'validation.ipReceiver.additionalInformation.not.0to100'
        }
      }
    }
  };
}

function validateDc09(alarmCenter) {
  return {
    dc09: {
      type: 'object',
      keys: {
        encryptionKey: {
          type: 'string',
          allowNull:
            !alarmCenter.encrypted ||
            (alarmCenter.dc09 && alarmCenter.dc09.eagleEyeCms),
          condition: (encryptionKey) =>
            /^([0-9a-fA-F]{32}|[0-9a-fA-F]{64})$/.test(encryptionKey),
          errorCode: 'validation.ipReceiver.dc09enckey.not.32or64chars'
        },
        accountNumber: {
          type: 'string',
          condition: (accountNumber) =>
            /^[0-9a-fA-F]{1,24}$/.test(accountNumber),
          errorCode:
            'validation.ipReceiver.dc09.accountnumber.not.1to16.hexchars'
        }
      }
    }
  };
}

function validatePolling(country, editConfigurationFeatures) {
  if (
    country.get('isoCode') === 'SE' &&
    editConfigurationFeatures.includes('alarmClass')
  ) {
    return {
      communicationPath: {
        type: 'string',
        condition: (communicationPath) => communicationPath !== 'select',
        errorCode: 'validation.ipReceiver.communication.path.not.entered'
      },
      alarmClass: {
        type: 'string',
        allowNull: true,
        condition: (alarmClass) => alarmClass !== 'select',
        errorCode: 'validation.ipReceiver.alarm.class.not.entered'
      }
    };
  }
  return {
    pollingInterval: {
      type: 'string',
      allowNull: true,
      condition: (pollingInterval) => pollingInterval !== 'select',
      errorCode: 'validation.ipReceiver.polling.not.entered'
    },
    communicationPath: {
      type: 'string',
      condition: (communicationPath) => communicationPath !== 'select',
      errorCode: 'validation.ipReceiver.communication.path.not.entered'
    }
  };
}

function validateIps(alarmCenter) {
  if (alarmCenter.copiedFromPredefinedAlarmCenter !== 'other') {
    return {
      primaryReceiverIp: { type: 'any', allowNull: true },
      primaryReceiverPort: { type: 'any', allowNull: true },
      secondaryReceiverIp: { type: 'any', allowNull: true },
      secondaryReceiverPort: { type: 'any', allowNull: true }
    };
  }
  const communicationPath = alarmCenter.communicationPath;
  const samePathSettings = alarmCenter.samePathSettings;
  if (
    communicationPath === 'select' ||
    (communicationPath === 'all' && samePathSettings)
  ) {
    return {
      primaryReceiverIp: {
        type: 'string',
        condition: (primaryReceiverIp) => isIp(primaryReceiverIp),
        errorCode: 'validation.not.valid.ip'
      },
      primaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port'
      },
      secondaryReceiverIp: {
        type: 'string',
        condition: (secondaryReceiverIp) => isIp(secondaryReceiverIp),
        errorCode: 'validation.not.valid.ip',
        allowNull: !alarmCenter.secondaryReceiverPort
      },
      secondaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port',
        allowNull: !alarmCenter.secondaryReceiverIp
      }
    };
  } else if (communicationPath === 'ethernet') {
    return {
      primaryReceiverIp: {
        type: 'string',
        condition: (primaryethernetReceiverIp) =>
          isIp(primaryethernetReceiverIp),
        errorCode: 'validation.not.valid.ip'
      },
      primaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port'
      },
      secondaryReceiverIp: {
        type: 'string',
        condition: (secondaryethernetReceiverIp) =>
          isIp(secondaryethernetReceiverIp),
        errorCode: 'validation.not.valid.ip',
        allowNull: !alarmCenter.secondaryethernetReceiverPort
      },
      secondaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port',
        allowNull: !alarmCenter.secondaryethernetReceiverIp
      }
    };
  } else if (communicationPath === 'mobile') {
    return {
      primaryReceiverIp: {
        type: 'string',
        condition: (primarymobileReceiverIp) => isIp(primarymobileReceiverIp),
        errorCode: 'validation.not.valid.ip'
      },
      primaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port'
      },
      secondaryReceiverIp: {
        type: 'string',
        condition: (secondarymobileReceiverIp) =>
          isIp(secondarymobileReceiverIp),
        errorCode: 'validation.not.valid.ip',
        allowNull: !alarmCenter.secondarymobileReceiverPort
      },
      secondaryReceiverPort: {
        type: 'number',
        parse: true,
        errorCode: 'validation.not.valid.port',
        allowNull: !alarmCenter.secondarymobileReceiverIp
      }
    };
  }
  return {
    primaryethernetReceiverIp: {
      type: 'string',
      condition: (primaryethernetReceiverIp) => isIp(primaryethernetReceiverIp),
      errorCode: 'validation.not.valid.ip'
    },
    primaryethernetReceiverPort: {
      type: 'number',
      parse: true,
      errorCode: 'validation.not.valid.port'
    },
    primarymobileReceiverIp: {
      type: 'string',
      condition: (secondaryethernetReceiverIp) =>
        isIp(secondaryethernetReceiverIp),
      errorCode: 'validation.not.valid.ip'
    },
    primarymobileReceiverPort: {
      type: 'number',
      parse: true,
      errorCode: 'validation.not.valid.port'
    },
    secondaryethernetReceiverIp: {
      type: 'string',
      condition: (secondaryethernetReceiverIp) =>
        isIp(secondaryethernetReceiverIp),
      errorCode: 'validation.not.valid.ip',
      allowNull: !alarmCenter.secondaryethernetReceiverPort
    },
    secondaryethernetReceiverPort: {
      type: 'number',
      parse: true,
      errorCode: 'validation.not.valid.port',
      allowNull: !alarmCenter.secondaryethernetReceiverIp
    },
    secondarymobileReceiverIp: {
      type: 'string',
      condition: (secondarymobileReceiverIp) => isIp(secondarymobileReceiverIp),
      errorCode: 'validation.not.valid.ip',
      allowNull: !alarmCenter.secondarymobileReceiverPort
    },
    secondarymobileReceiverPort: {
      type: 'number',
      parse: true,
      errorCode: 'validation.not.valid.port',
      allowNull: !alarmCenter.secondarymobileReceiverIp
    }
  };
}

function validateEvent(event, alarmCenter) {
  switch (alarmCenter.eventProtocol) {
    case 'v4.sia':
      return {
        type: 'object',
        keys: {
          alarm: {
            type: 'string',
            allowNull: !event.active || event.position === 63,
            condition: (alarm) => alarm.length < 25,
            errorCode: 'validation.ipReceiver.event.alarm.not.entered',
            conditionErrorCode:
              'validation.ipReceiver.event.alarm.v4.sia.length'
          },
          tamper: {
            type: 'string',
            allowNull: true,
            condition: (tamper) => tamper.length < 25,
            errorCode: 'validation.ipReceiver.event.tamper.v4.sia.length'
          },
          section: {
            type: 'string',
            allowNull: true,
            condition: (section) => section.length <= 5,
            errorCode: 'validation.ipReceiver.event.section.v4.length'
          },
          sectionText: {
            type: 'string',
            allowNull: true,
            condition: (sectionText) => sectionText.length <= 40,
            errorCode: 'validation.ipReceiver.event.section.text.v4.length'
          }
        }
      };
    case 'v4.contactId':
      return {
        type: 'object',
        keys: {
          alarm: {
            type: 'string',
            allowNull: !event.active || event.position === 63,
            condition: (alarm) => alarm.length <= 25,
            errorCode: 'validation.ipReceiver.event.alarm.not.entered',
            conditionErrorCode:
              'validation.ipReceiver.event.alarm.v4.contactId.length'
          },
          tamper: {
            type: 'string',
            allowNull: true,
            condition: (tamper) => tamper.length <= 25,
            errorCode: 'validation.ipReceiver.event.tamper.v4.contactId.length'
          },
          section: {
            type: 'string',
            allowNull: true,
            condition: (section) => section.length <= 5,
            errorCode: 'validation.ipReceiver.event.section.v4.length'
          },
          sectionText: {
            type: 'string',
            allowNull: true,
            condition: (sectionText) => sectionText.length <= 40,
            errorCode: 'validation.ipReceiver.event.section.text.v4.length'
          }
        }
      };
    case 'dc09.sia':
      return {
        type: 'object',
        keys: {
          alarm: {
            type: 'string',
            allowNull: !event.active || event.position === 63,
            condition: (alarm) => /^[A-Za-z]{2}$/.test(alarm),
            errorCode: 'validation.ipReceiver.event.alarm.not.entered',
            conditionErrorCode:
              'validation.ipReceiver.event.alarm.dc09.sia.length'
          },
          alarmRestore: {
            type: 'string',
            allowNull: !event.active || event.position === 63,
            condition: (alarmRestore) => /^[A-Za-z]{2}$/.test(alarmRestore),
            errorCode: 'validation.ipReceiver.event.restore.not.entered',
            conditionErrorCode:
              'validation.ipReceiver.event.alarm.restore.dc09.sia.length'
          },
          tamper: {
            type: 'string',
            allowNull: true,
            condition: (tamper) => /^[A-Za-z]{2}$/.test(tamper),
            errorCode: 'validation.ipReceiver.event.tamper.dc09.sia.length'
          },
          tamperRestore: {
            type: 'string',
            allowNull: true,
            condition: (tamperRestore) => /^[A-Za-z]{2}$/.test(tamperRestore),
            errorCode:
              'validation.ipReceiver.event.tamper.restore.dc09.sia.length'
          },
          section: {
            type: 'string',
            allowNull: true,
            condition: (section) => /^[0-9A-Fa-f]{0,4}$/.test(section),
            errorCode: 'validation.ipReceiver.event.section.dc09.sia.length'
          },
          sectionText: {
            type: 'string',
            allowNull: true,
            condition: (sectionText) => sectionText.length <= 63,
            errorCode: 'validation.ipReceiver.event.section.text.dc09.length'
          }
        }
      };
    case 'dc09.contactId':
      return {
        type: 'object',
        keys: {
          alarm: {
            type: 'string',
            allowNull: !event.active || event.position === 63,
            condition: (alarm) => /^[0-9]{3}$/.test(alarm),
            errorCode: 'validation.ipReceiver.event.alarm.not.entered',
            conditionErrorCode:
              'validation.ipReceiver.event.alarm.dc09.contactId.length'
          },
          tamper: {
            type: 'string',
            allowNull: true,
            condition: (tamper) => /^[0-9]{3}$/.test(tamper),
            errorCode:
              'validation.ipReceiver.event.tamper.dc09.contactId.length'
          },
          section: {
            type: 'string',
            allowNull: true,
            condition: (section) => /^[0-9]{3}$/.test(section),
            errorCode:
              'validation.ipReceiver.event.section.dc09.contactId.length'
          }
        }
      };
    default:
      return {
        type: 'object'
      };
  }
}
