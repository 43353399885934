/** @format */

export default function validateInput({ input, editConfigurationFeatures }) {
  const isSet =
    input.label ||
    input.inputType ||
    input.alarmText ||
    typeof input.flickerCounter === 'number';

  const flickerSettings = [
    'flickerCounter',
    'flickerDetectTime',
    'flickerModeTime'
  ];

  return {
    type: 'object',
    allowNull: true,
    keys: {
      ...flickerSettings.reduce(
        (acc, settingsKey) => ({
          ...acc,
          [settingsKey]: {
            type: 'number',
            condition: (setting) => setting > 0,
            allowNull: flickerSettings.every((setting) => !input[setting]),
            errorCode: 'validation.flicker.detection.invalid'
          }
        }),
        {}
      ),
      ...(editConfigurationFeatures.includes('sequenceChannel')
        ? {
            sequenceChannel: {
              type: 'string',
              allowNull:
                !input.inputType || typeof input.flickerCounter !== 'number',
              errorCode: 'validation.sequenceChannel.notselected'
            }
          }
        : isSet
        ? {
            inputType: {
              type: 'string',
              errorCode: 'validation.type.notselected'
            },
            label: {
              type: 'string',
              errorCode: 'validation.required.label'
            },
            eventCode: {
              type: 'string',
              condition: (eventCode) => eventCode.length <= 25,
              errorCode: 'validation.input.eventCode.not.1to25'
            },
            sectionNumber: {
              type: 'string',
              condition: (sectionNumber) => sectionNumber.length <= 5,
              errorCode: 'validation.input.sectionNumber.not.0to5'
            },
            sectionText: {
              type: 'string',
              condition: (sectionText) => sectionText.length <= 40,
              errorCode: 'validation.input.sectionText.not.0to40'
            },
            delay: {
              type: 'number',
              condition: (delay) => delay <= 32000,
              errorCode: 'validation.input.delay.invalid'
            }
          }
        : {})
    }
  };
}
