/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    userId: '',
    validUntil: ''
  },
  loadingUsers: false,
  isShowing: false,
  isCreating: false,
  created: false,
  type: 'user',
  error: Map()
});

export default function shareFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_SHARE_EXTERNAL_MODAL': {
      return INITIAL_STATE.set('isShowing', true).set('isCreating', false);
    }
    case 'HIDE_SHARE_EXTERNAL_MODAL': {
      return state.set('isShowing', false);
    }
    case 'SHARE_EXTERNAL_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'LOAD_ORGANIZATION_USERS_STARTED': {
      return state.set('loadingUsers', true);
    }
    case 'LOAD_ORGANIZATION_USERS_DONE': {
      return state.set('loadingUsers', false);
    }
    case 'LOAD_ORGANIZATION_USERS_FAILED': {
      return state.set('loadingUsers', false);
    }
    case 'EXTERNAL_SHARE_START': {
      return state.set('isCreating', true).set('error', Map());
    }
    case 'EXTERNAL_SHARE_DONE': {
      return state.set('created', true);
    }
    case 'EXTERNAL_SHARE_FAILED': {
      const error = action.payload.msg;
      return state.set('isCreating', false).set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
