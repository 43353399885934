/** @format */

export default function validateLine(line) {
  return {
    type: 'object',
    keys: {
      delay: {
        type: 'number',
        condition: (delay) => delay <= 32000 && delay >= 0,
        errorCode: 'validation.input.delay.invalid',
        allowNull: !line.lineSupervision,
        parse: true
      },
      panelType: {
        type: 'string',
        condition: (panelType) =>
          line.alarmProtocol === 'None' || panelType !== 'Choose',
        allowNull: line.alarmProtocol === 'None',
        errorCode: 'validation.panel.type.invalid'
      }
    }
  };
}
