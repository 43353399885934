/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  receiver: {},
  loading: false,
  sent: false,
  facilityName: '',
  error: ''
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_EAGLE_EYE_RECEIVER_TEST_MODAL': {
      return state
        .set('show', true)
        .set('receiver', fromJS(action.payload.receiver))
        .set('facilityName', action.payload.facilityName)
        .set('securityRouter', action.payload.securityRouter);
    }
    case 'HIDE_EAGLE_EYE_RECEIVER_TEST_MODAL': {
      return INITIAL_STATE;
    }
    case 'EAGLE_EYE_RECEIVER_TEST_STARTED': {
      return state.set('loading', true);
    }
    case 'EAGLE_EYE_RECEIVER_TEST_DONE': {
      return state.set('sent', true).set('loading', false);
    }
    case 'EAGLE_EYE_RECEIVER_TEST_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    default: {
      return state;
    }
  }
};
