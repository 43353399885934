/** @format */

import { fromJS, List } from 'immutable';
import config from 'config';

const INITIAL_STATE = fromJS({
  msg: '',
  error: '',
  loading: false,
  facilityName: '',
  nameError: '',
  receivers: [],
  voicePhrases: [],
  voiceActivated: false,
  voiceError: false
});

const receiverTemplate = fromJS({
  name: '',
  type: 'email',
  address: '',
  language: config.defaultLanguage,
  areaFilters: '',
  eventFilters: '',
  sectionFilters: '',
  voicePin: null,
  errors: {}
});

const voicePhraseTemplate = fromJS({
  event: '',
  section: '',
  message: '',
  language: config.defaultLanguage,
  errors: {},
  type: 'serial'
});

export default function eagleEyeReceivers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EAGLE_EYE_RECEIVER_INPUT_ERRORS': {
      return state
        .set('msg', '')
        .set('nameError', action.payload.nameError)
        .update('receivers', (receivers) =>
          receivers.map((receiver, index) =>
            receiver.set(
              'errors',
              fromJS(action.payload.receiverErrors[index] || {})
            )
          )
        )
        .update('voicePhrases', (voicePhrases) =>
          voicePhrases.map((voicePhrase, index) =>
            voicePhrase.set(
              'errors',
              fromJS(action.payload.voiceErrors[index] || {})
            )
          )
        )
        .set('voiceError', Object.keys(action.payload.voiceErrors).length > 0);
    }
    case 'EAGLE_EYE_RECEIVER_LOAD_START': {
      return state
        .set('msg', state.get('msg') ? state.get('msg') : '')
        .set('error', '')
        .set('loading', true);
    }
    case 'EAGLE_EYE_RECEIVER_LOAD_DONE': {
      return INITIAL_STATE.set(
        'facilityName',
        action.payload.facilityName || ''
      )
        .set('msg', state.get('msg') ? state.get('msg') : '')
        .set(
          'receivers',
          fromJS(
            action.payload.receivers.map((receiver) => ({
              ...receiver,
              errors: {}
            }))
          )
        )
        .set(
          'voicePhrases',
          fromJS(
            action.payload.voicePhrases.map((voicePhrase) => ({
              ...voicePhrase,
              errors: {}
            }))
          )
        )
        .set(
          'voiceActivated',
          state.get('voiceActivated') ||
            !!action.payload.voicePhrases.length ||
            action.payload.receivers.some(
              (receiver) => receiver.type === 'voice'
            )
        );
    }
    case 'EAGLE_EYE_RECEIVER_LOAD_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    case 'EAGLE_EYE_VOICE_SERVICE_TOGGLE': {
      return state
        .set('voiceActivated', !state.get('voiceActivated'))
        .set('voicePhrases', List())
        .set(
          'receivers',
          !state.get('voiceActivated')
            ? state.get('receivers')
            : state
                .get('receivers')
                .filter((receiver) => receiver.get('type') !== 'voice')
        );
    }
    case 'EAGLE_EYE_VOICE_PHRASE_ADD': {
      const newVoicePhrase = fromJS(action.payload) || voicePhraseTemplate;
      return state
        .set('msg', '')
        .update('voicePhrases', (voicePhrases) =>
          voicePhrases.push(newVoicePhrase)
        );
    }
    case 'EAGLE_EYE_VOICE_PHRASE_INPUT_CHANGE': {
      const { index, name, value, params } = action.payload;
      if (index === -1) {
        if (
          params &&
          (params.type === 'input' || params.type === 'errorInput') &&
          name !== 'language'
        ) {
          const newVoicePhrase = voicePhraseTemplate
            .set('type', params.type)
            .set('section', params.section)
            .set('event', params.event)
            .set('language', params.language)
            .set(name, value);
          return state
            .set('msg', '')
            .update('voicePhrases', (voicePhrases) =>
              voicePhrases.push(newVoicePhrase)
            );
        } else return state;
      } else if (
        params &&
        (params.type === 'input' || params.type === 'errorInput') &&
        !value
      ) {
        return state.update('voicePhrases', (voicePhrases) =>
          voicePhrases.filter((voicePhrase, i) => i !== index)
        );
      }
      return state
        .set('msg', '')
        .update('voicePhrases', (voicePhrases) =>
          voicePhrases.update(index, (voicePhrase) =>
            voicePhrase.set(name, value)
          )
        );
    }
    case 'EAGLE_EYE_VOICE_PHRASE_REMOVE': {
      return state.update('voicePhrases', (voicePhrases) =>
        voicePhrases.filter((voicePhrase, index) => index !== action.payload)
      );
    }
    case 'EAGLE_EYE_VOICE_PHRASE_PLAY': {
      return state;
    }
    case 'EAGLE_EYE_RECEIVER_ADD': {
      const newReceiver = fromJS(action.payload) || receiverTemplate;
      return state
        .set('msg', '')
        .update('receivers', (receivers) => receivers.push(newReceiver));
    }
    case 'EAGLE_EYE_RECEIVER_REMOVE': {
      return state.update('receivers', (receivers) =>
        receivers.filter((receiver, index) => index !== action.payload)
      );
    }
    case 'EAGLE_EYE_RECEIVER_FACILITY_NAME_CHANGE': {
      return state
        .set('msg', '')
        .set('nameError', '')
        .set('facilityName', action.payload);
    }
    case 'EAGLE_EYE_RECEIVER_INPUT_CHANGE': {
      const { index, name } = action.payload;
      let { value } = action.payload;
      if (!value) {
        value = '';
      }
      return state
        .set('msg', '')
        .update('receivers', (receivers) =>
          receivers.update(index, (receiver) =>
            receiver
              .set(name, value)
              .update('errors', (errors) => errors.remove(name))
          )
        );
    }
    case 'EAGLE_EYE_RECEIVER_UPDATE_STARTED': {
      return state.set('msg', '').set('error', '');
    }
    case 'EAGLE_EYE_RECEIVER_UPDATE_DONE': {
      return state.set('msg', 'edit.forward.alarm.done');
    }
    case 'EAGLE_EYE_RECEIVER_UPDATE_FAILED': {
      return state.set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
