/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';
import queryS from 'query-string';
import urls from 'utils/urls';
import history from 'utils/history';

export default function get({
  offset = 0,
  fromFolder,
  eagleEye,
  append,
  organizationId,
  onlyExternal = false
} = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const limit = state.configuration.getIn(['list', 'pageLimit']);
    const query = state.configuration.getIn(['list', 'searchQuery']);
    const filter = state.configuration.getIn(['list', 'filter']);
    const filterCountry = state.configuration.getIn(['list', 'filterCountry']);
    offset = offset || state.configuration.getIn(['list', 'offset']);
    const selectedFolder = state.configuration.getIn([
      'folders',
      'selectedFolder'
    ]);
    const sortOrder = state.configuration.getIn(['list', 'sortOrder']);
    const orderBy = state.configuration.getIn(['list', 'sortedBy']);
    let folderId;
    if (fromFolder) {
      folderId = selectedFolder.get('id');
      onlyExternal = selectedFolder.get('external');
      if (typeof folderId === 'string' && folderId.startsWith('organization')) {
        organizationId = folderId.substring('organization'.length) || null;
        folderId = null;
        onlyExternal = true;
      }
    }

    dispatch({
      type: 'CONFIGURATIONS_LOAD_START',
      payload: { append, eagleEye }
    });
    let response;
    try {
      response = await request.get(`${config.apiServer}/configurations/list`, {
        query: {
          organizationId,
          limit,
          offset,
          eagleEye,
          folderId,
          query,
          filter,
          filterCountry,
          onlyExternal,
          orderBy,
          ascending: sortOrder === 'ascending'
        }
      });

      const stateAfterRequest = getState().configuration.get('list');
      const hasChangedPage = !stateAfterRequest.get('loading');
      const hasChangedQuery = stateAfterRequest.get('searchQuery') !== query;
      const hasChangedEagleEye = stateAfterRequest.get('eagleEye') !== eagleEye;
      const hasChangedFilter = stateAfterRequest.get('filter') !== filter;
      if (
        hasChangedPage ||
        hasChangedQuery ||
        hasChangedEagleEye ||
        hasChangedFilter
      ) {
        return;
      }

      if (typeof response !== 'object') {
        const error = new Error('badResponseType');
        error.body = {
          msg: 'errors.badResponseType'
        };
        throw error;
      }
    } catch (errorResponse) {
      return dispatch({
        type: 'CONFIGURATIONS_LOAD_FAILED',
        payload: errorResponse.body
      });
    }

    offset = Math.min(offset, parseInt(response.body.total, 10));
    const page = Math.floor(offset / limit) + 1;

    if (!organizationId) {
      const queryString = queryS.stringify({
        page,
        searchQuery: query,
        pageLimit: limit,
        sortOrder,
        sortedBy: orderBy,
        offset,
        folderId,
        fromExternal: onlyExternal
      });
      const baseUrl = eagleEye ? urls.eagleEyeList : urls.configurationsList;
      history.replace(`${baseUrl}?${queryString}`);
    } else if (onlyExternal) {
      const queryString = queryS.stringify({
        page,
        searchQuery: query,
        pageLimit: limit,
        sortOrder,
        sortedBy: orderBy,
        offset,
        folderId: `organization${organizationId}`,
        fromExternal: onlyExternal
      });
      const baseUrl = urls.configurationsList;
      history.replace(`${baseUrl}?${queryString}`);
    }

    dispatch({
      type: 'CONFIGURATIONS_LOAD_DONE',
      payload: {
        total: response.body.total,
        configurations: response.body.configurations,
        isGatewayApiAvailable: response.body.isGatewayApiAvailable,
        eagleEye,
        offset,
        page,
        append
      }
    });
  };
}
