/** @format */

import React from 'react';

const Modal = ({ onClose, show, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className='dualtech-modal' onClick={onClose}>
      <div className='content'>{children}</div>
    </div>
  );
};
export default Modal;
