/** @format */

import { fromJS, List } from 'immutable';

const INITIAL_STATE = fromJS({
  list: [],
  sortedBy: 'createdAt',
  sortOrder: 'descending',
  loading: false
});

const serviceLogs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_SERVICE_LOGS_START': {
      return state.set('loading', true);
    }
    case 'GET_SERVICE_LOGS_DONE': {
      const logRows = action.payload;
      return state.set('loading', true).set('list', fromJS(logRows));
    }
    case 'GET_SERVICE_LOGS_FAILED': {
      return state.set('loading', false).set('list', List());
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default serviceLogs;
