/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({ isShowing: false, errors: {} });

export default function mobileModemFirmwareUpgradeReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'MOBILE_MODEM_FIRMWARE_UPGRADE_MISSING_PARAMETER':
      return state.set('errors', fromJS(action.payload.errors));
    case 'MOBILE_MODEM_FIRMWARE_UPGRADE_HIDE_SELECT_MODAL':
      return INITIAL_STATE;
    case 'MOBILE_MODEM_FIRMWARE_UPGRADE_SHOW_SELECT_MODAL':
      return INITIAL_STATE.set('isShowing', true);
    default:
      return state;
  }
}
