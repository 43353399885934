/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadEagleEyeReceivers(id) {
  const requestUrl = `${config.apiServer}/eagle-eye/${id}/receiver`;
  return async (dispatch) => {
    dispatch({ type: 'EAGLE_EYE_RECEIVER_LOAD_START' });
    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'EAGLE_EYE_RECEIVER_LOAD_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'EAGLE_EYE_RECEIVER_LOAD_FAILED',
        payload:
          response.body.msg ||
          (response.code === 500
            ? 'errors.maintenance'
            : 'errors.noValidRequest')
      });
    }
  };
}
