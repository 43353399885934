/** @format */

import React from 'react';
import { translate } from 'utils/language';

const ScrollToTop = () => {
  return (
    <div className='scroll-to-top' onClick={() => window.scrollTo(0, 0)}>
      <span className='fa fa-arrow-circle-o-up icon' />
      <p>{translate('go.to.top')}</p>
    </div>
  );
};

export default ScrollToTop;
