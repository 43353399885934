/** @format */

import React from 'react';

class Logo extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.logoPath !== this.props.logoPath;
  }

  render() {
    const { logoPath } = this.props;
    return (
      <div
        className='site-logo'
        style={{
          backgroundImage: `url(${logoPath})`
        }}
      />
    );
  }
}

export default Logo;
