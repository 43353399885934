/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';

export default function getShares({ limit, offset, sortedBy, sortOrder }) {
  return async (dispatch) => {
    dispatch({ type: 'SHARES_LOAD_START' });

    const requestUrl = `${config.apiServer}/shares/list`;
    let response;
    try {
      response = await request.get(requestUrl, {
        query: {
          limit,
          offset,
          orderBy: sortedBy,
          ascending: sortOrder === 'ascending'
        }
      });
    } catch (errorResponse) {
      return dispatch({
        type: 'SHARES_LOAD_FAILED',
        payload: errorResponse.body.msg || 'errors.maintenance'
      });
    }

    const total = response.body.total;
    const newOffset = Math.min(offset, parseInt(total, 10));
    const newPage = Math.floor(newOffset / limit) + 1;

    dispatch({
      type: 'SHARES_LOAD_DONE',
      payload: {
        total,
        offset: newOffset,
        page: newPage,
        shares: response.body.shares
      }
    });
  };
}
