/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  value: null
});

export default function deleteUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'OPEN_FIRMWARE_INTERVAL_MODAL': {
      return state.set('isShowing', true).set('value', action.payload.value);
    }
    case 'HIDE_FIRMWARE_INTERVAL_MODAL': {
      return state.set('isShowing', false).set('value', null);
    }
    default: {
      return state;
    }
  }
}
