/** @format */

import request from '@edgeguideab/client-request';
import languageActions from 'actions/language';
import history from 'utils/history';
import urls from 'utils/urls';
import loginRedirect from './loginRedirect';
import config from 'config.json';
import { rememberLogin } from 'utils/login';

export default function doLogin({ location }) {
  return async (dispatch, getState) => {
    const state = getState();
    const username = state.session.getIn(['loginForm', 'username']);
    const password = state.session.getIn(['loginForm', 'password']);
    const remember = state.session.getIn(['loginForm', 'remember']);
    let validCredentials = true;
    if (!username) {
      validCredentials = false;
      dispatch({
        type: 'SESSION_LOGIN_MISSING_USERNAME',
        payload: {
          msg: 'validation.login.required.email'
        }
      });
    }
    if (!password) {
      validCredentials = false;
      dispatch({
        type: 'SESSION_LOGIN_MISSING_PASSWORD',
        payload: {
          msg: 'validation.login.required.password'
        }
      });
    }
    if (!validCredentials) {
      return;
    }
    dispatch({
      type: 'SESSION_LOGIN_STARTED'
    });
    let response;
    try {
      response = await request.post(`${config.apiServer}/sessions/login`, {
        body: { username, password, remember }
      });
    } catch (errorResponse) {
      if (
        errorResponse.status === 403 &&
        errorResponse.body.redirectUrl === urls.organizationSuspended
      ) {
        history.push(urls.organizationSuspended);
      }

      window.localStorage.removeItem('encryptedUsername');
      window.localStorage.removeItem('encryptedPassword');
      return dispatch({
        type: 'SESSION_LOGIN_FAILED',
        payload: {
          msg: errorResponse.body.msg || 'errors.maintenance',
          resetCaptcha: errorResponse.body.resetCaptcha
        }
      });
    }

    if (response.body.newPolicyToAccept) {
      return dispatch({
        type: 'SHOW_POLICY_MODAL',
        payload: {
          policy: response.body.newPolicyToAccept,
          userId: response.body.userId
        }
      });
    }

    if (remember && config.showRememberMeBox) {
      window.localStorage.setItem(
        'encryptedUsername',
        response.body.encryptedUsername
      );
      window.localStorage.setItem(
        'encryptedPassword',
        response.body.encryptedPassword
      );
      rememberLogin({
        username: response.body.encryptedUsername,
        password: response.body.encryptedPassword
      });
    }

    dispatch({
      type: 'SESSION_LOGIN_DONE',
      payload: {
        msg: response.body.msg,
        user: response.body.user,
        loggedIn: !response.body['2FA'],
        products: response.body.products,
        supportedEeprom: response.body.supportedEeprom
      }
    });
    const hasStartPage = state.global.get('ewmFeatures').includes('startPage');
    if (!response.body['2FA']) {
      window.localStorage.setItem('csrfToken', response.body.csrfToken);
      await dispatch(
        languageActions.changeLanguage(response.body.user.lastLanguage)
      );
      let path = null;
      if (location) path = location.pathname;
      loginRedirect({
        hasStartPageAccess: response.body.user.acl.startPage.read.length > 0,
        hasStartPage,
        path
      });
      dispatch({ type: 'RESET_LOCATION' });
    } else {
      dispatch({ type: 'SESSION_TWO_FACTOR_VIEW' });
      history.push(urls.loginOTC);
    }
  };
}
