/** @format */

export const getDefaultEndpoints = () => [
  {
    id: 0,
    address: 'ping1.dualtech.se',
    type: 'ping',
    position: 1
  },
  {
    id: 1,
    address: 'ping2.dualtech.se',
    type: 'ping',
    position: 2
  },
  {
    id: 2,
    address: '8.8.8.8',
    type: 'dns',
    position: 3
  }
];
