/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  form: Map()
});

export default function eagleEyeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_EAGLE_EYE_MODAL': {
      return state.set('isShowing', true);
    }
    case 'HIDE_EAGLE_EYE_MODAL': {
      return INITIAL_STATE;
    }
    case 'EAGLE_EYE_MODAL_INPUT_CHANGE': {
      if (action.payload.name === 'activated') {
        return state.setIn(['form', 'eagleEye', 'activated'], true);
      } else if (action.payload.name === 'notActivated') {
        return state.setIn(['form', 'eagleEye', 'activated'], false);
      }
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
