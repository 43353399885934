/** @format */

import isIp from 'is-ip';
import { List, Iterable } from 'immutable';
import { hasCollidingIpConfiguration } from '../utils';
import validateReservation from './dhcpAddressReservation';

export default function validateLan(
  { lan, ipv4, lans, product },
  productObj,
  configuration,
  services
) {
  let pathOptions = undefined;
  if (productObj && configuration && services) {
    if (Iterable.isIterable(productObj)) productObj = productObj.toJS();
    if (Iterable.isIterable(configuration))
      configuration = configuration.toJS();
    if (Iterable.isIterable(services)) services = services.toJS();

    const productFeatures = productObj.features || List();
    const wanActive =
      !configuration.ipv4.networkType.includes('disabled') ||
      !configuration.ipv6.type.includes('none');

    const internetAccessService = services.find(
      (service) => service.name === 'internetAccess'
    );
    const hasWanActive =
      wanActive && (!internetAccessService || internetAccessService.activated);
    const mobileDataService = services.find(
      (service) => service.name === 'mobile'
    );
    const currentBundle = configuration.mobileData.data;
    const availableDataPlans = configuration.mobileData.dataPlans;
    const currentDataPlan =
      availableDataPlans &&
      availableDataPlans.find((plan) => plan.bundle === currentBundle);
    const hasWwanActive =
      !productFeatures.includes('requireMobileDataService') ||
      (mobileDataService &&
        mobileDataService.activated &&
        currentDataPlan &&
        !currentDataPlan.bundledServices &&
        (!internetAccessService || internetAccessService.activated));
    pathOptions = !hasWanActive
      ? hasWwanActive
        ? ['wwan', 'none']
        : ['none']
      : hasWwanActive
      ? ['auto', 'wwan', 'wan', 'none']
      : ['wan', 'none'];
  }

  const lanCollision =
    isIp(lan.ipAddress) &&
    hasCollidingIpConfiguration({
      ipAddress: lan.ipAddress,
      lan,
      lans
    });
  const wanCollision =
    ipv4.networkType &&
    ipv4.networkType.includes('manual') &&
    isIp(ipv4.ipAddress) &&
    hasCollidingIpConfiguration({
      ipAddress: ipv4.ipAddress,
      lan: { netmask: ipv4.netmask },
      lans,
      global: true
    });
  return {
    type: 'object',
    keys: {
      ports: {
        type: 'array',
        condition: (ports) => ports.length || product === 'nena',
        errorCode: 'validation.required.ports'
      },
      ipAddress: {
        type: 'string',
        condition: (ipAddress) =>
          isIp(ipAddress) && !(lanCollision || wanCollision),
        errorCode:
          lanCollision || wanCollision
            ? 'validation.lan.ipAddress.colliding'
            : 'validation.lan.ipAddress.notselected'
      },
      prefix: {
        type: 'number',
        parse: true,
        errorCode: 'validation.required.prefix'
      },
      label: {
        type: 'string',
        errorCode: 'validation.required.label'
      },
      path: {
        type: 'string',
        condition: (path) => !pathOptions || pathOptions.includes(path),
        errorCode: 'validation.required.internet.path',
        allowNull: false
      },
      start: {
        type: 'number',
        parse: true,
        allowNull: !lan.dhcpServer,
        errorCode: 'validation.required.start'
      },
      end: {
        type: 'number',
        parse: true,
        allowNull: !lan.dhcpServer,
        errorCode: 'validation.required.end'
      },
      dns: {
        type: 'string',
        allowNull: true,
        condition: (dns) => dns.split(',').every((address) => isIp(address)),
        errorCode: 'validation.required.dns'
      },
      dhcpAddressReservations: {
        type: 'array',
        items: () =>
          validateReservation({
            lan
          })
      }
    }
  };
}
