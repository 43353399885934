/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  objectIdentity: '',
  inputResetText: '',
  emailSenderAddress: '',
  smtpUserName: '',
  smtpPassword: '',
  smtpPasswordRepeat: ''
});

export default function receiverSettingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'receiverSettings') {
        return state.set(action.payload.name, action.payload.value);
      } else if (
        action.payload.name === 'productType' &&
        action.payload.value === '4G'
      ) {
        return state
          .set('emailSenderAddress', '')
          .set('smtpUserName', '')
          .set('smtpPassword', '')
          .set('smtpPasswordRepeat', '');
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
