/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  data: null,
  type: null,
  showModal: false,
  showWarningModal: false,
  showDeleteModal: false,
  loading: false,
  done: false,
  error: ''
});

export default function mutationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_MUTATION_DONE': {
      const mutation = action.payload.body.mutation;
      return state
        .set('type', mutation && mutation.type)
        .set('data', fromJS(mutation));
    }
    case 'MUTATION_SHOW_SELECT_MODAL': {
      return state
        .set('showModal', true)
        .set('loading', false)
        .set('done', false);
    }
    case 'MUTATION_HIDE_SELECT_MODAL': {
      return state
        .set('showModal', false)
        .set('loading', false)
        .set('done', false);
    }
    case 'MUTATION_SHOW_WARNING_MODAL': {
      return state.set('showWarningModal', true);
    }
    case 'MUTATION_HIDE_WARNING_MODAL': {
      return state.set('showWarningModal', false);
    }
    case 'MUTATION_SHOW_DELETE_MODAL': {
      return state
        .set('showDeleteModal', true)
        .set('loading', false)
        .set('done', false)
        .set('error', '');
    }
    case 'MUTATION_HIDE_DELETE_MODAL': {
      return state
        .set('showDeleteModal', false)
        .set('loading', false)
        .set('done', false)
        .set('error', '');
    }
    case 'ACTIVATE_MUTATION_START': {
      return state.set('loading', true).set('done', false);
    }
    case 'ACTIVATE_MUTATION_FAILED': {
      return state.set('loading', false).set('done', false);
    }
    case 'ACTIVATE_MUTATION_DONE': {
      return state.set('loading', false).set('done', true);
    }
    case 'DELETE_MUTATION_START': {
      return state.set('loading', true).set('done', false).set('error', '');
    }
    case 'DELETE_MUTATION_FAILED': {
      return state
        .set('loading', false)
        .set('done', false)
        .set('error', action.payload);
    }
    case 'DELETE_MUTATION_DONE': {
      return state.set('loading', false).set('done', true).set('error', '');
    }
    case 'EDIT_INSTALLER_CHANGES': {
      let { name } = action.payload;
      const { value } = action.payload;
      let position;
      if (name.includes('inputs')) {
        position = name.substring(6);
        name = name.substring(0, 6);
      } else if (name.includes('outputs')) {
        position = name.substring(7);
        name = name.substring(0, 7);
      } else if (name.includes('relays')) {
        position = name.substring(6);
        name = name.substring(0, 6);
      }
      if (position) {
        const index = state
          .getIn(['data', 'installerChanges', name])
          .findIndex(
            (change) => change.get('position') === parseInt(position, 10)
          );
        return state.setIn(
          ['data', 'installerChanges', name, index, 'keep'],
          value
        );
      } else {
        return state.setIn(['data', 'installerChanges', name, 'keep'], value);
      }
    }
    default: {
      return state;
    }
  }
}
