/** @format */

import urls from 'utils/urls';
import history from 'utils/history';

export default function loginRedirect({
  hasStartPageAccess,
  hasStartPage,
  path
}) {
  let url = urls.configurationsList;
  if (hasStartPage) {
    url = hasStartPageAccess ? urls.startPage : urls.eagleEyeList;
  }
  if (path) {
    url = path;
  }
  history.push(url);
}
