/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  form: Map()
});

export default function createConfigurationStep2Reducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_CREATE_CONFIGURATION_STEP_2_MODAL': {
      return state.set('isShowing', true);
    }
    case 'HIDE_CREATE_CONFIGURATION_STEP_2_MODAL': {
      return INITIAL_STATE;
    }
    case 'CREATE_CONFIGURATION_STEP_2_MODAL_INPUT_CHANGE': {
      if (action.payload.name === 'productType') {
        return state.setIn(['form', action.payload.name], action.payload.value);
      }
      if (action.payload.name === 'activated') {
        return state.setIn(['form', 'eagleEye', 'activated'], true);
      } else if (action.payload.name === 'notActivated') {
        return state.setIn(['form', 'eagleEye', 'activated'], false);
      }
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
