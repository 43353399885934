/** @format */

export default function validateOutput(output) {
  if (!!output.optionalFunctionality) {
    return {
      type: 'object'
    };
  }
  return {
    type: 'object',
    keys: {
      position: {
        type: 'number',
        parse: true,
        condition: (position) => Number(position),
        errorCode: 'validation.input.position'
      },
      label: {
        type: 'string',
        errorCode: 'validation.required.label'
      },
      outputType: {
        type: 'string',
        errorCode: 'validation.required.type'
      },
      outputFunction: {
        type: 'string',
        errorCode: 'validation.output.function.notselected'
      },
      pulseLength: {
        type: 'number',
        allowNull: output.outputFunction !== 'outputcontrol.function.pulse',
        condition: (pulseLength) =>
          output.outputFunction !== 'outputcontrol.function.pulse' ||
          pulseLength > 0,
        errorCode: 'validation.pulselength.notselected'
      },
      activeText: {
        type: 'string',
        errorCode: 'validation.required.active.text'
      },
      inactiveText: {
        type: 'string',
        allowNull: output.outputFunction !== 'outputcontrol.function.static',
        errorCode: 'validation.required.inactive.text'
      },
      inputFeedback: {
        type: 'number',
        parse: true,
        allowNull: !output.isArm,
        errorCode: 'validation.required.input.feedback'
      }
    }
  };
}
