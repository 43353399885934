/** @format */

export default function validateIpProtocolsDc09(protocol, ipProtocolsDc09s) {
  const otherProtocolsOnSameLan = ipProtocolsDc09s.filter(
    (p) =>
      p.position !== protocol.position && p.lanPosition === protocol.lanPosition
  );
  const collidingProtocol = otherProtocolsOnSameLan.some(
    (p) =>
      p.networkProtocol === protocol.networkProtocol && p.port === protocol.port
  );
  return {
    type: 'object',
    keys: {
      lanPosition: {
        type: 'number',
        parse: true,
        allowNull: false,
        condition: () => !collidingProtocol,
        errorCode: collidingProtocol
          ? 'validation.ip.protocol.colliding.protocols'
          : 'validation.ip.protocol.lan.invalid'
      },
      port: {
        type: 'number',
        parse: true,
        allowNull: false,
        errorCode: 'validation.ip.protocol.port.invalid'
      },
      networkProtocol: {
        type: 'string',
        allowNull: false,
        errorCode: 'validation.ip.protocol.network.protocol.invalid'
      },
      supervisionInterval: {
        type: 'number',
        parse: true,
        allowNull: !protocol.supervision,
        errorCode: 'validation.ip.protocol.supervision.invalid'
      },
      function: {
        type: 'string',
        allowNull: false,
        errorCode: 'validation.ip.protocol.function.invalid'
      }
    }
  };
}
