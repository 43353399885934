/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  users: [],
  searchQuery: '',
  isFetching: false,
  pagination: { users: { total: 0, page: 0, start: 0 } },
  sortedBy: 'fullname',
  sortOrder: 'ascending',
  error: ''
});

const sessions = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case 'USER_DELETE_DONE': {
      const deletedId = action.payload.id;
      return state
        .set(
          'users',
          state.get('users').filter((user) => user.get('id') !== deletedId)
        )
        .setIn(
          ['pagination', 'users', 'total'],
          state.getIn(['pagination', 'users', 'total']) - 1
        );
    }
    case 'USER_LIST_SEARCH_INPUT_CHANGE': {
      return state.set('searchQuery', action.payload);
    }
    case 'LOAD_USERS_STARTED': {
      return state.set('isFetching', true).set('error', '');
    }
    case 'LOAD_USERS_DONE': {
      let users = fromJS(action.payload.users);
      const organization = action.payload.organization;
      users = action.payload.append ? state.get('users').push(...users) : users;
      return state
        .set('users', users)
        .set('organization', fromJS(organization))
        .set('isFetching', false)
        .setIn(['pagination', 'users', 'total'], action.payload.total)
        .setIn(['pagination', 'users', 'page'], action.payload.page)
        .setIn(['pagination', 'users', 'start'], action.payload.start);
    }
    case 'LOAD_USERS_FAILED': {
      return state.set('error', action.payload);
    }
    case 'ADMIN_ACTIVATE_USER_DONE': {
      return state.set(
        'users',
        state.get('users').map((user) => {
          if (user.get('id') === action.payload) {
            return user.set('isConfirmed', true);
          }
          return user;
        })
      );
    }
    case 'USER_LIST_SORT': {
      const column = action.payload.column;
      const order = action.payload.order;
      return state.set('sortedBy', column).set('sortOrder', order);
    }
    case 'CLEAR_SEARCH': {
      return state.set('searchQuery', '');
    }
    case 'USER_LIST_RESET_STATE':
      return INITIAL_STATE.set('searchQuery', state.get('searchQuery'));
    case 'SESSION_LOGOUT_DONE':
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

export default sessions;
