/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadAdditionalServices(configurationId) {
  const requestUrl = `${config.apiServer}/configurations/${configurationId}/additional-services`;

  return async (dispatch) => {
    dispatch({ type: 'LOAD_ADDITIONAL_SERVICES_START' });
    try {
      const response = await request.get(requestUrl);
      dispatch({
        type: 'LOAD_ADDITIONAL_SERVICES_DONE',
        payload: response.body
      });
    } catch (response) {
      return dispatch({
        type: 'LOAD_ADDITIONAL_SERVICES_FAILED',
        payload: response.body.msg || 'errors.maintenance'
      });
    }
  };
}
