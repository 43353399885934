/** @format */

import { fromJS } from 'immutable';
import program from './program';
import commission from './commission';
import getDalmLog from './getDalmLog';
import getDalmDebugLog from './getDalmDebugLog';
import resetHardware from './resetHardware';
import getDalmStatus from './getDalmStatus';
import rebootDalm from './rebootDalm';
import firmwareUpgrade from './firmwareUpgrade';
import kernelUpgrade from './kernelUpgrade';
import testMode from './testMode';
import alarmTest from './alarmTest';
import mobileModemFirmwareUpgrade from './mobileModemFirmwareUpgrade';
import activateSubscription from './activateSubscription';

const INITIAL_STATE = fromJS({
  program: program(undefined, { type: '' }),
  commission: commission(undefined, { type: '' }),
  getDalmLog: getDalmLog(undefined, { type: '' }),
  getDalmDebugLog: getDalmDebugLog(undefined, { type: '' }),
  resetHardware: resetHardware(undefined, { type: '' }),
  getDalmStatus: getDalmStatus(undefined, { type: '' }),
  rebootDalm: rebootDalm(undefined, { type: '' }),
  firmwareUpgrade: firmwareUpgrade(undefined, { type: '' }),
  kernelUpgrade: kernelUpgrade(undefined, { type: '' }),
  mobileModemFirmwareUpgrade: mobileModemFirmwareUpgrade(undefined, {
    type: ''
  }),
  testMode: testMode(undefined, { type: '' }),
  alarmTest: alarmTest(undefined, { type: '' }),
  activateSubscription: activateSubscription(undefined, { type: '' })
});

export default function configurationOperationsReducer(
  state = INITIAL_STATE,
  action
) {
  state = state.set('program', program(state.get('program'), action));
  state = state.set('commission', commission(state.get('commission'), action));
  state = state.set('getDalmLog', getDalmLog(state.get('getDalmLog'), action));
  state = state.set(
    'getDalmDebugLog',
    getDalmDebugLog(state.get('getDalmDebugLog'), action)
  );
  state = state.set(
    'resetHardware',
    resetHardware(state.get('resetHardware'), action)
  );
  state = state.set(
    'getDalmStatus',
    getDalmStatus(state.get('getDalmStatus'), action)
  );
  state = state.set('rebootDalm', rebootDalm(state.get('rebootDalm'), action));
  state = state.set(
    'firmwareUpgrade',
    firmwareUpgrade(state.get('firmwareUpgrade'), action)
  );
  state = state.set(
    'kernelUpgrade',
    kernelUpgrade(state.get('kernelUpgrade'), action)
  );
  state = state.set('testMode', testMode(state.get('testMode'), action));
  state = state.set('alarmTest', alarmTest(state.get('alarmTest'), action));
  state = state.set(
    'mobileModemFirmwareUpgrade',
    mobileModemFirmwareUpgrade(state.get('mobileModemFirmwareUpgrade'), action)
  );
  state = state.set(
    'activateSubscription',
    activateSubscription(state.get('activateSubscription'), action)
  );
  return state;
}
