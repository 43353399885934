/** @format */

import { fromJS } from 'immutable';

import {
  DOWNLOAD_REG_SCHEMA_START,
  DOWNLOAD_REG_SCHEMA_SUCCESS,
  DOWNLOAD_REG_SCHEMA_FAILURE
} from 'actions/configuration/downloadRegSchema/actionTypes';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isDownloading: false,
  error: null
});

export default function downloadRegSchemaReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case DOWNLOAD_REG_SCHEMA_START: {
      return INITIAL_STATE.set('isShowing', true).set('isDownloading', true);
    }
    case DOWNLOAD_REG_SCHEMA_FAILURE: {
      return state
        .set('isDownloading', false)
        .set('error', action.payload.error);
    }
    case DOWNLOAD_REG_SCHEMA_SUCCESS:
    case 'HIDE_DOWNLOAD_REG_SCHEMA_MODAL': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
