/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  loading: false,
  error: null,
  list: []
});

export default function blockedEmailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LIST_BLOCKED_EMAILS_STARTED': {
      return state.set('show', true).set('loading', true);
    }
    case 'HIDE_BLOCKED_EMAILS_MODAL': {
      return INITIAL_STATE;
    }
    case 'LIST_BLOCKED_EMAILS_DONE': {
      return state.set('loading', false).set('list', fromJS(action.payload));
    }
    case 'LIST_BLOCKED_EMAILS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    default: {
      return state;
    }
  }
}
