/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadSubscriptionEventLog({ configurationId }) {
  return async (dispatch) => {
    if (configurationId === null || !isFinite(configurationId)) {
      return dispatch({
        type: 'LOAD_SUBSCRIPTION_EVENT_LOG_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    dispatch({
      type: 'LOAD_SUBSCRIPTION_EVENT_LOG_STARTED',
      payload: configurationId
    });

    const url = `${config.apiServer}/subscriptions/${configurationId}/event-log`;

    try {
      const response = await request.get(url);
      dispatch({
        type: 'LOAD_SUBSCRIPTION_EVENT_LOG_DONE',
        payload: { ...response.body }
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_SUBSCRIPTION_EVENT_LOG_FAILED',
        payload: response.body
      });
    }
  };
}
