/** @format */

import isIp from 'is-ip';

export default function validateIpv6({ ipv6 }) {
  return {
    type: 'object',
    allowNull: true,
    keys:
      ipv6.type === 'static'
        ? {
            type: 'string',
            ipv6Address: {
              type: 'string',
              allowNull: !ipv6 || ipv6.type !== 'static',
              condition: (ipAddress) => isIp.v6(ipAddress),
              errorCode: 'validation.ipv6.address'
            },
            defaultGateway: {
              type: 'string',
              errorCode: 'validation.ipv6.defaultGateway'
            },
            dns: {
              type: 'string',
              errorCode: 'validation.ipv6.dns'
            }
          }
        : {}
  };
}
