/** @format */

import { fromJS, Map } from 'immutable';
import { formatReceiver } from 'actions/configuration/utils';

const INITIAL_PATH_SETTINGS = {
  primaryReceiverIp: '',
  primaryReceiverPort: '',
  secondaryReceiverIp: '',
  secondaryReceiverPort: '',
  primaryethernetReceiverIp: '',
  primaryethernetReceiverPort: '',
  primarymobileReceiverIp: '',
  primarymobileReceiverPort: '',
  secondaryethernetReceiverIp: '',
  secondaryethernetReceiverPort: '',
  secondarymobileReceiverIp: '',
  secondarymobileReceiverPort: ''
};

const INITIAL_ALARM_CENTER_JSON = {
  name: '',
  pollingInterval: 'select',
  pollingScheme: 'standard',
  lockedPollingScheme: false,
  samePathSettings: true,
  ...INITIAL_PATH_SETTINGS,
  hasDc09: false,
  dc09: {},
  v4: {},
  encrypted: true,
  encryptedOptional: true,
  showExtendedBody: false,
  position: 1,
  copiedFromPredefinedAlarmCenter: ''
};

const INITIAL_STATE = fromJS([]);

export default function alarmCentersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_CONFIGURATION_DONE': {
      if (action.payload.alarmCenters.length === 0) {
        return INITIAL_STATE;
      }

      const maxPosition = action.payload.alarmCenters.reduce(
        (max, { position }) => (position > max ? position : max),
        0
      );

      return fromJS(
        Array(maxPosition)
          .fill()
          .map((_, index) =>
            action.payload.alarmCenters.find((ac) => ac.position === index + 1)
          )
      );
    }
    case 'ADD_PREDEFINED_ALARM_CENTERS': {
      return changeAlarmCenter(state, action);
    }
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type !== undefined
      ) {
        const name = action.payload.name;
        if (name.includes('v4.') || name.includes('dc09.')) {
          const layers = name.split('.');
          return state.setIn(
            [action.payload.index, layers[0], layers[1]],
            action.payload.value
          );
        } else if (action.payload.type === 'alarmCenters') {
          let value = action.payload.value;
          if (name.includes('Receiver')) {
            value = value.replace(/\s/g, '');
          }
          if (name === 'communicationPath') {
            return state
              .setIn([action.payload.index, name], value)
              .setIn([action.payload.index, 'pollingInterval'], 'select');
          }
          return state.setIn([action.payload.index, name], value);
        }
      } else if (
        action.payload.name === 'networkType' ||
        (action.payload.name === 'type' && action.payload.type === 'ipv6')
      ) {
        const oldValue =
          action.payload.params[
            action.payload.name === 'networkType' ? 'ipv4Type' : 'ipv6Type'
          ];

        const otherVerisonValue =
          action.payload.params[
            action.payload.name === 'networkType' ? 'ipv6Type' : 'ipv4Type'
          ];

        const isDisabling =
          action.payload.value.includes('disabled') ||
          action.payload.value === 'none';

        const wasDisabled =
          oldValue.includes('disabled') || oldValue === 'none';

        const otherVersionDisabled =
          otherVerisonValue.includes('disabled') ||
          otherVerisonValue === 'none';

        const alarmCenters = state;
        if (isDisabling && otherVersionDisabled) {
          alarmCenters.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              state = state.setIn([index, 'communicationPath'], 'mobile');
              if (action.payload.product === 'dalmLte') {
                state = state.setIn([index, 'pollingInterval'], 'select');
              }
            }
          });
        } else if (wasDisabled && otherVersionDisabled) {
          alarmCenters.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              state = state.setIn([index, 'communicationPath'], 'all');
              if (action.payload.product === 'dalmLte') {
                state = state.setIn([index, 'pollingInterval'], 'select');
              }
            }
          });
        }
      } else if (action.payload.name === 'gprsDisabled') {
        const value = action.payload.value;
        const alarmCenters = state;
        if (value.includes('disabled')) {
          alarmCenters.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              state = state
                .setIn([index, 'communicationPath'], 'ethernet')
                .setIn([index, 'pollingInterval'], 'select');
            }
          });
        } else {
          alarmCenters.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              state = state
                .setIn([index, 'communicationPath'], 'all')
                .setIn([index, 'pollingInterval'], 'select');
            }
          });
        }
      } else if (action.payload.name === 'productType') {
        const alarmCenters = state;
        alarmCenters.forEach((alarmCenter, index) => {
          if (alarmCenter) {
            state = state
              .setIn([index, 'pollingInterval'], 'select')
              .setIn([index, 'pollingScheme'], 'standard')
              .setIn([index, 'alarmClass'], null);
          }
        });
      }
      return state;
    }
    case 'CONFIGURATION_EDIT_CHANGE_ALARM_CENTER': {
      return changeAlarmCenter(state, action);
    }
    case 'CONFIGURATION_EDIT_TOGGLE_ALARM_CENTER_INPUT': {
      const oldValue = state.getIn([action.payload.index, action.payload.name]);
      if (action.payload.name === 'hasDc09') {
        let alarmCenterTwo = state.get(1);
        let alarmCenterThree = state.get(2);
        if (
          alarmCenterTwo &&
          alarmCenterTwo.get('copiedFromPredefinedAlarmCenter') === 'local'
        ) {
          alarmCenterTwo = fromJS(INITIAL_ALARM_CENTER_JSON);
          alarmCenterTwo.set('position', 2);
        }
        if (
          alarmCenterThree &&
          alarmCenterThree.get('copiedFromPredefinedAlarmCenter') === 'local'
        ) {
          alarmCenterThree = fromJS(INITIAL_ALARM_CENTER_JSON);
          alarmCenterThree.set('position', 2);
        }
        if (oldValue) {
          state.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              if (state.getIn([index, 'dc09'])) {
                state = state.setIn([index, 'dc09'], new Map());
                state = state.setIn([index, 'encrypted'], true);
                state = state.setIn([index, 'pollingScheme'], 'standard');
                state = state.setIn([index, 'lockedPollingScheme'], false);
              }
            }
          });
        } else {
          state.forEach((alarmCenter, index) => {
            if (alarmCenter) {
              if (state.getIn([index, 'v4'])) {
                state = state.setIn([index, 'v4'], new Map());
                state = state.setIn([index, 'encrypted'], false);
              }
            }
          });
        }
        return state
          .setIn([0, 'hasDc09'], !oldValue)
          .set(1, alarmCenterTwo)
          .set(2, alarmCenterThree);
      }
      return state.setIn(
        [action.payload.index, action.payload.name],
        !oldValue
      );
    }
    case 'EDIT_AUTHENTICATION_CODE_DONE': {
      const alarmCenter = state.find(
        (arc) => arc.get('id') === action.payload.alarmCenterId
      );
      if (!alarmCenter) {
        return state;
      }
      return state.setIn(
        [alarmCenter.get('position') - 1, 'v4', 'authenticationCode'],
        action.payload.newAuthenticationCode
      );
    }
    default: {
      return state;
    }
  }
}

function changeAlarmCenter(state, action) {
  const predefinedAlarmCenter = action.payload.alarmCenter;
  const index = action.payload.index;
  const hasEthernet = !action.payload.networkType.includes('disabled');
  const hasMobile = !action.payload.gprsDisabled.includes('disabled');
  const communicationPath =
    hasEthernet && hasMobile ? 'all' : hasMobile ? 'mobile' : 'ethernet';

  const id = state.getIn([index, 'id']);
  if (predefinedAlarmCenter === 'other' || predefinedAlarmCenter === 'local') {
    return state
      .set(index, fromJS(INITIAL_ALARM_CENTER_JSON))
      .setIn([index, 'encryptedOptional'], true)
      .setIn([index, 'copiedFromPredefinedAlarmCenter'], predefinedAlarmCenter)
      .setIn(
        [index, 'hasDc09'],
        predefinedAlarmCenter === 'other' ? false : null
      )
      .setIn(
        [index, 'position'],
        predefinedAlarmCenter === 'local' ? 3 : index + 1
      )
      .setIn([index, 'id'], id)
      .setIn([index, 'communicationPath'], communicationPath)
      .setIn(
        [index, 'pollingInterval'],
        state.getIn([index, 'pollingInterval'], 'select')
      );
  } else if (!predefinedAlarmCenter) {
    return state
      .set(index, fromJS(INITIAL_ALARM_CENTER_JSON))
      .setIn([index, 'id'], id)
      .setIn([index, 'communicationPath'], communicationPath)
      .setIn(
        [index, 'pollingInterval'],
        state.getIn([index, 'pollingInterval'], 'select')
      );
  }

  const primaryReceiver = predefinedAlarmCenter.getIn(
    ['encrypted', 'primaryReceiver'],
    predefinedAlarmCenter.getIn(['unEncrypted', 'primaryReceiver'], '')
  );

  const secondaryReceiver = predefinedAlarmCenter.getIn(
    ['encrypted', 'secondaryReceiver'],
    predefinedAlarmCenter.getIn(['unEncrypted', 'secondaryReceiver'], '')
  );

  const primaryIps = primaryReceiver
    ? formatReceiver(primaryReceiver, true)
    : { ip: '', port: '' };

  const secondaryIps = secondaryReceiver
    ? formatReceiver(secondaryReceiver)
    : { ip: '', port: '' };
  const samePathSettings =
    !primaryIps.primaryethernetReceiverIp ||
    !primaryIps.primarymobileReceiverIp;
  let alarmCenterTwo = state.get(1);
  let alarmCenterThree = state.get(2);
  if (alarmCenterTwo) {
    if (
      predefinedAlarmCenter.get('dc09') &&
      alarmCenterTwo.get('copiedFromPredefinedAlarmCenter') === 'local'
    ) {
      alarmCenterTwo = fromJS(INITIAL_ALARM_CENTER_JSON);
      alarmCenterTwo.set('position', 2);
      alarmCenterTwo.set('communicationPath', communicationPath);
    }
  } else {
    alarmCenterTwo = fromJS(INITIAL_ALARM_CENTER_JSON);
    alarmCenterTwo.set('position', 2);
    alarmCenterTwo.set('communicationPath', communicationPath);
  }
  if (alarmCenterThree) {
    if (
      predefinedAlarmCenter.get('dc09') &&
      alarmCenterThree.get('copiedFromPredefinedAlarmCenter') === 'local'
    ) {
      alarmCenterThree = fromJS(INITIAL_ALARM_CENTER_JSON);
      alarmCenterThree.set('position', 3);
      alarmCenterThree.set('communicationPath', communicationPath);
    }
  } else {
    alarmCenterThree = fromJS(INITIAL_ALARM_CENTER_JSON);
    alarmCenterThree.set('position', 3);
    alarmCenterThree.set('communicationPath', communicationPath);
  }
  const hadDc09Before = state.getIn([0, 'hasDc09']);
  if (!hadDc09Before && predefinedAlarmCenter.get('dc09')) {
    state.forEach((alarmCenter, i) => {
      if (alarmCenter) {
        state = state.setIn([i, 'v4'], Map());
      }
    });
  } else if (hadDc09Before && !predefinedAlarmCenter.get('dc09')) {
    state.forEach((alarmCenter, i) => {
      if (alarmCenter) {
        state = state
          .setIn([i, 'dc09'], Map())
          .setIn([i, 'pollingScheme'], 'standard')
          .setIn([i, 'lockedPollingScheme'], false);
      }
    });
  }
  if (predefinedAlarmCenter.get('dc09')) {
    state = state
      .setIn(
        [index, 'dc09', 'eagleEyeCms'],
        predefinedAlarmCenter.get('eagleEyeCms')
      )
      .setIn([index, 'dc09', 'prefix'], predefinedAlarmCenter.get('prefix'));
  }
  if (!state.get(index)) {
    state = state.set(index, fromJS({}));
  }
  return state
    .set(1, alarmCenterTwo)
    .set(2, alarmCenterThree)
    .setIn([index, 'name'], predefinedAlarmCenter.get('name'))
    .setIn(
      [index, 'copiedFromPredefinedAlarmCenter'],
      predefinedAlarmCenter.get('name')
    )
    .setIn([index, 'primaryReceiverIp'], fromJS(primaryIps.primaryReceiverIp))
    .setIn(
      [index, 'secondaryReceiverIp'],
      fromJS(secondaryIps.secondaryReceiverIp)
    )
    .setIn(
      [index, 'primaryReceiverPort'],
      fromJS(primaryIps.primaryReceiverPort)
    )
    .setIn(
      [index, 'secondaryReceiverPort'],
      fromJS(secondaryIps.secondaryReceiverPort)
    )
    .setIn(
      [index, 'primaryethernetReceiverIp'],
      fromJS(primaryIps.primaryethernetReceiverIp)
    )
    .setIn(
      [index, 'secondaryethernetReceiverIp'],
      fromJS(secondaryIps.secondaryethernetReceiverIp)
    )
    .setIn(
      [index, 'primaryethernetReceiverPort'],
      fromJS(primaryIps.primaryethernetReceiverPort)
    )
    .setIn(
      [index, 'secondaryethernetReceiverPort'],
      fromJS(secondaryIps.secondaryethernetReceiverPort)
    )
    .setIn(
      [index, 'primarymobileReceiverIp'],
      fromJS(primaryIps.primarymobileReceiverIp)
    )
    .setIn(
      [index, 'secondarymobileReceiverIp'],
      fromJS(secondaryIps.secondarymobileReceiverIp)
    )
    .setIn(
      [index, 'primarymobileReceiverPort'],
      fromJS(primaryIps.primarymobileReceiverPort)
    )
    .setIn(
      [index, 'secondarymobileReceiverPort'],
      fromJS(secondaryIps.secondarymobileReceiverPort)
    )
    .setIn([index, 'samePathSettings'], samePathSettings)
    .setIn([index, 'encrypted'], predefinedAlarmCenter.get('encrypted'))
    .setIn(
      [index, 'encryptedOptional'],
      predefinedAlarmCenter.get('encrypted') &&
        predefinedAlarmCenter.get('unEncrypted')
    )
    .setIn([index, 'hasDc09'], predefinedAlarmCenter.get('dc09'))
    .setIn([index, 'position'], index + 1)
    .setIn([index, 'communicationPath'], communicationPath)
    .setIn(
      [index, 'pollingInterval'],
      state.getIn([index, 'pollingInterval'], 'select')
    )
    .setIn(
      [index, 'pollingScheme'],
      predefinedAlarmCenter.get('pollingScheme') || 'standard'
    )
    .setIn(
      [index, 'lockedPollingScheme'],
      !!predefinedAlarmCenter.get('pollingScheme')
    );
}
