/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false
});

export default function prefixHelpReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_PREFIX_HELP_MODAL': {
      return state.set('show', true);
    }
    case 'HIDE_PREFIX_HELP_MODAL': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
