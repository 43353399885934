/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  resetting: false,
  reset: false,
  error: ''
});

export default function resetHardwareReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_SECURITY_ROUTER_CONFIGURATION_DONE':
    case 'LOAD_CONFIGURATION_DONE':
      return INITIAL_STATE;
    case 'CONFIGURATION_RESET_HARDWARE_STARTED':
      return state.set('resetting', true).set('reset', false);
    case 'CONFIGURATION_RESET_HARDWARE_DONE':
      return state.set('resetting', false).set('reset', true);
    case 'CONFIGURATION_RESET_HARDWARE_FAILED':
      return state.set('resetting', false).set('error', action.payload);
    default:
      return state;
  }
}
