/** @format */

import { fromJS, List } from 'immutable';

const INITIAL_STATE = fromJS({
  list: [],
  sortedBy: 'createdAt',
  sortOrder: 'descending',
  loading: false,
  showDialog: false,
  expandedRow: null,
  isUploading: false,
  uploaded: false,
  uploadError: '',
  uploadingProgress: 0,
  errors: {
    comment: [],
    binary: [],
    softwareVersion: [],
    supportedProducts: []
  },
  uploadForm: {
    binary: null,
    comment: '',
    production: false,
    version: '',
    eepromVersion: '',
    supportedProducts: []
  }
});

const firmware = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_FIRMWARES_START': {
      return state.set('loading', true);
    }
    case 'GET_FIRMWARES_DONE': {
      const firmwares = action.payload.firmwares;
      return state.set('loading', true).set('list', fromJS(firmwares));
    }
    case 'GET_FIRMWARES_FAILED': {
      return state.set('loading', false).set('list', List());
    }
    case 'OPEN_FIRMWARE_DIALOG': {
      return state
        .set('uploadError', '')
        .set('showDialog', true)
        .set('uploaded', false);
    }
    case 'CLOSE_FIRMWARE_DIALOG': {
      return state.set('showDialog', false);
    }
    case 'FIRMWARE_TABLE_ROW_EXPANDED': {
      let expandedRowId = action.payload.id;
      if (expandedRowId === state.get('expandedRow')) {
        expandedRowId = null;
      }
      return state.set('expandedRow', expandedRowId);
    }
    case 'FIRMWARE_UPLOAD_FORM_CHANGE': {
      return state.setIn(
        ['uploadForm', fromJS(action.payload.name)],
        fromJS(action.payload.value)
      );
    }
    case 'FIRMWARE_UPLOAD_START': {
      return state
        .set('isUploading', true)
        .set('errors', INITIAL_STATE.get('errors'))
        .set('uploaded', false)
        .set('uploadingProgress', 0);
    }
    case 'FIRMWARE_UPLOAD_DONE': {
      return state
        .set('isUploading', false)
        .set('uploaded', true)
        .set(
          'uploadForm',
          fromJS({
            binary: null,
            comment: '',
            production: false,
            version: '',
            eepromVersion: state.getIn(['uploadForm', 'eepromVersion']),
            supportedProducts: []
          })
        );
    }
    case 'FIRMWARE_UPLOAD_PROGRESS': {
      const progress =
        parseFloat(
          parseFloat(
            action.payload.progress.loaded / action.payload.progress.total
          ).toFixed(3)
        ) * 100;
      return state.set('uploadingProgress', progress);
    }
    case 'FIRMWARE_UPLOAD_ERROR': {
      return state.set('isUploading', false).set('uploadError', action.payload);
    }
    case 'FIRMWARE_UPLOAD_FORM_ERROR': {
      return state.set('errors', fromJS(action.payload.errors));
    }
    case 'SESSION_LOGIN_DONE':
    case 'SESSION_CHECK_DONE': {
      let products = action.payload.products || {};
      const supportedEeprom = action.payload.supportedEeprom || [];

      products = Object.entries(products).map((entry) => ({
        name: entry[1].name,
        selected: false,
        type: entry[0],
        productType: entry[1].productType
      }));
      return state
        .set('products', products)
        .setIn(['uploadForm', 'eepromVersion'], supportedEeprom[0]);
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default firmware;
