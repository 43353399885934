/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadDalmStatus({ configurationId, dalmStatusId }) {
  return async (dispatch) => {
    if (
      configurationId === null ||
      dalmStatusId === null ||
      !isFinite(configurationId) ||
      !isFinite(dalmStatusId)
    ) {
      return dispatch({
        type: 'LOAD_DALM_STATUS_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }
    configurationId = parseInt(configurationId, 10);
    dalmStatusId = parseInt(dalmStatusId, 10);
    dispatch({
      type: 'LOAD_DALM_STATUS_STARTED'
    });
    const requestUrl = `${config.apiServer}/configurations/${configurationId}/dalm-status/${dalmStatusId}`;
    try {
      const response = await request.get(requestUrl);
      dispatch({ type: 'LOAD_DALM_STATUS_DONE', payload: response.body });
    } catch (response) {
      dispatch({
        type: 'LOAD_DALM_STATUS_FAILED',
        payload: response.body.msg
      });
    }
  };
}
