/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    userId: '',
    validUntil: ''
  },
  isShowing: false,
  isDeleting: false,
  deleted: false,
  error: Map()
});

export default function deleteFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DELETE_FOLDER_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'SHOW_DELETE_FOLDER_MODAL': {
      return INITIAL_STATE.set('isShowing', true).set('isMoving', false);
    }
    case 'HIDE_DELETE_FOLDER_MODAL': {
      return state.set('isShowing', false);
    }
    case 'DELETE_FOLDER_START': {
      return state.set('isDeleting', true).set('error', Map());
    }
    case 'DELETE_FOLDER_DONE': {
      return state.set('deleted', true);
    }
    case 'DELETE_FOLDER_FAILED': {
      const error = action.payload.msg;
      return state.set('isDeleting', false).set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
