/** @format */

import React from 'react';
import { connect } from 'react-redux';
import history from 'utils/history';

const mapStateToProps = (state, ownProps) => {
  return {
    to: ownProps.to,
    children: ownProps.children,
    className: ownProps.className
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (to, event) => {
      if (ownProps.onClick) ownProps.onClick(event);
      if (to) history.push(to);
    }
  };
};
/*
  Custom Link will work outside a Router context, enabling us to use the same navigation bar across all views.
*/
const Link = ({ children, to, onClick, className }) => {
  return (
    <a
      className={`navigation-link ${className || ''}`}
      to={to}
      onClick={onLinkClick}
    >
      {children}
    </a>
  );
  function onLinkClick(event) {
    event.preventDefault();
    onClick(to, event);
  }
};

const LinkContainer = connect(mapStateToProps, mapDispatchToProps)(Link);
export default LinkContainer;
