/** @format */

export default function validateSerialPort(serialPort) {
  return {
    type: 'object',
    keys: {
      position: {
        type: 'string',
        errorCode: 'validation.serial.port.position'
      },
      name: {
        type: 'string',
        errorCode: 'validation.serial.port.type'
      },
      delay: {
        type: 'number',
        parse: true,
        allowNull: !serialPort.supervision,
        errorCode: 'validation.serial.port.delay'
      },
      lanPosition: {
        type: 'number',
        parse: true,
        allowNull: !serialPort.lanConnection,
        errorCode: 'validation.serial.port.lan.position'
      },
      remotePort: {
        type: 'number',
        parse: true,
        allowNull: !serialPort.lanConnection,
        errorCode: 'validation.serial.port.remote.port'
      }
    }
  };
}
