/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  form: {
    path: ''
  },
  error: ''
});

export default function editSubscriptionDetailsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SHOW_EDIT_SUBSCRIPTION_DETAILS_MODAL': {
      const summary = action.payload;
      return INITIAL_STATE.set('isShowing', true).setIn(
        ['form', 'path'],
        summary.get('path')
      );
    }
    case 'HIDE_EDIT_SUBSCRIPTION_DETAILS_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_SUBSCRIPTION_DETAILS_INPUT_CHANGE': {
      return state.setIn(['form', 'path'], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
