/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showDialog: false,
  current: {}
});

export default function simCardAutoExtendModalReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'SIMCARD_AUTOEXTEND_DIALOG_OPEN': {
      return state.set('current', action.payload).set('showDialog', true);
    }
    case 'SIMCARD_AUTOEXTEND_DIALOG_CLOSE': {
      return state.set('current', {}).set('showDialog', false);
    }
    default: {
      return state;
    }
  }
}
