/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function editAdminNotes() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_ADMIN_NOTES_STARTED' });
    const state = getState();
    const configurationId = state.modals.getIn([
      'editAdminNotes',
      'configurationId'
    ]);
    const adminNotes = state.modals.getIn(['editAdminNotes', 'adminNotes']);

    try {
      await request.post(
        `${config.apiServer}/configurations/admin-notes/${configurationId}`,
        { body: { adminNotes } }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_ADMIN_NOTES_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({
      type: 'EDIT_ADMIN_NOTES_DONE',
      payload: adminNotes
    });
  };
}
