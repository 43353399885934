/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  lineSupervision: false,
  delay: 30,
  alarmProtocol: 'None',
  panelType: null
});

export default function lineReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'line') {
        return state.set(action.payload.name, action.payload.value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
