/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  name: 'standard',
  eventCode: '',
  sectionNumber: '',
  twentyFourHourZone: true,
  serialError: false,
  hasDialerBrand: true,
  showEventParameters: false
});

export default function serialPortReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'serialPort') {
        const name = action.payload.name;
        return state.set(name, action.payload.value);
      }
      return state;
    }
    case 'CHANGE_SERIAL_PORT': {
      return (state = action.payload);
    }
    default: {
      return state;
    }
  }
}
