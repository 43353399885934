/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import loadSubscriptionEventLog from './subscriptionEventLog';

export default function loadSubscriptionSummary(id) {
  return async (dispatch) => {
    if (id === null || !isFinite(id)) {
      return dispatch({
        type: 'LOAD_SUBSCRIPTION_SUMMARY_FAILED',
        payload: { msg: 'errors.noValidRequest' }
      });
    }

    id = parseInt(id, 10);
    dispatch({ type: 'LOAD_SUBSCRIPTION_SUMMARY_STARTED' });

    let response;
    try {
      response = await request.get(
        `${config.apiServer}/subscriptions/${id}/summary`
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'LOAD_SUBSCRIPTION_SUMMARY_FAILED',
        payload: { msg: errorResponse.body.msg }
      });
    }
    const configuration = response.body;
    dispatch({
      type: 'LOAD_SUBSCRIPTION_SUMMARY_DONE',
      payload: configuration
    });
    dispatch(
      loadSubscriptionEventLog({
        configurationId: configuration.id
      })
    );
  };
}
