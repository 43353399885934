/** @format */

import isIp from 'is-ip';

export default function validateLan(lan) {
  const isSet = lan.ipAddress || lan.label;

  return {
    type: 'object',
    keys: isSet
      ? {
          ipAddress: {
            type: 'string',
            condition: (ipAddress) => isIp(ipAddress),
            errorCode: 'validation.ipAddress.notselected'
          },
          prefix: {
            type: 'number',
            condition: (prefix) => prefix > 0,
            errorCode: 'validation.prefix.length.notselected'
          },
          mtu: {
            type: 'number',
            condition: (mtu) => mtu < 1501,
            errorCode: 'validation.mtu.notselected'
          }
        }
      : {}
  };
}
