/** @format */

export const getDefaultSerialPort = () => ({
  label: 'errorInputType.serialEspa',
  sequenceChannel: '',
  delay: '0',
  showExtendedBody: false,
  text: '',
  eventCode: '86',
  sectionNumber: '01',
  sectionText: 'Section 1',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 6,
  reservedFields: []
});

export const getDefaultEthernet = () => ({
  label: 'errorInputType.ethernet',
  sequenceChannel: '',
  delay: '300',
  showExtendedBody: false,
  text: '',
  eventCode: '82',
  sectionNumber: '01',
  sectionText: 'Section 1',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 3,
  reservedFields: []
});

export const getDefaultPower = () => ({
  label: 'errorInputType.power',
  sequenceChannel: '',
  delay: '60',
  showExtendedBody: false,
  text: '',
  eventCode: '81',
  sectionNumber: '01',
  sectionText: 'Section 1',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  disabled: false,
  position: 2,
  reservedFields: []
});

export const getDefaultTestMode = () => ({
  label: 'errorInputType.testmode',
  sequenceChannel: '',
  delay: '2',
  showExtendedBody: false,
  text: '',
  eventCode: '',
  sectionNumber: '01',
  sectionText: '',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 7,
  reservedFields: []
});

export const getDefaultCommunicationFailure = () => ({
  label: 'errorInputType.commfailure.tus',
  sequenceChannel: '',
  delay: '0',
  showExtendedBody: false,
  text: '',
  eventCode: '',
  sectionNumber: '01',
  sectionText: '',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 8,
  reservedFields: []
});

export const getDefaultSabotage = () => ({
  label: 'errorInputType.sabotage',
  sequenceChannel: '',
  delay: '0',
  showExtendedBody: false,
  text: '',
  eventCode: '85',
  sectionNumber: '01',
  sectionText: '',
  delayType: 'T',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 5,
  reservedFields: []
});

export const getDefaultLine = () => ({
  label: 'errorInputType.line',
  sequenceChannel: '',
  delay: '30',
  showExtendedBody: false,
  text: '',
  eventCode: 'LT',
  sectionNumber: '01',
  sectionText: 'Line Trouble',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  position: 9,
  reservedFields: []
});

export const getDefaultGprs = () => ({
  label: 'errorInputType.gprs',
  sequenceChannel: '',
  delay: '18000',
  showExtendedBody: false,
  text: '',
  eventCode: '83',
  sectionNumber: '01',
  sectionText: 'Section 1',
  delayType: 'C',
  twentyFourHourZone: true,
  sendReset: true,
  resetDefinition: 'N',
  disabled: false,
  position: 4,
  reservedFields: []
});
