/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  configurationId: null
});

export default function vpn(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'HIDE_VPN_MODAL':
      return INITIAL_STATE;
    case 'SHOW_VPN_MODAL':
      return state
        .set('isShowing', true)
        .set('configurationId', action.payload);
    default:
      return state;
  }
}
