/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  progress: 0,
  error: '',
  dalmLog: [],
  dalmLogId: null,
  loading: false
});

export default function getDalmDebugLogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_DALM_DEBUG_LOG_FROM_DALM_STARTED': {
      return INITIAL_STATE;
    }
    case 'GET_DALM_DEBUG_LOG_FROM_DALM_POLL_CANCELLED': {
      return state.set('cancelPolling', false);
    }
    case 'GET_DALM_DEBUG_LOG_FROM_DALM_POLL_DONE': {
      return state
        .set('progress', action.payload.progress)
        .set('dalmLogId', action.payload.id)
        .set('file', action.payload.file);
    }
    case 'GET_DALM_DEBUG_LOG_FROM_DALM_FAILED': {
      return state.set('error', action.payload).set('progress', 100);
    }
    case 'GET_DALM_DEBUG_LOG_STARTED': {
      return INITIAL_STATE;
    }
    case 'GET_DALM_DEBUG_LOG_DONE': {
      return state
        .set('error', '')
        .set('loading', false)
        .set('dalmLog', fromJS(action.payload));
    }
    case 'GET_DALM_DEBUG_LOG_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
