/** @format */

import { fromJS, Map } from 'immutable';
const INITIAL_STATE = fromJS({
  temporaryUuid: '',
  checkingUuid: false,
  uuidError: false,
  email: '',
  username: '',
  activating: false,
  activated: false,
  activationError: '',
  activationCode: false,
  form: {
    activationCode: ''
  },
  errors: {}
});
const userActivation = (state, action) => {
  state = state || INITIAL_STATE;
  switch (action.type) {
    case 'USER_ACTIVATION_CHECK_UUID_START': {
      const uuid = action.payload.uuid;
      return state.set('checkingUuid', true).set('temporaryUuid', uuid);
    }
    case 'USER_ACTIVATION_CHECK_UUID_DONE': {
      return state
        .set('checkingUuid', false)
        .set('activationCode', action.payload.activationCode);
    }
    case 'USER_ACTIVATION_CHECK_UUID_FAILED': {
      return state.set('uuidError', true).set('checkingUuid', false);
    }
    case 'USER_ACTIVATION_FORM_ERRORS': {
      return state.set('errors', fromJS(action.payload));
    }
    case 'USER_ACTIVATION_FORM_CHANGE': {
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'USER_ACTIVATION_START': {
      return state.set('activating', true).set('errors', Map());
    }
    case 'USER_ACTIVATION_DONE': {
      const email = action.payload.email;
      const username = action.payload.username;

      return state
        .set('activating', false)
        .set('username', username)
        .set('email', email)
        .set('activated', true);
    }
    case 'USER_ACTIVATION_FAILED': {
      const error = action.payload.error;
      return state.set('activating', false).set('activationError', error);
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default userActivation;
