/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import configurationLoadActions from 'actions/configuration/load';

export default function editSubscriptionDetails() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_SUBSCRIPTION_DETAILS_STARTED' });
    const state = getState();

    const configurationId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'id'
    ]);

    const subscriptionId = state.configuration.getIn([
      'currentSubscription',
      'subscription',
      'jsonSummary',
      'id'
    ]);

    const path = state.modals.getIn([
      'editSubscriptionDetails',
      'form',
      'path'
    ]);

    try {
      await request.post(
        `${config.apiServer}/subscriptions/${configurationId}/saveSubscriptionDetails`,
        {
          body: {
            subscriptionId,
            path
          }
        }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_SUBSCRIPTION_DETAILS_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({ type: 'SUBSCRIPTION_CONFIGURATION_RESET_STATE' });

    await dispatch(
      configurationLoadActions.loadSubscriptionSummary(configurationId)
    );

    dispatch({
      type: 'EDIT_SUBSCRIPTION_DETAILS_DONE'
    });
  };
}
