/** @format */

import { Map, fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  shares: [],
  userForm: {},
  current: {},
  isFetching: false,
  formErrors: {},
  editingOther: false,
  error: '',
  msg: '',
  showCreationModal: false,
  showShareDeletion: false,
  currentShare: {},
  addEndUser: false,
  addSuperUser: false
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CREATE_USER':
    case 'SHOW_ADD_USER_MODAL': {
      const { addEndUser, addSuperUser } = action.payload;

      let newState = INITIAL_STATE.set('addEndUser', addEndUser).set(
        'addSuperUser',
        addSuperUser
      );

      if (addEndUser)
        newState = newState.setIn(['userForm', 'role'], 'endCustomer');
      // basically, it overrides if addSuperUser === true
      if (addSuperUser)
        newState = newState.setIn(['userForm', 'role'], 'superUser');

      return newState;
    }
    case 'LOAD_USER_STARTED': {
      return state.set('isFetching', true).set('error', '').set('msg', '');
    }
    case 'LOAD_USER_DONE': {
      const user = action.payload.user;
      const loadedSelf = action.payload.loadedSelf;

      return state
        .setIn(['userForm', 'fullname'], user.fullname)
        .setIn(['userForm', 'username'], user.username)
        .setIn(['userForm', 'email'], user.email)
        .setIn(['userForm', 'phoneNumber'], user.phoneNumber)
        .setIn(['userForm', 'password'], '')
        .setIn(['userForm', 'role'], user.role)
        .setIn(['userForm', 'passwordConfirmation'], '')
        .setIn(['userForm', 'mobilePhoneNumber'], user.mobilePhoneNumber)
        .setIn(['userForm', 'emailConfirmation'], user.email)
        .setIn(
          ['userForm', 'twoFactorAuthentication'],
          user.twoFactorAuthentication
        )
        .setIn(['userForm', 'passwordExpiration'], user.passwordExpiration)
        .set('formErrors', fromJS({}))
        .set('current', fromJS(user))
        .set('editingOther', !loadedSelf);
    }
    case 'LOAD_USER_SHARES_DONE': {
      return state.set('shares', fromJS(action.payload));
    }
    case 'LOAD_USER_SHARES_FAIL': {
      return state.set('error', action.payload);
    }
    case 'EDIT_USER_SHARE': {
      return state.update('shares', (shares) =>
        shares.map((share) =>
          share.get('id') === action.payload.get('id') ? action.payload : share
        )
      );
    }
    case 'REMOVE_SHARE_DONE': {
      return state.update('shares', (shares) =>
        shares.filter((share) => share.get('id') !== action.payload.get('id'))
      );
    }
    case 'USER_FORM_ADD_TWO_FACTOR_SETTINGS':
      return state
        .setIn(
          ['userForm', 'twoFactorAuthentication'],
          state.getIn(['userForm', 'twoFactorAuthentication'], true)
        )
        .setIn(
          ['userForm', 'passwordExpiration'],
          state.getIn(['userForm', 'passwordExpiration'], false)
        );
    case 'USER_FORM_CHANGED': {
      if (
        action.payload.name === 'role' &&
        action.payload.value !== 'companyUser'
      ) {
        state = state
          .setIn(
            ['userForm', 'twoFactorAuthentication'],
            action.payload.twoFactorEnabled
          )
          .setIn(['userForm', 'passwordExpiration'], false);
      }

      return state
        .set('msg', '')
        .setIn(['userForm', action.payload.name], action.payload.value);
    }
    case 'USER_CREATE_START': {
      return state
        .set('isSending', true)
        .set('error', '')
        .set('msg', '')
        .set('formErrors', Map());
    }
    case 'USER_CREATE_DONE': {
      return state
        .set('isSending', false)
        .set('showCreationModal', true)
        .set('msg', 'create.user.done');
    }
    case 'USER_CREATE_FAILED': {
      const error = action.payload;
      return state.set('isSending', false).set('error', error);
    }
    case 'USER_UPDATE_START': {
      return state
        .set('isSending', true)
        .set('error', '')
        .set('msg', '')
        .set('formErrors', Map());
    }
    case 'USER_UPDATE_DONE': {
      return state.set('isSending', false).set('msg', 'edit.user.done');
    }
    case 'USER_UPDATE_FAILED': {
      return state.set('isSending', false).set('error', action.payload);
    }
    case 'USER_FORM_ERRORS': {
      const errors = action.payload;
      return state.set('isSending', false).set('formErrors', errors);
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
