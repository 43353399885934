/** @format */

import { fromJS } from 'immutable';

const INITIAL_FREE_POSITIONS = ['1', '2'];
const INITIAL_STATE = fromJS(getInitialState());

export default function outputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (
        action.payload.index !== undefined &&
        action.payload.type === 'relays'
      ) {
        if (action.payload.name === 'optionalFunctionality') {
          state = state.setIn(['data', action.payload.index, 'pulse'], false);
        } else if (action.payload.name === 'position') {
          state = state.setIn(
            ['data', action.payload.index, action.payload.name],
            action.payload.value
          );
          const freePositions = INITIAL_FREE_POSITIONS.filter(
            (position) =>
              !state
                .get('data')
                .find((relay) => relay.get('position') + '' === position)
          );
          return state.set('freePositions', fromJS(freePositions));
        }
        return state.setIn(
          ['data', action.payload.index, action.payload.name],
          action.payload.value
        );
      } else if (action.payload.name === 'product') {
        const data =
          state.get('maxRelays') === 5 ? [] : getDefaultPangeaRelays();
        state = state.set('data', data);
        const maxRelays = state.get('maxRelays') === 5 ? 2 : 5;
        state = state.set('maxRelays', maxRelays);
        let freePositions = [];
        if (maxRelays === 2) {
          freePositions = INITIAL_FREE_POSITIONS.filter(
            (position) =>
              !data.find((relay) => relay.get('position') + '' === position)
          );
        }
        state = state.set('freePositions', fromJS(freePositions));
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_RELAY': {
      const relays = state.get('data').toJS();
      relays.push({
        ...getDefaultRelay()
      });
      return state.set('data', fromJS(relays));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_RELAY': {
      const relays = state.get('data').delete(action.payload.index);
      const freePositions = INITIAL_FREE_POSITIONS.filter(
        (position) =>
          !relays.find((relay) => relay.get('position') + '' === position)
      );
      return state
        .set('data', relays)
        .set('freePositions', fromJS(freePositions));
    }
    case 'EAGLE_EYE_REMOTE_CONTROL_UPDATE': {
      const relays = state.get('data').map((oldRelay) => {
        const updatedRelay = action.payload.outputs.find(
          (relay) =>
            relay.position === oldRelay.get('position') &&
            relay.type === 'relay'
        );
        return updatedRelay ? fromJS(updatedRelay) : oldRelay;
      });
      return state.set('data', relays);
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  const data = getDefaultPangeaRelays();
  return {
    maxRelays: 5,
    freePositions: [],
    data
  };
}

function getDefaultPangeaRelays() {
  const functionMap = [
    {
      position: 1,
      function: 'interruptCritical',
      label: 'ATS_FAIL',
      type: 'output.active.open'
    },
    {
      position: 2,
      function: 'interruptIp',
      label: 'ETH_FAIL',
      type: 'output.active.open'
    },
    {
      position: 3,
      function: 'interruptMobile',
      label: 'GSM_FAIL',
      type: 'output.active.open'
    },
    {
      position: 4,
      function: 'localAlarm',
      label: 'COMM_FAIL',
      type: 'output.active.close'
    },
    {
      position: 5,
      function: 'transmissionSuccess',
      label: 'FA_ACK',
      type: 'output.active.close'
    }
  ];
  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      label: functionMap[i].label,
      type: 'relay',
      activeText: '',
      inactiveText: '',
      pulseLength: 0,
      isArm: false,
      outputType: functionMap[i].type,
      pulse: false,
      position: functionMap[i].position,
      outputFunction: null,
      optionalFunctionality: functionMap[i].function,
      inputFeedback: null,
      isImported: false,
      locked: true
    });
  }
  return data;
}

function getDefaultRelay() {
  return {
    label: '',
    type: 'relay',
    activeText: '',
    inactiveText: '',
    pulseLength: 0,
    isArm: false,
    outputType: null,
    pulse: false,
    position: null,
    outputFunction: null,
    optionalFunctionality: null,
    inputFeedback: null,
    isImported: false,
    locked: false
  };
}
