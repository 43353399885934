/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function updateServiceValue(service, activate, configurationId) {
  return async (dispatch) => {
    dispatch({ type: 'SECURITY_ROUTER_SERVICES_UPDATE_STARTED' });

    const requestUrl = `${config.apiServer}/configurations/security-router-services/${configurationId}`;
    try {
      const response = await request.post(requestUrl, {
        body: { service, activate }
      });
      dispatch({
        type: 'SECURITY_ROUTER_SERVICES_UPDATE_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'SECURITY_ROUTER_SERVICES_UPDATE_FAILED',
        payload:
          response.body.msg ||
          (response.code === 500
            ? 'errors.maintenance'
            : 'errors.noValidRequest')
      });
    }
  };
}
