/** @format */

const addSecureTheme = {
  desktopSmall: '700px',
  desktopMedium: '1170px',
  desktopLarge: '1250px',
  desktopHuge: '1400px',
  desktopSmallInt: 700,

  navigationBarBackgroundColor: '#fff',
  navigationBarBackgroundAccentColor: '#fff',
  navigationBarBorderColor: 'rgba(0,0,0,0.1)',
  navigationBarItemBorderColor: 'transparent',
  navigationBarTextColor: '#000',
  navigationBarTextAccentColor: '#004c93',

  ewmTurquoise: '#004c93',
  ewmTurquoiseLight: '#acc8d2',
  menuBackgroundBlack: '#2f2f2f',
  secondaryMain: '#ee7100',
  linkColor: '#004c93',
  linkColorHover: '#006ace',

  detailsConfigurationBackgroundColor: '#fff',
  detailsConfigurationInformationHeaderColor: '#fff'
};

export default addSecureTheme;
