/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  configurationId: null,
  isShowing: false,
  isLoading: false,
  isDone: false,
  eventLogMessage: '',
  error: ''
});

export default function addEventLog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_EVENT_LOG_MODAL_SHOW': {
      return state
        .set('isLoading', false)
        .set('isShowing', true)
        .set('configurationId', action.payload.configurationId)
        .set('eventLogMessage', action.payload.eventLogMessage);
    }
    case 'ADD_EVENT_LOG_MODAL_INPUT_CHANGE': {
      return state.set('eventLogMessage', action.payload.value);
    }
    case 'ADD_EVENT_LOG_MODAL_START': {
      return state.set('isLoading', true);
    }
    case 'ADD_EVENT_LOG_MODAL_SUCCESS': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'ADD_EVENT_LOG_MODAL_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.msg);
    }
    case 'ADD_EVENT_LOG_MODAL_RESET': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
