/** @format */
import anyAscii from './any-ascii';

class Transpiler {
  static isValueValid(formControlValue) {
    const unsupported = [...(formControlValue || '')].filter(
      (x) => !this.isValidCharacter(x)
    );

    return unsupported.length > 0 ? [...new Set(unsupported)] : false;
  }

  static getTransliteratedValue(newCharacters, formControlValue) {
    if (!newCharacters) return formControlValue;

    const hasInvalidCharacters = [...newCharacters].some(
      (x) => !this.isValidCharacter(x)
    );
    let updatedValue = formControlValue || '';

    if (hasInvalidCharacters) {
      const transliteratedValues = this.getTransliterationMap(newCharacters);

      for (const character of transliteratedValues.keys()) {
        updatedValue = updatedValue
          .split(character)
          .join(transliteratedValues.get(character) || '');
      }
    }

    return updatedValue;
  }

  static isValidCharacter(character) {
    const encoder = new TextEncoder();
    const encodedCharacter = encoder.encode(character);

    if (encodedCharacter.byteLength === 1) {
      return encodedCharacter[0] <= 127;
    } else if (encodedCharacter.byteLength === 2) {
      return (
        encodedCharacter[0] === 195 &&
        encodedCharacter[1] >= 128 &&
        encodedCharacter[1] <= 191
      );
    } else {
      return false;
    }
  }

  static getTransliterationMap(characters) {
    const modifiedFormControlValue = new Map();

    for (const character of characters) {
      const transliteratedValue = anyAscii(character);

      if (transliteratedValue.length === character.length) {
        modifiedFormControlValue.set(character, transliteratedValue);
      }
    }

    return modifiedFormControlValue;
  }
}

export default Transpiler;
