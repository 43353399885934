/** @format */

import React from 'react';
import Link from 'components/global/Link';
import urls from 'utils/urls';
import logopic from './logo.png';
import Logo from './Logo';
import { getPaths } from './paths';
import theme from 'theme';
import ReactCollapsible from 'react-collapsible';
import ScrollToTop from 'components/global/ScrollToTop';
import AboutModal from '../../global/AboutModal';
const APPROXIMATE_ITEM_WIDTH = 170;

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileView: window.innerWidth < theme.desktopSmallInt,
      hamburgerMenuInTransition: false,
      hamburgerMenuOpen: this.props.showHamburgerMenu
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth.bind(this));
    this.setState({ offsetWidth: this.itemsWrapperRef.offsetWidth });
  }
  componentDidUpdate(prevProps) {
    const paths = getPaths(this.props);
    const currentPath = paths.find(
      (path) => path.pathname === this.props.browserPath
    );

    if (this.props.browserPath !== prevProps.browserPath) {
      this.props.closeExpandedMenus();
    }

    if (
      window.innerWidth < theme.desktopSmallInt &&
      currentPath &&
      currentPath.parentId &&
      !prevProps.expandedNavigationItems.includes(currentPath.parentId) &&
      !this.props.expandedNavigationItems.includes(currentPath.parentId)
    ) {
      this.props.addExpandedItem(currentPath.parentId);
    }

    if (this.props.showHamburgerMenu && !prevProps.showHamburgerMenu) {
      setTimeout(
        () => window.addEventListener('click', prevProps.closeHamburgerMenu),
        0
      );
    } else if (!this.props.showHamburgerMenu && prevProps.showHamburgerMenu) {
      window.removeEventListener('click', prevProps.closeHamburgerMenu);
    }

    if (
      !this.state.hamburgerMenuInTransition &&
      this.state.hamburgerMenuOpen !== this.props.showHamburgerMenu
    ) {
      this.setState({
        hamburgerMenuInTransition: true,
        hamburgerMenuOpen: this.props.showHamburgerMenu
      });
    }
  }
  componentWillUnmount() {
    this.props.closeHamburgerMenu();
    window.removeEventListener('click', this.props.closeHamburgerMenu);
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ isMobileView: window.innerWidth < theme.desktopSmallInt });
  }

  render() {
    const {
      hideLinks,
      openHamburgerMenu,
      browserPath,
      checkedSession,
      isChecking,
      organizationLogo,
      expandedNavigationItems
    } = this.props;
    const itemsWrapperRef = this.itemsWrapperRef;
    let availableWidth =
      !this.state.isMobileView && itemsWrapperRef
        ? itemsWrapperRef.offsetWidth
        : 0;
    const paths = hideLinks ? [] : getPaths(this.props);
    let hamburgerItems = 0;
    let visibleItems = 0;
    const excessDesktopNavigationItems = [];
    paths
      .filter((path) => !path.hidden && !path.alwaysHamburger)
      .reverse()
      .forEach((path) => {
        if (availableWidth < APPROXIMATE_ITEM_WIDTH) {
          excessDesktopNavigationItems.push(path);
          return;
        }
        if (path.parentId) return;
        availableWidth -= APPROXIMATE_ITEM_WIDTH;
      });

    const navigationItems = paths
      .filter((path) => {
        return path.hidden || path.isExpandItem ? false : true;
      })
      .reverse()
      .map((path, i) => {
        const pathRegexp = new RegExp(`^/${path.pathname.split('/')[1]}`);
        const selected = pathRegexp.test(browserPath) ? ' selected' : '';
        const hidden = path.hidden ? ' hidden' : '';
        const onlyMobile = path.onlyMobile ? ' only-mobile' : '';
        const even = !path.hidden && visibleItems % 2 === 0 ? ' even' : '';
        const isExcessItem = excessDesktopNavigationItems.find(
          (item) => item.name === path.name
        );
        visibleItems = path.hidden ? visibleItems : visibleItems + 1;
        let accentColor = '';
        let alwaysHamburger = '';
        if (path.alwaysHamburger || isExcessItem) {
          hamburgerItems++;
          alwaysHamburger = ' always-hamburger';
          accentColor = hamburgerItems % 2 === 0 ? ' accent-color' : '';
        }
        const classNames = `navigation-item${even}${selected}${hidden}${accentColor}${alwaysHamburger}${onlyMobile}`;
        const clickHandler = (e) => {
          if (typeof path.onClick === 'function') {
            if (path.expandable) {
              path.onClick(path);
            } else {
              path.onClick();
            }
            if (!path.pathname) {
              e.preventDefault();
            }
          }
        };

        if (path.expandable) {
          return renderExpandableNavigationItem({
            paths,
            path,
            browserPath,
            isExcessItem,
            classNames,
            clickHandler,
            index: i,
            expandedNavigationItems
          });
        } else if (isExcessItem) {
          return renderExcessNavigationItems({
            path,
            classNames,
            clickHandler,
            index: i
          });
        } else {
          return (
            <div className={classNames} key={i}>
              <Link to={path.pathname} onClick={clickHandler}>
                {path.name}
              </Link>
            </div>
          );
        }
      });

    let logoPath = isChecking ? '' : logopic;
    if (checkedSession) {
      logoPath = organizationLogo || logopic;
    }

    return (
      <div>
        <AboutModal />
        <ScrollToTop />
        <div className='navigation-bar-wrapper'>
          <div className='navigation-bar'>
            <Link to={urls.home}>{<Logo logoPath={logoPath} />}</Link>
            <div
              className='desktop-navigation-items'
              ref={(ref) => {
                this.itemsWrapperRef = ref;
              }}
            >
              {navigationItems}
            </div>
            {!hideLinks && (
              <span
                className='fa fa-bars hamburger-menu-button'
                onClick={openHamburgerMenu}
              />
            )}
          </div>
          <ReactCollapsible
            transitionTime={200}
            easing='ease-in-out'
            overflowWhenOpen='visible'
            open={this.state.hamburgerMenuOpen}
            className={`hamburger-navigation-items`}
            openedClassName={`hamburger-navigation-items`}
            onClose={() =>
              this.setState({
                hamburgerMenuInTransition: false,
                hamburgerMenuOpen: false
              })
            }
            onOpen={() =>
              this.setState({
                hamburgerMenuInTransition: false,
                hamburgerMenuOpen: true
              })
            }
          >
            {navigationItems}
          </ReactCollapsible>
        </div>
      </div>
    );
  }
}

function renderExpandableNavigationItem({
  paths,
  path,
  browserPath,
  isExcessItem,
  classNames,
  clickHandler,
  index,
  expandedNavigationItems
}) {
  const childItems = paths
    .filter((childPath) => childPath.parentId === path.id)
    .map((childPath, childIndex) => {
      const expandItem = childPath.isExpandItem ? ' expand-item' : '';
      const selected = browserPath === childPath.pathname ? ' selected' : '';
      const alwaysHamburger = isExcessItem ? ' always-hamburger' : '';
      return (
        <div
          className={`navigation-item${expandItem}${selected}${alwaysHamburger}`}
          key={childIndex}
        >
          <Link to={childPath.pathname} onClick={clickHandler}>
            {childPath.name}
          </Link>
        </div>
      );
    });

  const isExpanded = expandedNavigationItems.find(
    (expandedNavItem) => expandedNavItem === path.id
  );

  if (isExcessItem) {
    return (
      <>
        <div
          id='expandable'
          role='button'
          className={`${classNames} always-hamburger`}
          key={index}
          to={path.pathname}
          onClick={clickHandler}
        >
          <p id='expandable'>{path.name}</p>
          <i
            id='expandable'
            className={'fa fa-caret-'.concat(isExpanded ? 'down' : 'up')}
          />
        </div>
        <>{isExpanded && childItems}</>
      </>
    );
  }

  return (
    <div
      id='expandable'
      className={classNames}
      key={index}
      onClick={clickHandler}
    >
      <ul>
        <li>
          <div
            id='expandable'
            role='button'
            className='navigation-link'
            to={path.pathname}
          >
            {path.name}
          </div>
          <i
            id='expandable'
            className={'fa fa-caret-'.concat(isExpanded ? 'down' : 'up')}
          />
        </li>
        {isExpanded && <li> {childItems}</li>}
      </ul>
    </div>
  );
}

function renderExcessNavigationItems({
  path,
  classNames,
  clickHandler,
  index
}) {
  return (
    <div className={`${classNames} always-hamburger`} key={index}>
      <Link to={path.pathname} onClick={clickHandler}>
        {path.name}
      </Link>
    </div>
  );
}

export default NavigationBar;
