/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  list: [],
  query: ''
});

export default function eventLog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_EVENT_LOG_STARTED':
    case 'LOAD_SUBSCRIPTION_EVENT_LOG_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_EVENT_LOG_DONE': {
      return state
        .set('list', fromJS(action.payload.list))
        .set('loading', false);
    }
    case 'LOAD_SUBSCRIPTION_EVENT_LOG_DONE': {
      const items = state.get('query')
        ? action.payload.list.filter(({ eventId }) =>
            eventId.toLowerCase().includes(state.get('query').toLowerCase())
          )
        : action.payload.list;

      return state.set('list', fromJS(items)).set('loading', false);
    }
    case 'LOAD_EVENT_LOG_FAILED':
    case 'LOAD_SUBSCRIPTION_EVENT_LOG_FAILED': {
      return state.set('error', action.payload.msg).set('loading', false);
    }
    case 'EVENT_LOG_LIST_QUERY': {
      return state.set('query', action.payload).set('offset', 0);
    }
    case 'RESET_EVENT_LOG_QUERY': {
      return state.set('query', '');
    }
    default: {
      return state;
    }
  }
}
