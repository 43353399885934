/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  configurationId: '',
  adminNotes: '',
  error: ''
});

export default function editAdminNotesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_EDIT_ADMIN_NOTES_MODAL': {
      return state
        .set('isShowing', true)
        .set('configurationId', action.payload.configurationId)
        .set('adminNotes', action.payload.adminNotes);
    }
    case 'HIDE_EDIT_ADMIN_NOTES_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_ADMIN_NOTES_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_ADMIN_NOTES_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_ADMIN_NOTES_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_ADMIN_NOTES_INPUT_CHANGE': {
      return state.set('adminNotes', action.payload.value);
    }
    default: {
      return state;
    }
  }
}
