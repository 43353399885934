/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  show: false,
  showDeactivate: false
});

export default function activateEagleEyeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'HIDE_ACTIVATE_EAGLE_EYE_MODAL': {
      return state.set('show', false);
    }
    case 'SHOW_ACTIVATE_EAGLE_EYE_MODAL': {
      return state.set('show', true);
    }
    case 'HIDE_DEACTIVATE_EAGLE_EYE_MODAL': {
      return state.set('showDeactivate', false);
    }
    case 'SHOW_DEACTIVATE_EAGLE_EYE_MODAL': {
      return state.set('showDeactivate', true);
    }
    default: {
      return state;
    }
  }
}
