/** @format */

import React, { lazy } from 'react';
import { Route } from 'react-router';
import urls from 'utils/urls';
const OrganizationViewContainer = lazy(() =>
  import('components/views/Organization/View/OrganizationViewContainer')
);
const OrganizationListContainer = lazy(() =>
  import('components/views/Organization/List/OrganizationListContainer')
);
const OrganizationAdminContainer = lazy(() =>
  import('components/views/Organization/Admin/OrganizationAdminContainer')
);
const UserContainer = lazy(() =>
  import('components/views/User/View/UserContainer')
);
const UserCreateContainer = lazy(() =>
  import('components/views/User/Create/UserCreateContainer')
);
const OrganizationAdminCreateContainer = lazy(() =>
  import('components/views/Organization/Admin/Create')
);

export default function OrganizationRoutes(withSession) {
  return [
    <Route
      exact
      key={urls.organizationCreateUser}
      path={urls.organizationCreateUser}
      component={withSession(UserCreateContainer)}
    />,
    <Route
      exact
      key={urls.adminOrganizationUser}
      path={urls.adminOrganizationUser}
      component={withSession(UserContainer)}
    />,
    <Route
      exact
      key={urls.organization}
      path={urls.organization}
      component={withSession(OrganizationListContainer)}
    />,
    <Route
      exact
      key={urls.ownOrganization}
      path={urls.ownOrganization}
      component={withSession(OrganizationListContainer)}
    />,
    <Route
      exact
      key={urls.organizationAdmin}
      path={urls.organizationAdmin}
      component={withSession(OrganizationAdminContainer)}
    />,
    <Route
      exact
      key={urls.organizationDetails}
      path={urls.organizationDetails}
      component={withSession(OrganizationViewContainer)}
    />,
    <Route
      exact
      key={urls.ownOrganizationDetails}
      path={urls.ownOrganizationDetails}
      component={withSession(OrganizationViewContainer)}
    />,
    <Route
      exact
      key={urls.ownOrganizationCreateUser}
      path={urls.ownOrganizationCreateUser}
      component={withSession(UserCreateContainer)}
    />,
    <Route
      exact
      key={urls.organizationUser}
      path={urls.organizationUser}
      component={withSession(UserContainer)}
    />,
    <Route
      exact
      key={urls.createOrganization}
      path={urls.createOrganization}
      component={withSession(OrganizationAdminCreateContainer)}
    />
  ];
}
