/** @format */

import expect from '@edgeguideab/expect';
import validateAlarmCenter from './alarmCenter';
import validateInput from './input';
import validateErrorInput from './errorInput';
import validateLine from './line';
import validateRelay from './relay';
import validateSerialPort from './serialPort';
import validateIpProtocolsDc09 from './ipProtocolsDc09';
import validateBACnet from './bacnet';

export default function validateAlarmTransmitter({
  configuration,
  country,
  productFeatures,
  editConfigurationFeatures
}) {
  return expect(
    {
      alarmCenters: {
        type: 'array',
        items: (alarmCenter) =>
          validateAlarmCenter(alarmCenter, country, editConfigurationFeatures)
      },
      inputs: {
        type: 'object',
        keys: {
          data: {
            type: 'array',
            items: (input) => validateInput({ input, productFeatures })
          }
        }
      },
      errorInputs: {
        type: 'array',
        items: () => validateErrorInput()
      },
      line: validateLine(configuration.line),
      relays: {
        type: 'object',
        keys: {
          data: {
            type: 'array',
            items: (relay) => validateRelay(relay)
          }
        }
      },
      serialPorts: {
        type: 'object',
        keys: {
          data: {
            type: 'array',
            items: validateSerialPort
          }
        }
      },
      ipProtocolsDc09s: {
        type: 'array',
        items: (protocol) =>
          validateIpProtocolsDc09(protocol, configuration.ipProtocolsDc09s)
      },
      bacnet: {
        type: 'object',
        allowNull: !productFeatures.includes('bacnet'),
        keys: validateBACnet(configuration)
      }
    },
    configuration
  ).errors();
}
