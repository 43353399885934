/** @format */

import { fromJS } from 'immutable';
import util from 'utils/util';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  list: [],
  mobileFolders: [],
  selectedFolder: {},
  hasRootShare: false
});

export default function foldersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_LIST_INIT_STATE': {
      const { folderId, fromExternal } = action.payload;
      const selectedFolder = {
        id:
          folderId && !folderId.includes('organization')
            ? JSON.parse(folderId)
            : undefined,
        external: fromExternal ? JSON.parse(fromExternal) : undefined
      };
      if (folderId && folderId.includes('organization')) {
        selectedFolder.id = folderId;
      }

      return INITIAL_STATE.set('selectedFolder', fromJS(selectedFolder));
    }
    case 'LOAD_FOLDERS_START': {
      return state.set('loading', true);
    }
    case 'LOAD_FOLDERS_DONE': {
      let { folders } = action.payload;
      const { hasRootShare } = action.payload;
      const selectedId = state.getIn(['selectedFolder', 'id']);

      let mobileFolders;
      if (selectedId && selectedId !== -1) {
        const selectedFolder =
          folders.find((folder) => folder.id === selectedId) || {};
        state = state.set('selectedFolder', fromJS(selectedFolder));
        if (Object.keys(selectedFolder).length > 0) {
          folders = expandParentFolders(folders, selectedFolder);
          mobileFolders = fromJS(
            folders.filter((f) => f.parentId === selectedId)
          );
        }
      }
      const newFolders = fromJS(util.buildFolderTree(folders));
      return state
        .set('hasRootShare', hasRootShare)
        .set('list', newFolders)
        .set('mobileFolders', mobileFolders || newFolders)
        .set('loading', false);
    }
    case 'LOAD_FOLDERS_FAILED': {
      return state.set('loading', false).set('error', action.payload.msg);
    }
    case 'FOLDER_SELECTED': {
      return state
        .set('selectedFolder', action.payload || fromJS({}))
        .set(
          'mobileFolders',
          (action.payload && action.payload.get('children')) ||
            state.get('list')
        );
    }
    case 'FOLDER_EXPAND': {
      const path = action.payload.path;
      const expanded = !!state.getIn(['list', ...path, 'expanded']);
      return state.setIn(['list', ...path, 'expanded'], !expanded);
    }
    default: {
      return state;
    }
  }
}

function getParentId(folder) {
  let parentId = folder.parentId;
  if (
    !folder.parentId &&
    !`${folder.id}`.includes('organization') &&
    folder.external
  ) {
    parentId = `organization${folder.organizationId}`;
  }
  return parentId;
}

function expandParentFolders(folders, selectedFolder) {
  let folder = selectedFolder;

  const parent = (f) => f.id === getParentId(folder);
  while (folder && getParentId(folder)) {
    folder = folders.find(parent);
    if (!folder) {
      break;
    }
    folder.expanded = true;
  }
  return folders;
}
