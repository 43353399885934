/** @format */

import { fromJS } from 'immutable';
import moment from 'moment';

const INITIAL_STATE = fromJS({
  year: moment().year(),
  month: null,
  thing: {},
  tab: 'data',
  loading: true,
  error: ''
});

export default function current(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'THINGS_CURRENT_LOAD_START':
      return INITIAL_STATE.set('loading', true);
    case 'THINGS_CURRENT_LOAD':
      return state.set('loading', false).set('thing', fromJS(action.payload));
    case 'THINGS_CURRENT_ERROR':
      return state.set('loading', false).set('error', action.payload);
    case 'THINGS_CURRENT_YEAR':
      return state.set('loading', true).set('year', action.payload);
    case 'THINGS_CURRENT_MONTH':
      return state
        .set('month', action.payload.month)
        .set('showSimMonth', action.payload.showSimMonth);
    case 'THINGS_CURRENT_TAB':
      return state.set('tab', action.payload);
    default:
      return state;
  }
}
