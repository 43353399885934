/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  error: '',
  list: []
});

export default function predefinedAlarmCenters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_ALARM_CENTERS_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_ALARM_CENTERS_DONE': {
      action.payload = action.payload.map((alarmCenter) => {
        alarmCenter.selected = false;
        return alarmCenter;
      });
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_ALARM_CENTERS_FAIL': {
      return state.set('loading', false);
    }
    case 'EDIT_AUTHENTICATION_CODE_DONE': {
      const alarmCenter = state
        .get('list')
        .find((arc) => arc.get('id') === action.payload.alarmCenterId);
      if (!alarmCenter) {
        return state;
      }
      return state.setIn(
        ['list', alarmCenter.get('position') - 1, 'v4', 'authenticationCode'],
        action.payload.newAuthenticationCode
      );
    }
    default: {
      return state;
    }
  }
}
