/** @format */

import { fromJS, List } from 'immutable';

const INITIAL_STATE = fromJS({
  pictures: List(),
  loading: false,
  error: ''
});

export default function cameras(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EAGLE_EYE_CAMERA_SET_PICTURES': {
      return state.set('pictures', fromJS(action.payload));
    }
    case 'EAGLE_EYE_CAMERA_LOAD_PICTURES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'EAGLE_EYE_CAMERA_LOAD_PICTURES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'EAGLE_EYE_CAMERA_LOAD_PICTURES_DONE': {
      return state.set('loading', false);
    }
    case 'EAGLE_EYE_CAMERA_REFRESH_PICTURE_FAILED': {
      return state.set('error', action.payload);
    }
    case 'EAGLE_EYE_CAMERA_POLL_PICTURE_FAILED': {
      return state.set('error', action.payload);
    }
    case 'EAGLE_EYE_CAMERA_REFRESH_PICTURE_STARTED': {
      const pictures = state.get('pictures').map((picture) => {
        if (picture.get('cameraId') === action.payload) {
          picture = picture.set('isLoading', true);
        }
        return picture;
      });
      return state.set('pictures', pictures).set('error', '');
    }
    default: {
      return state;
    }
  }
}
