/** @format */

export { formatAlarmCenters, formatAlarmCenterForSave, formatReceiver };

function formatAlarmCenters(alarmCenters) {
  return alarmCenters.map(formatAlarmCenter);
}

const defaultReceiverValues = {
  primaryReceiverIp: '',
  primaryReceiverPort: '',
  primaryethernetReceiverIp: '',
  primaryethernetReceiverPort: '',
  primarymobileReceiverIp: '',
  primarymobileReceiverPort: '',
  secondaryReceiverIp: '',
  secondaryReceiverPort: '',
  secondaryethernetReceiverIp: '',
  secondaryethernetReceiverPort: '',
  secondarymobileReceiverIp: '',
  secondarymobileReceiverPort: ''
};

function formatAlarmCenter({
  primaryReceiver,
  secondaryReceiver,
  v4,
  ...rest
}) {
  return {
    ...rest,
    ...defaultReceiverValues,
    ...(primaryReceiver && formatReceiver(primaryReceiver, true)),
    ...(secondaryReceiver && formatReceiver(secondaryReceiver)),
    samePathSettings:
      (!primaryReceiver || !primaryReceiver.includes(';')) &&
      (!secondaryReceiver || !secondaryReceiver.includes(';')),
    ...(v4 && {
      v4: {
        ...v4,
        transmitterCode:
          v4.transmitterCode && v4.transmitterCode.replace(/\s+/g, ''),
        authenticationCode:
          v4.authenticationCode && v4.authenticationCode.replace(/\s+/g, '')
      }
    })
  };
}

function formatReceiver(receiver, isPrimary) {
  if (!receiver.includes(';')) {
    const [ip, port] = receiver.split(':');
    return isPrimary
      ? { primaryReceiverIp: ip, primaryReceiverPort: port }
      : { secondaryReceiverIp: ip, secondaryReceiverPort: port };
  }

  const [ethernetPath, mobilePath] = receiver.split(';');
  const { ip: ethernetIp, port: ethernetPort } = splitAddress(ethernetPath);
  const { ip: mobileIp, port: mobilePort } = splitAddress(mobilePath);
  return isPrimary
    ? {
        primaryethernetReceiverIp: ethernetIp,
        primaryethernetReceiverPort: ethernetPort,
        primarymobileReceiverIp: mobileIp,
        primarymobileReceiverPort: mobilePort
      }
    : {
        secondaryethernetReceiverIp: ethernetIp,
        secondaryethernetReceiverPort: ethernetPort,
        secondarymobileReceiverIp: mobileIp,
        secondarymobileReceiverPort: mobilePort
      };
}

function formatReceiverForSave({
  ip,
  port,
  ethernetIp,
  ethernetPort,
  mobileIp,
  mobilePort,
  samePathSettings,
  communicationPath,
  showIpAddresses
}) {
  if (!showIpAddresses && (samePathSettings || communicationPath !== 'all')) {
    return ip ? `${ip}:${port}` : null;
  }

  const ethernetReceiver = ethernetIp && `${ethernetIp}:${ethernetPort}`;
  const mobileReceiver = mobileIp && `${mobileIp}:${mobilePort}`;
  return ethernetReceiver || mobileReceiver
    ? `${ethernetReceiver};${mobileReceiver}`
    : null;
}

function formatAlarmCenterForSave(alarmCenter, showIpAddresses) {
  const [primaryReceiver, secondaryReceiver] = ['primary', 'secondary'].map(
    (pathType) =>
      formatReceiverForSave({
        ip: alarmCenter[`${pathType}ReceiverIp`],
        port: alarmCenter[`${pathType}ReceiverPort`],
        ethernetIp: alarmCenter[`${pathType}ethernetReceiverIp`],
        ethernetPort: alarmCenter[`${pathType}ethernetReceiverPort`],
        mobileIp: alarmCenter[`${pathType}mobileReceiverIp`],
        mobilePort: alarmCenter[`${pathType}mobileReceiverPort`],
        samePathSettings: alarmCenter.samePathSettings,
        communicationPath: alarmCenter.communicationPath,
        showIpAddresses
      })
  );

  delete alarmCenter.primaryReceiverIp;
  delete alarmCenter.primaryReceiverPort;
  delete alarmCenter.secondaryReceiverIp;
  delete alarmCenter.secondaryReceiverPort;

  delete alarmCenter.samePathSettings;
  delete alarmCenter.primaryethernetReceiverIp;
  delete alarmCenter.primaryethernetReceiverPort;
  delete alarmCenter.primarymobileReceiverIp;
  delete alarmCenter.primarymobileReceiverPort;
  delete alarmCenter.secondaryethernetReceiverIp;
  delete alarmCenter.secondaryethernetReceiverPort;
  delete alarmCenter.secondarymobileReceiverIp;
  delete alarmCenter.secondarymobileReceiverPort;

  alarmCenter.primaryReceiver = primaryReceiver;
  alarmCenter.secondaryReceiver = secondaryReceiver;
  if (alarmCenter.v4) {
    alarmCenter.v4 = {
      ...alarmCenter.v4,
      transmitterCode:
        alarmCenter.v4.transmitterCode &&
        alarmCenter.v4.transmitterCode.replace(/\s+/g, ''),
      authenticationCode:
        alarmCenter.v4.authenticationCode &&
        alarmCenter.v4.authenticationCode.replace(/\s+/g, '')
    };
  }
  return alarmCenter;
}

function splitAddress(address) {
  const parts = address.split(':');
  const port = parts.length > 0 ? parts[1] : '';
  return {
    ip: parts[0],
    port
  };
}
