/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  share: {},
  hide: true,
  loading: false,
  done: false,
  error: ''
});

export default function editShareReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EDIT_SHARE_MODAL_SHOW': {
      return state.set('hide', false).set('share', fromJS(action.payload));
    }
    case 'EDIT_SHARE_MODAL_HIDE': {
      return INITIAL_STATE;
    }
    case 'EDIT_SHARE_START': {
      return state.set('loading', true).set('error', '');
    }
    case 'EDIT_SHARE_FAIL': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'EDIT_SHARE_DONE': {
      return state.set('loading', false).set('done', true);
    }
    case 'EDIT_SHARE_MODAL_INPUT_CHANGE': {
      return state.setIn(['share', action.payload.name], action.payload.value);
    }
    default: {
      return state;
    }
  }
}
