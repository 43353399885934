/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';
import urls from 'utils/urls';
import dalmConfigurationEditValidation from './validation/dalm';
import { formatAlarmCenterForSave } from 'actions/configuration/utils';
import history from 'utils/history';

export default function create() {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SAVE_CONFIGURATION_STARTED'
    });
    const state = getState();
    const configuration = state.configuration
      .getIn(['current', 'configuration'])
      .toJS();
    const country = state.global.get('country');
    const editConfigurationFeatures = state.global.get(
      'editConfigurationFeatures'
    );
    const errors = dalmConfigurationEditValidation({
      configuration,
      editConfigurationFeatures,
      country
    });
    await Promise.resolve(); // Allow errors to be re-rendered, and therefore animated
    if (Object.keys(errors).length) {
      errors.generalError = 'configuration.error.top';
      dispatch({
        type: 'SAVE_CONFIGURATION_FAILED',
        payload: errors
      });
      return;
    }

    configuration.alarmCenters = configuration.alarmCenters
      .filter(Boolean)
      .map((arc) =>
        formatAlarmCenterForSave(
          arc,
          editConfigurationFeatures.includes('showAlarmCenterIps')
        )
      );

    let response;
    try {
      response = await request.post(`${config.apiServer}/configurations`, {
        body: configuration
      });
    } catch (errorResponse) {
      return dispatch({
        type: 'SAVE_CONFIGURATION_FAILED',
        payload: errorResponse.body.msg || {
          generalError: 'configuration.server.error.top'
        }
      });
    }

    dispatch({
      type: 'SAVE_CONFIGURATION_DONE',
      payload: response.body
    });
    history.push(urls.configurationDetails.replace(':id', response.body.id));
  };
}
