/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  showModal: false,
  showConfirmationDialog: false
});

export default function moveFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ORGANIZATION_DOWNGRADE_OPEN': {
      return state.set('showModal', true);
    }
    case 'ORGANIZATION_DOWNGRADE_CLOSE': {
      return state.set('showModal', false).set('showConfirmationDialog', false);
    }
    case 'ORGANIZATION_DOWNGRADE_WARNING_SUBMIT': {
      return state.set('showConfirmationDialog', true);
    }
    default: {
      return state;
    }
  }
}
