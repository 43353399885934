/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  form: {
    userId: '',
    validUntil: ''
  },
  isShowing: false,
  isMoving: false,
  moved: false,
  error: Map()
});

export default function moveFolderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MOVE_FOLDER_FORM_CHANGE': {
      if (action.payload.value === '-1') {
        return state.setIn(['form', action.payload.name], undefined);
      }
      return state.setIn(['form', action.payload.name], action.payload.value);
    }
    case 'SHOW_MOVE_FOLDER_MODAL': {
      return state
        .merge(INITIAL_STATE)
        .set('isShowing', true)
        .set('isMoving', false);
    }
    case 'HIDE_MOVE_FOLDER_MODAL': {
      return state.set('isShowing', false);
    }
    case 'MOVE_FOLDER_START': {
      return state.set('isMoving', true).setIn('error', Map());
    }
    case 'MOVE_FOLDER_DONE': {
      return state.set('moved', true);
    }
    case 'MOVE_FOLDER_FAILED': {
      const error = action.payload.msg;
      return state.set('isMoving', false).set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
