/** @format */

import { fromJS, List } from 'immutable';

const INITIAL_STATE = fromJS({
  list: [],
  sortedBy: 'createdAt',
  sortOrder: 'descending',
  loading: false,
  showDialog: false,
  isUploading: false,
  uploaded: false,
  uploadError: '',
  uploadingProgress: 0,
  errors: {
    binary: [],
    softwareVersion: []
  },
  uploadForm: {
    binary: null,
    version: '',
    comment: '',
    production: false
  },
  refresh: false
});

const kernel = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_KERNELS_START': {
      return state.set('loading', true).set('refresh', false);
    }
    case 'GET_KERNELS_DONE': {
      const kernels = action.payload.kernels;
      return state.set('loading', true).set('list', fromJS(kernels));
    }
    case 'GET_KERNELS_FAILED': {
      return state.set('loading', false).set('list', List());
    }
    case 'OPEN_KERNEL_DIALOG': {
      return state
        .set('uploadError', '')
        .set('showDialog', true)
        .set('uploaded', false);
    }
    case 'CLOSE_KERNEL_DIALOG': {
      return state.set('showDialog', false);
    }
    case 'KERNEL_UPLOAD_FORM_CHANGE': {
      return state.setIn(
        ['uploadForm', fromJS(action.payload.name)],
        fromJS(action.payload.value)
      );
    }
    case 'KERNEL_UPLOAD_START': {
      return state
        .set('isUploading', true)
        .set('errors', INITIAL_STATE.get('errors'))
        .set('uploaded', false)
        .set('uploadingProgress', 0);
    }
    case 'KERNEL_UPLOAD_DONE': {
      return state
        .set('isUploading', false)
        .set('uploaded', true)
        .set('refresh', true)
        .set(
          'uploadForm',
          fromJS({
            binary: null,
            version: '',
            comment: '',
            production: false
          })
        );
    }
    case 'KERNEL_UPLOAD_PROGRESS': {
      const progress =
        parseFloat(
          parseFloat(
            action.payload.progress.loaded / action.payload.progress.total
          ).toFixed(3)
        ) * 100;
      return state.set('uploadingProgress', progress);
    }
    case 'KERNEL_UPLOAD_ERROR': {
      return state.set('isUploading', false).set('uploadError', action.payload);
    }
    case 'KERNEL_UPLOAD_FORM_ERROR': {
      return state.set('errors', fromJS(action.payload.errors));
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default kernel;
