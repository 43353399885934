/** @format */

import { fromJS, Map } from 'immutable';

import {
  DOWNLOAD_CSV_REQUEST,
  DOWNLOAD_CSV_SUCCESS,
  DOWNLOAD_CSV_FAILURE,
  LOADING_CSV_FILE_DATA,
  HIDE_DOWNLOAD_CSV_FILE_MODAL
} from '../../actions/folder/downloadCsvEdgeManager';

const initialState = fromJS({
  show: false,
  loading: false,
  data: Map(),
  folder: '',
  error: null,
  total: 0,
  count: 0
});

const downloadCsvFileEdgeManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_CSV_REQUEST:
      return state
        .set('show', true)
        .set('loading', true)
        .set('count', 0)
        .set('total', 0)
        .set('folder', action.payload.folder);
    case LOADING_CSV_FILE_DATA:
      return state
        .set('count', action.payload.count)
        .set('total', action.payload.total);
    case DOWNLOAD_CSV_SUCCESS:
      return state.set('loading', false).set('data', fromJS(action.payload));
    case HIDE_DOWNLOAD_CSV_FILE_MODAL:
      return initialState;
    case DOWNLOAD_CSV_FAILURE:
      return state.set('loading', false).set('error', action.payload.msg);
    default:
      return state;
  }
};

export default downloadCsvFileEdgeManagerReducer;
