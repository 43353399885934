/** @format */

import { fromJS } from 'immutable';

const INITIAL_FREE_POSITIONS = ['1', '2', '3', '4'];
const INITIAL_STATE = fromJS(getInitialState());
export default function outputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, value } = action.payload;
      if (index !== undefined && type === 'outputs') {
        if (name === 'optionalFunctionality') {
          state = state.setIn(['data', index, 'pulse'], false);
        } else if (name === 'position') {
          state = state.setIn(['data', index, name], value);
          const freePositions = state
            .get('INITIAL_FREE_POSITIONS')
            .filter(
              (position) =>
                !state
                  .get('data')
                  .find((output) => output.get('position') + '' === position)
            );
          return state.set('freePositions', fromJS(freePositions));
        } else if (name === 'isArm' && !value) {
          state = state.setIn(['data', index, 'inputFeedback'], null);
        }
        return state.setIn(['data', index, name], value);
      } else if (name === 'product') {
        state = INITIAL_STATE;
      } else if (
        index !== undefined &&
        type === 'inputs' &&
        name === 'label' &&
        value === ''
      ) {
        const outputs = state.get('data').map((output) => {
          if (parseInt(output.get('inputFeedback'), 10) === index + 1) {
            output = output.set('inputFeedback', null);
          }
          return output;
        });
        return state.set('data', outputs);
      } else if (
        type === 'eagleEye' &&
        (name === 'eagleEyeRemoteControl' || name === 'eagleEyeClientApp') &&
        !value
      ) {
        let outputs = state.get('data');
        outputs = outputs.filter((output) =>
          output.get('optionalFunctionality')
        );
        const freePositions = state
          .get('INITIAL_FREE_POSITIONS')
          .filter(
            (position) =>
              !outputs.find(
                (output) => output.get('position') + '' === position
              )
          );
        return state
          .set('data', outputs)
          .set('freePositions', fromJS(freePositions));
      } else if (action.payload.name === 'expansionBoard') {
        return updateInitialPositionForExtensionBoard(
          action.payload.value,
          state
        );
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_OUTPUT': {
      const outputs = state.get('data').toJS();
      outputs.push({
        ...getDefaultOutput(action.payload?.params?.addview)
      });
      return state.set('data', fromJS(outputs));
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_OUTPUT': {
      const outputs = state.get('data').delete(action.payload.index);
      const freePositions = state
        .get('INITIAL_FREE_POSITIONS')
        .filter(
          (position) =>
            !outputs.find((output) => output.get('position') + '' === position)
        );
      return state
        .set('data', outputs)
        .set('freePositions', fromJS(freePositions));
    }
    case 'EAGLE_EYE_REMOTE_CONTROL_UPDATE': {
      const outputs = state.get('data').map((oldOutput) => {
        const updatedOutput = action.payload.outputs.find(
          (output) =>
            output.position === oldOutput.get('position') &&
            output.type === 'output'
        );
        return updatedOutput ? fromJS(updatedOutput) : oldOutput;
      });
      return state.set('data', outputs);
    }
    default: {
      return state;
    }
  }
}

function getInitialState() {
  return {
    maxOutputs: 4,
    freePositions: INITIAL_FREE_POSITIONS,
    data: [],
    INITIAL_FREE_POSITIONS
  };
}

function getDefaultOutput(addview) {
  return {
    label: '',
    activeText: '',
    type: 'output',
    inactiveText: '',
    pulseLength: 0,
    isArm: false,
    outputType: null,
    pulse: false,
    position: null,
    outputFunction: addview ? 'outputcontrol.function.static' : null,
    optionalFunctionality: null,
    inputFeedback: null,
    isImported: false
  };
}

function updateInitialPositionForExtensionBoard(value, state) {
  if (value !== 'none') {
    if (state.get('INITIAL_FREE_POSITIONS').length < 5) {
      state = state.set(
        'INITIAL_FREE_POSITIONS',
        state.get('INITIAL_STATE_POSITIONS').push('5', '6', '7', '8')
      );
    }
    const freePositions = state
      .get('INITIAL_FREE_POSITIONS')
      .filter(
        (position) =>
          !state
            .get('data')
            .find((output) => output.get('position') + '' === position)
      );
    return state
      .set('freePositions', fromJS(freePositions))
      .set('maxOutputs', 8);
  } else if (state.get('INITIAL_FREE_POSITIONS').length === 8) {
    const outputs = state
      .get('data')
      .filter((output) => parseInt(output.get('position'), 10) < 5);
    state = state.set(
      'INITIAL_FREE_POSITIONS',
      state.get('INITIAL_FREE_POSITIONS').splice(4, 4)
    );
    const freePositions = state
      .get('INITIAL_FREE_POSITIONS')
      .filter(
        (position) =>
          !outputs.find((output) => output.get('position') + '' === position)
      );
    return state
      .set('freePositions', fromJS(freePositions))
      .set('data', outputs)
      .set('maxOutputs', 4);
  }
  return state;
}
